import type { ReactElement } from "react";

// hooks
import { useToggle } from "../hooks/useToggle";

// components
import Close from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

type BannerProps = {
  dismissible?: boolean;
  children?: React.ReactNode;
  placement?: "bottom" | "top";
} & React.HTMLAttributes<HTMLDivElement>;
export function Banner({
  dismissible,
  children,
  style,
  placement = "top",
}: BannerProps): ReactElement | null {
  const [dismissed, toggleDismissed] = useToggle(false);

  if (!children || dismissed) {
    return null;
  }
  return (
    <>
      <div
        className="flex row f-c-c"
        style={{
          paddingRight: "16px",
          paddingLeft: "16px",
          position: "fixed",
          zIndex: 10000,
          top: placement === "top" ? 0 : undefined,
          bottom: placement === "bottom" ? 0 : undefined,
          left: 0,
          right: 0,
          backgroundColor: "var(--color-background--alt)",
          borderBottom:
            placement === "top" ? "1px solid var(--color-divider)" : undefined,
          borderTop:
            placement === "bottom"
              ? "1px solid var(--color-divider)"
              : undefined,
        }}
      >
        <div
          style={{
            padding: "16px",
            minHeight: "42px",
            fontSize: "1rem",
            ...style,
          }}
        >
          {children}
        </div>
        {dismissible && (
          <span>
            <IconButton
              aria-label="Dismiss banner"
              onClick={toggleDismissed(true)}
            >
              <Close />
            </IconButton>
          </span>
        )}
      </div>
    </>
  );
}
