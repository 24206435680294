// libraries
import { clsx } from "clsx";
import { useField } from "formik";
import { useRef, useState, useCallback } from "react";

// MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import InputLabel, { InputLabelProps } from "@mui/material/InputLabel";

// components
import { FileThumbnail } from "../../../File/FileThumbnail";
import { IFieldFileSelect } from "@iluvatar/global/src/typings";

// utilities
import {
  genFormFileObj,
  useFormContext,
} from "../../../../contexts/form.context";
import { fieldValidator } from "..";

// types
import type { FormFile } from "../../../../services/file";

export const FileSelectField: React.FC<
  Omit<IFieldFileSelect, "onSelected" | "fieldType"> & {
    formControlProps?: FormControlProps;
    actionBarText?: string;
  }
> = ({ name, required, accept, actionBarText, ...props }) => {
  const { onFieldChange, setFiles } = useFormContext();

  const [fieldProps, meta, helpers] = useField({
    name,
    validate: fieldValidator(required),
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange: _onChange, ...formikProps } = fieldProps;

  const onChange = useCallback(
    (files: FormFile[] | undefined) => {
      let formFiles = [...(fieldProps?.value || [])];

      /* Merge files: add file if its not in the value */
      (files || []).forEach((file) => {
        if (
          !formFiles.some(
            (f: FormFile) => f.file && f.file.name === file.file.name,
          )
        ) {
          formFiles.push(file);
        }
        return true;
      });

      // if single select then take last selected
      if (!props.multiple) {
        formFiles = [formFiles[formFiles.length - 1]];
      }

      setFiles(name, formFiles);
      onFieldChange(name, formFiles);
      helpers.setValue(formFiles);
    },
    [fieldProps?.value, helpers, name, onFieldChange, props.multiple, setFiles],
  );

  const removeFile = useCallback(
    (file: FormFile) => () => {
      const files: FormFile[] = fieldProps?.value || [];
      const nextFiles = files.filter((f) => f.fileUUID !== file.fileUUID);

      setFiles(name, nextFiles);
      onFieldChange(name, nextFiles);
      helpers.setValue(nextFiles);
    },
    [fieldProps?.value, helpers, name, onFieldChange, setFiles],
  );

  return (
    <FileSelect
      {...formikProps}
      {...props}
      name={name}
      accept={accept}
      required={required}
      onSelected={onChange}
      removeFile={removeFile}
      actionBarText={actionBarText}
      error={Boolean(meta.error)}
      note={meta.error || props.description}
    />
  );
};

// type
interface FileField {
  name: string;
  label: string;
  onSelected: (files: FormFile[] | undefined) => void;
  removeFile: (file: FormFile) => (ev: React.MouseEvent<HTMLElement>) => void;
  formControlProps?: FormControlProps;
  inputLabelProps?: InputLabelProps;
  labelPlacement?: "inline" | "above";
  value?: FormFile[];
  accept?: string;
  note?: string;
  error?: boolean;
  multiple?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  required?: boolean;
  className?: string;
}

export const filesLoop = (files: FileList | undefined): File[] => {
  const fileArray = [];
  if (!files) {
    return [];
  }

  for (let idx = 0; idx <= (files?.length || 0); idx++) {
    if (files[idx]) {
      fileArray.push(files[idx]);
    }
  }
  return fileArray;
};

// TODO: rewrite so it ususes forwardRef like signature
export const FileSelect: React.FC<FileField & { actionBarText?: string }> = ({
  className,
  onSelected,
  multiple,
  readonly,
  disabled,
  required,
  value,
  accept,
  label,
  error,
  name,
  note,
  labelPlacement = "inline",
  actionBarText = "Click to Attach Files",
  inputLabelProps = {},
  formControlProps = {},
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<FormFile[] | undefined>(undefined);

  const effectiveDisabled = disabled || readonly;

  const fixedLabel = required ? label + "*" : label;
  const labelAbove = labelPlacement === "above";
  const labelInline = labelPlacement === "inline";

  /* functions */
  const onSelectFile = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (ev) => {
      const nextFiles = filesLoop(
        ev.target.files ? ev.target.files : undefined,
      ).map(genFormFileObj);
      setFiles(nextFiles);
      onSelected(nextFiles);
    },
    [onSelected],
  );

  const removeFile = useCallback(
    (file: FormFile) => () => {
      const nextFiles = (files || [])?.filter(
        (f) => f.fileUUID !== file.fileUUID,
      );
      setFiles(nextFiles);
      onSelected(nextFiles);
    },
    [files, onSelected],
  );

  return (
    <FormControl
      variant="outlined"
      className={clsx({ readonly }, className)}
      {...formControlProps}
      error={error}
      required={required}
      disabled={effectiveDisabled}
    >
      {labelAbove && <div className="label-above">{fixedLabel}</div>}
      <Box
        sx={{
          minHeight: "62px",
          border: "1px solid var(--color-border)",
          borderColor:
            readonly || effectiveDisabled
              ? "var(--color-border--readonly)"
              : "var(--color-border)",
          borderRadius: "16px",
        }}
      >
        {labelInline && (
          <InputLabel
            shrink
            {...inputLabelProps}
            /* the background color and padding would normally be fixed with the Inputs legend. That DNE here */
            sx={{
              px: "4px",
              backgroundColor: "var(--color-background-paper--alt)",
            }}
          >
            {fixedLabel}
          </InputLabel>
        )}
        <Box
          sx={{
            m: 1,
            mb: 0.5,
            minHeight: "20px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {(value || files || []).map((file: FormFile) => (
            <FileThumbnail<FormFile>
              file={file}
              key={file.fileUUID}
              disabled={effectiveDisabled}
              removeFile={rest.removeFile || removeFile}
            />
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            px: "16px",
            py: "4px",
            mt: "auto",
            cursor: effectiveDisabled ? "inherit" : "pointer",
            backgroundColor: "background.paper",
            borderTop: "1px solid var(--color-border)",
            borderColor:
              readonly || effectiveDisabled
                ? "var(--color-border--readonly)"
                : "var(--color-border)",
            opacity: readonly || effectiveDisabled ? 0.15 : 1,
            borderBottomRightRadius: "16px",
            borderBottomLeftRadius: "16px",
          }}
          onClick={() => inputRef.current?.click()}
        >
          <Typography variant="caption">{actionBarText}</Typography>
        </Box>
        <input
          hidden
          id={name}
          type="file"
          name={name}
          accept={accept}
          ref={inputRef}
          multiple={multiple}
          disabled={effectiveDisabled}
          onChange={onSelectFile}
        />
      </Box>
      {Boolean(note) && (
        <FormHelperText className="flex" error={error}>
          {note}
        </FormHelperText>
      )}
    </FormControl>
  );
};
