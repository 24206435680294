// libraries
import { clsx } from "clsx";

export function ColorSwatch({
  color,
  active,
  ...props
}: {
  color: string;
  active?: boolean;
} & React.HTMLAttributes<HTMLDivElement>): JSX.Element {
  return (
    <div
      {...props}
      data-color={color}
      className={clsx("color-swatch", props.className)}
      style={{
        ...(props.style || {}),
        backgroundColor: color,
        boxShadow: active ? `0 0 4px ${color}` : "unset",
      }}
    >
      {props.children}
    </div>
  );
}
