// MUI
import { Typography } from "@mui/material";

// types
import { IElementHeader } from "@iluvatar/global/src/typings";

interface HeaderElementInterface extends IElementHeader {
  className?: string;
}

export const Header: React.FC<Omit<HeaderElementInterface, "fieldType">> = (
  props,
) => {
  return (
    <Typography variant={props.meta?.headerType} className={props.className}>
      {props.text}
    </Typography>
  );
};
