// MUI
import { LinearProgress } from "@mui/material";

// types
import { ProgressTracker } from "./utilities";

export const UploadTracker: React.FC<{
  uploadProgress?: ProgressTracker;
  title?: string;
}> = ({ uploadProgress, title = "Uploading Files" }) => {
  if (!uploadProgress) {
    return <></>;
  }
  return (
    <div>
      <div style={{ color: "var(--color-text--secondary)" }}>{title}</div>
      {Object.entries(uploadProgress).map(([id, p]) => (
        <LinearProgress
          key={id}
          valueBuffer={p.process}
          value={p.process}
          variant={p.process > 0 ? "buffer" : "determinate"}
          color={p.process === 100 ? "secondary" : "primary"}
          sx={{ mb: "5px" }}
        />
      ))}
    </div>
  );
};
