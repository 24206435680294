// libraries
// import { fetchAndActivate, getAll } from "firebase/remote-config";

// services
// import { remoteConfig } from "../services/firebase.service";
import {
  deriveGlobalAvailableFeatureFlags,
  RemoteConfigType,
  defaultConfig,
} from "@iluvatar/global/src/config";

export class RemoteConfig {
  config: RemoteConfigType;
  globalAvailableFeatureFlags: number;

  constructor() {
    // remoteConfig.settings.fetchTimeoutMillis = 60000;
    // this.defaultConfig = defaultConfig;
    this.config = defaultConfig;
    this.globalAvailableFeatureFlags = deriveGlobalAvailableFeatureFlags(
      this.config,
    );

    // fetchAndActivate(remoteConfig).then(() => {
    //   this.config = parseRemoteFetch(this.config, getAll(remoteConfig));
    //   this.globalAvailableFeatureFlags = deriveGlobalAvailableFeatureFlags(
    //     this.config,
    //   );
    // });
  }
}
const config = new RemoteConfig();

export default config;
