/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ADMIN } from "../constants/permission.maps";

// types
import { PermissionsClaim } from "../typings/permissions";

/**
 * Notes:
 * - tests are for this are ran within the api test suite
 */
// eslint-disable-next-line
export const hasAdminPermissions = (claim: any): boolean => {
  if (!claim) {
    return false;
  }

  return claim.perm && (claim.perm._p & ADMIN.bitFlag) === ADMIN.bitFlag;
};

// eslint-disable-next-line
export const getCurrentBitFlag = (claim: any): number => {
  return claim.perm?._p || 0;
};

export const hasPermission = (
  permissionClaim: PermissionsClaim,
  bitFlag: number,
): boolean => {
  // if any of the targets parents are admin then the user has permissions
  const isAdmin = hasAdminPermissions(permissionClaim);
  if (isAdmin) {
    return true;
  }
  const userPermissionFlag = getCurrentBitFlag(permissionClaim);
  if (!userPermissionFlag) {
    return false;
  }
  return (userPermissionFlag & bitFlag) === bitFlag;
};
