// libraries
import { useState } from "react";
import { FormikHelpers } from "formik";
import { Link, FileRoute, useNavigate } from "@tanstack/react-router";

// MUI
import { Button, CircularProgress, Divider, Typography } from "@mui/material";

// services
import requestService from "../services/request.service";

// components
import { Form } from "../components/Form";
import DatePicker from "../components/Form/Fields/Date";
import UnAuthLayout from "../layout/unauthed-page";
import { EmailField } from "../components/Form/Fields/Email";

// hooks
import { useToggle } from "../hooks/useToggle";
import { useToastContext } from "../contexts/toast.context";
import { useAuthenticationContext } from "../contexts/authentication/authentication.context";

// validations
import { USER_REGISTRATION_VALIDATION } from "../components/Form/Validation/user-registration";

// constants
import { formErrorHandler } from "../components/Form/helpers";

import "../scss/auth.scss";

// types
import { MemoTextField, PasswordField } from "../components/Form/Fields/Input";

type RegisterForm = {
  email: string;
  password: string;
  confirmPassword: string;
  birthDate: number | undefined;
  displayName?: string;
};

const RegisterUser: React.FC = () => {
  const { login } = useAuthenticationContext();
  const navigate = useNavigate();
  const { addToast } = useToastContext();
  const [disabled, setDisabled] = useState(false);
  const [blocking, toggleBlocking] = useToggle(true);

  const submitForm = async (
    user: RegisterForm,
    helpers: FormikHelpers<RegisterForm>,
  ) => {
    // TODO: set all props that are empty fields to undefined (for all formik form)
    // PATCH: make user not to send empty strings for password (formik default)
    try {
      setDisabled(true);
      toggleBlocking(false)();
      const rr = await requestService.request("/api/register", {
        method: "post",
        body: JSON.stringify({
          user,
        }),
      });
      const res = await login(user.email, user.password);
      addToast("Account Created");
      toggleBlocking(true)();
      helpers.resetForm();

      if (res) {
        navigate({
          to: "/user/$userId/edit",
          params: {
            userId: res[0].uid,
          },
        });
      } else {
        navigate({ to: "/login" });
      }
    } catch (error) {
      formErrorHandler(error, helpers);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <UnAuthLayout>
      <div className="auth-page-container">
        <Typography variant="h5" color="text.secondary" sx={{ mt: 3 }}>
          Create an Account
        </Typography>

        <Form<RegisterForm>
          id="register"
          className="auth-form max-w-[420px]"
          renderAfter
          disabled={disabled}
          onSubmit={submitForm}
          blockRouting={blocking}
          validationSchema={USER_REGISTRATION_VALIDATION}
          initialValues={{
            email: "",
            password: "",
            confirmPassword: "",
            birthDate: undefined,
            displayName: "",
          }}
        >
          <MemoTextField
            required
            className="auth-form-element"
            label="Display Name"
            name="displayName"
            description="What name would you like to go by?"
          />
          <EmailField
            required
            className="auth-form-element"
            label="Email"
            name="email"
          />
          <DatePicker
            required
            outputNumber
            className="auth-form-element"
            label="Birth Date"
            name="birthDate"
          />
          <PasswordField
            required
            className="auth-form-element"
            label="Password"
            name="password"
            description="The super secret key you definitely don't use everywhere..."
          />
          <PasswordField
            required
            className="auth-form-element"
            label="Confirm Password"
            name="confirmPassword"
          />
          <Button
            fullWidth
            className="auth-form-element auth-button"
            form="register"
            variant="outlined"
            type="submit"
            color="primary"
            disabled={disabled}
            sx={{ mt: 3 }}
            startIcon={
              disabled && (
                <CircularProgress size={14} sx={{ mr: "5px", ml: "1px" }} />
              )
            }
          >
            Register
          </Button>
        </Form>
        <Divider />
        <Link
          to="/login"
          className="flex f-c-c my-3"
          style={{ color: "var(--color-text--secondary)" }}
        >
          back to Login
        </Link>
        {/* <Link
          to="/password-reset"
          style={{ color: "var(--color-text--secondary)" }}
        >
          Forgot password?
        </Link> */}
      </div>
    </UnAuthLayout>
  );
};

export const Route = new FileRoute("/register").createRoute({
  component: RegisterUser,
});
