type FlatType<T = unknown> = Record<
  string,
  string | number | null | boolean | unknown | T
>;

function isPrimitiveValue(val: unknown): boolean {
  return (
    val === null ||
    typeof val === "string" ||
    typeof val === "number" ||
    typeof val === "boolean"
  );
}

export function flattenObject<T = string, O = Record<string, unknown>>(
  obj: O,
  depth?: number | undefined,
  options?: {
    preserveArray?: boolean;
  },
): FlatType<T> {
  if (depth === 1) {
    return obj as FlatType;
  }
  const returnObj: FlatType<T> = {};

  if (!isObject(obj)) {
    throw new Error("obj is not an object");
  }

  Object.entries(obj).forEach(([key, value]) => {
    if (
      options?.preserveArray &&
      Array.isArray(value) &&
      value.every(isPrimitiveValue)
    ) {
      returnObj[key] = value;
      // add array entries index too (flatten will override / merge the above and the below)
      Object.entries(
        flattenObject(value, depth ? depth - 1 : undefined, options),
      ).forEach(([flatKey, flatValue]) => {
        returnObj[key + "." + flatKey] = flatValue;
      });
    } else if (typeof value === "object" && value !== null) {
      Object.entries(
        flattenObject(
          value as Record<string, unknown>,
          depth ? depth - 1 : undefined,
          options,
        ),
      ).forEach(([flatKey, flatValue]) => {
        returnObj[key + "." + flatKey] = flatValue;
      });
    } else if (isPrimitiveValue(value)) {
      returnObj[key] = value;
    }
  });
  return returnObj;
}

// TODO: add unit tests
export const unFlattenObject = <T>(
  obj: Record<string, unknown>,
  delimiter = ".",
) => {
  const result: Record<string, unknown> = {};
  for (const i in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, i)) {
      const keys = i.split(delimiter);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      keys.reduce(function (r: any, e, j) {
        return (
          r[e] ||
          (r[e] = isNaN(Number(keys[j + 1]))
            ? keys.length - 1 == j
              ? obj[i]
              : {}
            : [])
        );
      }, result);
    }
  }
  return result as T;
};

export function isObject(obj: unknown): obj is Record<string, unknown> {
  return typeof obj === "object";
}

// TODO: add unit tests
export function traverseObjectPath<Value>(
  path: string,
  srcObject: unknown,
  mutate?: boolean,
): Value {
  const stack = (path || "").split(".");
  let prop = stack.shift();
  let targetObj = mutate
    ? srcObject
    : JSON.parse(JSON.stringify({ dup: srcObject })).dup;
  while (prop && targetObj) {
    targetObj = targetObj[prop];
    prop = stack.shift();
  }

  if (!targetObj) {
    return targetObj;
  }

  if (Array.isArray(targetObj)) {
    return [...targetObj] as unknown as Value;
  } else if (targetObj !== null && typeof targetObj === "object") {
    // THINK: don't spread here, you lose the instantiated type? (ie, Timestamp)
    // return targetObj;
    return { ...targetObj };
  } else {
    return targetObj as Value;
  }
}

export function removePrivateProps(
  obj: Record<string, unknown>,
): Record<string, unknown> {
  return Object.getOwnPropertyNames(obj).reduce<Record<string, unknown>>(
    (acc, name) => {
      if (!/^_(.)/g.test(name) || name.startsWith("__")) {
        acc[name] = obj[name];
      }
      return acc;
    },
    {},
  );
}

export function pivotRecordArray<T = string>(obj: Record<string, T[]>): T[] {
  return [
    ...(Object.keys(obj)
      .reduce<Set<T>>((_set, key) => {
        obj[key].forEach((_uid) => _set.add(_uid));
        return _set;
      }, new Set<T>())
      .values() as unknown as T[]),
  ];
}

/**
 * Performs equality by checking Object.is then JSON.stringify compare
 */
export function deepEqual(objA: unknown, objB: unknown): boolean {
  if (Object.is(objA, objB)) {
    return true;
  }

  if (
    typeof objA !== "object" ||
    objA === null ||
    typeof objB !== "object" ||
    objB === null
  ) {
    return false;
  }

  return JSON.stringify(objA) === JSON.stringify(objB);
}
