// MUI
import { Alert, CircularProgress } from "@mui/material";

// utilities
import { useStorageUrl } from "../../hooks/useStorageUrl";
import { imgRefSize, isFormFile } from "../../services/file";

// types
import { IFileReference } from "@iluvatar/global/src/typings";
import type { FormFile } from "../../services/file";

export function ImagePreviewPopup({
  file,
  show,
  closeHandler,
}: {
  file: FormFile | IFileReference;
  show: boolean;
  closeHandler: (ev: React.MouseEvent<HTMLDivElement>) => void;
}) {
  const [fileUrl, loading, error] = useStorageUrl(imgRefSize(file, 600));

  const imageUrl = isFormFile(file) ? URL.createObjectURL(file?.file) : fileUrl;

  if (!show) {
    return null;
  }

  return (
    <div
      style={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1204,
        position: "fixed",
        backgroundColor: "var(--color-black)",
      }}
      onClick={closeHandler}
    >
      {loading && <CircularProgress />}
      {error && (
        <Alert>Uh Oh! Looks like the image preview is unavailable.</Alert>
      )}
      <div
        style={{
          top: "50%",
          left: "50%",
          maxWidth: "800px",
          maxHeight: "800px",
          height: "100%",
          width: "100%",
          display: "flex",
          borderRadius: "2%",
          position: "absolute",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${imageUrl})`,
          transform: "translate(-50%,-50%)",
          WebkitTransform: "translate(-50%, -50%)",
        }}
      ></div>
    </div>
  );
}
