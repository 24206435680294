// libraries
import { useCallback, useEffect } from "react";
import { useField } from "formik";
import { clsx } from "clsx";

// MUI
import FormControl, { FormControlProps } from "@mui/material/FormControl";

import InputLabel, { InputLabelProps } from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";

// context
import { useFormContext } from "../../../../contexts/form.context";

// types
import { IFieldBase } from "@iluvatar/global/src/typings";
import { emailValidator } from "..";

interface TextFieldInterface extends IFieldBase {
  className?: string;
  showMaxLength?: number;
  outlinedInputProps?: Partial<OutlinedInputProps>;
  formControlProps?: FormControlProps;
  inputLabelProps?: InputLabelProps;
}

export const EmailField: React.FC<TextFieldInterface> = ({
  className,
  name,
  label,
  labelPlacement = "inline",
  disabled,
  readonly,
  description,
  defaultValue,
  required,
  autoFocus,
  showMaxLength,
  max,
  inputLabelProps,
  formControlProps,
  outlinedInputProps,
}) => {
  const { onFieldChange } = useFormContext();

  const [fieldProps, meta, helpers] = useField({
    name,
    validate: emailValidator(required),
  });
  const { value, onChange, onBlur } = fieldProps;
  const { touched, error } = meta;

  useEffect(() => {
    if (!value && defaultValue) {
      helpers.setValue(defaultValue);
      onFieldChange(name, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Derived Properties
   */
  const changeHandler = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      onChange(e);
      onFieldChange(name, e.currentTarget.value);
    },
    [onChange, onFieldChange, name],
  );

  // fixes display bug with required star overlapping border
  const fixedLabel = required ? label + "*" : label;
  const labelAbove = labelPlacement === "above";
  const labelInline = labelPlacement === "inline";

  // || "" so so this is a controlled field
  const fixedValue = value || "";

  /*
   * this is a naive string length approach, emoji and unicode characters may be
   * considered a single character but count for multiple length values.
   */
  const charCount =
    max && value && showMaxLength !== undefined && value.length >= showMaxLength
      ? `${value.length}/${max}`
      : null;

  const hasError = Boolean(touched && error);
  const note = hasError ? error : description;
  return (
    <FormControl
      variant="outlined"
      className={clsx({ readonly }, className)}
      {...formControlProps}
      disabled={disabled || readonly}
      required={required}
      error={hasError}
    >
      {labelAbove && <div className="label-above">{fixedLabel}</div>}
      {labelInline && <InputLabel {...inputLabelProps}>{label}</InputLabel>}
      <OutlinedInput
        {...outlinedInputProps}
        name={name}
        id={name}
        onChange={changeHandler}
        autoFocus={autoFocus}
        onBlur={onBlur}
        label={labelInline ? fixedLabel : undefined}
        value={fixedValue}
        inputProps={{ maxLength: max, enterKeyHint: "next" }}
      />
      {Boolean(charCount || note) && (
        <FormHelperText
          id={`${name}-help-text`}
          className="flex"
          data-error={error}
          error={hasError}
        >
          {note}
          {charCount && <span className="flex-spacer" />}
          {charCount}
        </FormHelperText>
      )}
    </FormControl>
  );
};
