// libraries
import { Link } from "@tanstack/react-router";
import Pattern from "@mui/icons-material/pattern";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";

import { cn } from "@/utilities";

// hooks
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useAuthenticationContext } from "../contexts/authentication/authentication.context";

// components
import { DarkThemeToggle } from "../components/DarkThemeToggle/DarkThemeToggle";
import { FlexSpacer } from "../components/FlexSpacer";
import { BubbleIcon } from "../components/BubbleIcon";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";

import "./nav.scss";

// types
import { User } from "@iluvatar/global/src/typings";

type PageInterface = React.PropsWithChildren & {
  menu?: React.ReactNode;
  drawerActionName?: string;
  drawerAction?: () => void;
  isSecondNav?: boolean;
};

function hasMatchNotification(user: User): boolean {
  return Object.entries(user.pageUpdateTimes || {}).some(
    ([t, v]) =>
      t.startsWith("matches") &&
      user.pageViewsTimes &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      v > (user.pageViewsTimes[t as any] || 0),
  );
}

const PageNav: React.FC<PageInterface> = ({ children }) => {
  const { uid, isAuthenticated } = useAuthenticationContext();
  const { user } = useCurrentUser();

  const isActive = (path: string): boolean => {
    return location.pathname.startsWith(path);
  };

  return (
    <>
      <header className="nav">
        <div className="container">
          {isAuthenticated && (
            <Link className="title" to="/">
              Bubble
            </Link>
          )}

          <FlexSpacer />
          {isAuthenticated && (
            <Link
              to="/likes"
              className={cn("h-[100%] flex items-center nav-link relative", {
                active: isActive("/likes"),
              })}
            >
              {(user?.pageUpdateTimes?.likes || 0) >
                (user?.pageViewsTimes?.likes || 0) && (
                <div className="rounded bg-amber-500 w-[9px] h-[9px] absolute bottom-[17px] right-[-1px] z-[100]" />
              )}
              <BubbleIcon className="w-[35px]" />
            </Link>
          )}
          {isAuthenticated && (
            <Link
              to="/matches"
              className={cn(
                "h-[100%] flex items-center nav-link relative mr-1",
                {
                  active: isActive("/matches"),
                },
              )}
            >
              {user && hasMatchNotification(user) && (
                <div className="rounded bg-amber-500 w-[9px] h-[9px] absolute bottom-[17px] right-[-1px] z-[100]" />
              )}
              <Pattern className="w-[28px] mb-[0px] h-[27px] rotate-[180deg]" />
              {/* <ChatBubble /> */}
              {/* <Diversity1 className="w-[35px] mb-[0px] h-[27px]" /> */}
            </Link>
          )}
          {isAuthenticated && (
            <DropdownMenu>
              <DropdownMenuTrigger
                className={cn("nav-link", { active: isActive("/user") })}
              >
                <SettingsOutlined />
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className="min-w-[200px]"
                sideOffset={8}
                collisionPadding={{ right: 12 }}
              >
                <DropdownMenuLabel>Settings</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <Link
                  to={"/user/$userId/edit"}
                  className={cn("nav-link", { active: isActive("/user") })}
                  params={{
                    userId: uid!,
                  }}
                >
                  <DropdownMenuItem>Profiles</DropdownMenuItem>
                </Link>
                <Link
                  to={"/user/$userId/account-settings"}
                  className={cn("nav-link", { active: isActive("/user") })}
                  params={{
                    userId: uid!,
                  }}
                >
                  <DropdownMenuItem>Account</DropdownMenuItem>
                </Link>
                <DropdownMenuItem disabled>Subscription</DropdownMenuItem>
                {/* <DropdownMenuItem disabled>Billing</DropdownMenuItem> */}
                {/* <DropdownMenuSeparator /> */}
                {/* <DropdownMenuLabel className="flex row items-center">
                  Theme
                  <FlexSpacer />
                  <DarkThemeToggle />
                </DropdownMenuLabel> */}
                <DropdownMenuSeparator />
                <Link to="/logout" className={cn("nav-link")}>
                  <DropdownMenuItem>Logout</DropdownMenuItem>
                </Link>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
          <DarkThemeToggle />
        </div>
      </header>
      <>{children}</>
    </>
  );
};

export default PageNav;
