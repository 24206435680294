import { useState, useEffect } from "react";

// constants
import { cn } from "@/utilities";

// components
import { ImageFull } from "../ImageRef";

// styles
import "./image-list.scss";

// types
import { IFileReference } from "@iluvatar/global/src/typings";

export function ImageCarousel({
  userId,
  images,
  className,
}: {
  userId: string;
  className?: string;
  images: IFileReference[];
}) {
  const [viewIdx, setViewIdx] = useState(0);

  const prevImage = () => {
    setViewIdx((x) => (x > 0 ? x - 1 : x));
  };

  const nextImage = () => {
    setViewIdx((x) => (x < images.length - 1 ? x + 1 : x));
  };

  useEffect(() => {
    setViewIdx(0);
  }, [userId]);

  return (
    <div className={cn(className, "mb-8 max-[500px]:mx-[-16px] relative")}>
      <ImageFull imgRef={images[viewIdx]} />
      <div
        className={cn(
          "absolute left-0 top-0 bottom-0 right-[66%] z-index-[1000]",
          { clickable: viewIdx > 0 },
        )}
        onClick={prevImage}
      />
      <div
        className={cn(
          "absolute right-0 top-0 bottom-0 left-[66%] z-index-[1000]",
          { clickable: viewIdx < images.length - 1 },
        )}
        onClick={nextImage}
      />
      <div className="counter">
        {images.map((key, idx) => (
          <div
            key={key.id}
            className={cn("dot", {
              active: idx === viewIdx,
            })}
          />
        ))}
      </div>
    </div>
  );
}
