// Libraries
import { clsx } from "clsx";
import { ReactNode } from "react";

// MUI
import Box, { BoxProps } from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Components

// styles
import "./accordion.scss";

export const AccordionHeader: React.FC<
  Omit<BoxProps, "title"> & {
    title?: string | JSX.Element | React.ReactNode;
    subTitle?: string;
    open?: boolean;
    variant?: TypographyProps["variant"];
    slots?: {
      startAdornment?: ReactNode;
      endAdornment?: ReactNode;
    };
  }
> = ({ title, subTitle, slots, variant = "h3", open, ...props }) => {
  return (
    <Box
      {...props}
      className={clsx(props.className, "accordion-header", { open })}
    >
      {slots?.startAdornment || undefined}
      {open ? (
        <KeyboardArrowDownIcon color="primary" />
      ) : (
        <KeyboardArrowUpIcon
          color="primary"
          sx={{ transform: "rotate(90deg)", color: "var(--text-color)" }}
        />
      )}

      {typeof title === "string" && (
        <Typography variant={variant} sx={{ ml: 1 }}>
          {title}

          {subTitle && (
            <Typography className="accordion-sub-header" variant="caption">
              ({subTitle})
            </Typography>
          )}
        </Typography>
      )}
      {title && typeof title !== "string" && title}

      <div className="flex flex-1" />

      {slots?.endAdornment || undefined}
    </Box>
  );
};
