// libraries
import diff from "microdiff";
import { useEffect, useState } from "react";
import { useFormikContext, FormikValues } from "formik";

// MUI
import { Alert } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";

// utilities
import {
  flattenObject,
  isObject,
  removePrivateProps,
} from "@iluvatar/global/src/utilities/object";
import {
  FieldGroupOrderingTypes,
  FieldGroupOrderingWithRef,
  ICondition,
  IElement,
  IField,
  IFieldGroup,
  IFieldGroupBuilt,
  IFieldGroupReference,
  IFieldReference,
} from "@iluvatar/global/src/typings";
import { FORM_GLOBAL_ERROR_KEY } from "@iluvatar/global/src/constants";
import { sortObj } from "@iluvatar/global/src/utilities";
import { FormError } from "./types";

export type FormBubbleState<T = unknown> = { values: T };
export type FormState = { hasChanges: boolean };

/*
 * HACK: Patch for formik tough bug
 *
 * When submission of the form happens, all fields should be marked as touched. This only happens for fields with
 * an entry in the initial values https://github.com/formium/formik/issues/445. I believe this is a bug that should be fixed.
 * Below is a patch that touches every field with errors (HACK) so that the error messages persist through refresh.
 */
export const FormikPatchTouched: React.FC<{ showErrors?: boolean }> = ({
  showErrors,
}) => {
  const { errors, setFieldTouched, isSubmitting, isValidating } =
    useFormikContext();

  useEffect(() => {
    if ((isSubmitting && !isValidating) || showErrors) {
      Object.keys(flattenObject(errors)).forEach((path) => {
        setFieldTouched(path, true, false);
      });
    }
  }, [errors, isSubmitting, isValidating, setFieldTouched, showErrors]);
  return null;
};

export function FormikOnChange<Values extends FormikValues>({
  onChange,
}: {
  onChange: (state: Values) => void;
}): JSX.Element {
  const { values, initialValues } = useFormikContext<Values>();
  const [prevInitial, setPrevInitial] = useState<Values>(initialValues);

  useEffect(() => {
    if (diff(initialValues, prevInitial).length) {
      setPrevInitial(initialValues);
      return;
    }
    if (diff(values, initialValues).length) {
      onChange(values);
    }
    /* eslint-disable-next-line */
  }, [onChange, values, initialValues]);
  return <></>;
}

/**
 * We only have a need right now to bubble up if theres are any changes in the form
 */
export const BubbleUpFormMetadata: React.FC<{
  setFormMetadata: (state: FormState) => void;
}> = ({ setFormMetadata }) => {
  const { values, initialValues } = useFormikContext();

  const [state, setLocalState] = useState<FormState>({
    hasChanges: false,
  });

  useEffect(() => {
    if (
      !state?.hasChanges &&
      isObject(values) &&
      JSON.stringify(removePrivateProps(values)) !==
        JSON.stringify(initialValues)
    ) {
      setLocalState((s) => ({ ...s, hasChanges: true }));
    } else if (
      state?.hasChanges &&
      isObject(values) &&
      JSON.stringify(removePrivateProps(values)) ===
        JSON.stringify(initialValues)
    ) {
      setLocalState((s) => ({ ...s, hasChanges: false }));
    }
  }, [initialValues, state, state?.hasChanges, values]);

  useEffect(() => {
    setFormMetadata(state);
  }, [state, setFormMetadata]);

  return null;
};

export const BubbleUpFormValues: React.FC<{
  setState: (state: FormBubbleState) => void;
}> = ({ setState }) => {
  const { values, initialValues } = useFormikContext();

  const [state, setLocalState] = useState<FormBubbleState>({
    values: initialValues,
  });

  useEffect(() => {
    if (JSON.stringify(values) !== JSON.stringify(state.values)) {
      setLocalState({ values });
      setState({ values });
    }
  }, [initialValues, setState, state, values]);

  return null;
};

export function useGroupErrors(name: string) {
  const { errors: formikErrors } =
    useFormikContext<Record<string, string | unknown>>();
  const [groupError, setGroupError] = useState<string | undefined>();
  useEffect(() => {
    const errors = formikErrors[name];
    if (errors && typeof errors === "string") {
      setGroupError(errors);
    } else if (errors && Array.isArray(errors)) {
      const arrayError = errors.filter((error) => typeof error === "string");
      arrayError.length && setGroupError(arrayError[0]);
    } else if (groupError) {
      // if error update, groupError is set but not in formik, reset groupError
      setGroupError(undefined);
    }
  }, [formikErrors, setGroupError, groupError, name]);
  return groupError;
}

export const RootFormErrors = () => {
  const errors = useGroupErrors(FORM_GLOBAL_ERROR_KEY);
  const { errors: allErrors, submitCount, isSubmitting } = useFormikContext();
  if (errors) {
    return (
      <FormHelperText className="mb-16-force" error={true}>
        {errors.toString()}
      </FormHelperText>
    );
  }

  if (Object.keys(allErrors).length && submitCount > 0 && !isSubmitting) {
    return (
      <Alert severity="error" variant="outlined" sx={{ mb: 1 }}>
        Looks like theres an error in your form. Have a look to see if theres
        some missing or invalid data.
      </Alert>
    );
  }
  return null;
};

export function hasConditionalHide(conditions: ICondition[]): boolean {
  return conditions.some((condition) => {
    return condition?.action === "hidden";
  });
}

export function hasConditionalVisible(conditions: ICondition[]): boolean {
  return conditions.some((condition) => {
    return condition?.action === "visible";
  });
}

export function joinPath(...path: (string | undefined)[]) {
  return path.filter((p) => !!p).join(".");
}

export function fieldGroupOrdering<
  G extends IFieldGroupBuilt = IFieldGroupBuilt,
  F extends IField | IElement | IFieldReference =
    | IField
    | IElement
    | IFieldReference,
>(group?: IFieldGroup): FieldGroupOrderingWithRef<G, F>[] {
  if (!group) {
    return [];
  }

  const maps = {
    group: new Map<string, IFieldGroup | IFieldGroupReference>(),
    field: new Map<string, IField | IElement | IFieldReference>(),
  };

  (group.groups || []).forEach((_group) => maps.group.set(_group.name, _group));
  (group.fields || []).forEach((_field) => maps.field.set(_field.name, _field));

  if (group.ordering) {
    return group.ordering.reduce<FieldGroupOrderingWithRef<G, F>[]>(
      (arr, order) => {
        const _entry = maps[order.type].get(order.name);

        if (_entry === undefined) {
          // eslint-disable-next-line no-console
          console.warn("group ordering doesn't have matching entry");
        } else {
          arr.push({
            ...order,
            entry: _entry,
          } as FieldGroupOrderingWithRef<G, F>);
        }
        return arr;
      },
      [],
    );
  }

  return [
    ...(group.fields || []).map((_field, idx) => {
      const ord: FieldGroupOrderingWithRef<G, F> = {
        type: FieldGroupOrderingTypes.field,
        entryIdx: idx,
        orderIdx: idx,
        name: _field.name,
        entry: _field as F,
      };
      return ord;
    }),
    ...(group.groups || [])
      .sort(sortObj<IFieldGroup | IFieldGroupReference>("label"))
      .map((_group, idx) => {
        const ord: FieldGroupOrderingWithRef<G, F> = {
          type: FieldGroupOrderingTypes.group,
          entryIdx: idx,
          orderIdx: (group.fields || []).length + idx,
          name: _group.name,
          entry: _group as G,
        };
        return ord;
      }),
  ];
}

export function isFormError(val: unknown): val is FormError {
  return (
    isObject(val) &&
    Object.getOwnPropertyNames(val).includes("path") &&
    Object.getOwnPropertyNames(val).includes("message")
  );
}
