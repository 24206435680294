// libraries
import { useRef, useState, useCallback, createRef } from "react";
import { Cropper, ReactCropperElement } from "react-cropper";

import { Button } from "@mui/material";

import "./image-select.scss";
import "cropperjs/dist/cropper.css";
import { Rotate90DegreesCw } from "@mui/icons-material";

interface FileSelectWrapperProps {
  onComplete?: (name: string, dataBlob: Blob | null) => Promise<void>;
  children?: React.ReactElement;
  name?: string;
  readonly?: boolean;
  disabled?: boolean;
  required?: boolean;
  className?: string;
}

export const ImageSelectWrapper: React.FC<FileSelectWrapperProps> = ({
  children,
  name,
  readonly,
  disabled,
  required,
  onComplete = async () => null,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const cropperRef = createRef<ReactCropperElement>();

  const [image, setImage] = useState<string | null>();
  const [file, setFile] = useState<File | null>();
  const [externalDisabled, setExternalDisabled] = useState<boolean>(false);

  const effectiveDisabled = disabled || readonly || externalDisabled;

  /**
   * Functions
   */

  const setDisabled = useCallback((d: boolean) => {
    setExternalDisabled(d);
  }, []);

  const onSelectFile = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    async (ev) => {
      ev.preventDefault();
      if (effectiveDisabled) {
        return;
      }
      setDisabled(true);

      const files = ev.target.files || [];
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result?.toString());
      };
      setFile(files[0]);
      reader.readAsDataURL(files[0]);

      setDisabled(false);
    },
    [effectiveDisabled, setDisabled],
  );

  const onClickFile = useCallback<React.MouseEventHandler<HTMLInputElement>>(
    (ev) => {
      // this is to fix a small bug in the original implementation
      // where it does not allow to upload the exact same file two times
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (ev.target as any).value = null;
    },
    [],
  );

  const clear = useCallback(() => {
    setFile(null);
    setImage(null);
    if (inputRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (inputRef.current as any).value = null;
      inputRef.current.files = null;
    }
  }, []);

  const onDrop = useCallback(
    (ev: React.DragEvent) => {
      ev.preventDefault();
      if (effectiveDisabled) {
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result?.toString());
      };
      reader.readAsDataURL(ev.dataTransfer.files[0]);
    },
    [effectiveDisabled],
  );

  const onDragover = useCallback((ev: React.MouseEvent) => {
    ev.preventDefault();
  }, []);

  // This opens the dialog
  const onClick = useCallback(() => {
    if (effectiveDisabled) {
      return;
    }

    inputRef.current?.click();
  }, [effectiveDisabled]);

  return (
    <>
      <div onDrop={onDrop} onDragOver={onDragover} onClick={onClick} {...rest}>
        {children}
        <input
          hidden
          id="file"
          type="file"
          accept="image/*"
          name={name}
          disabled={effectiveDisabled}
          required={required}
          value={inputRef.current?.value}
          ref={inputRef}
          onChange={onSelectFile}
        />
      </div>

      {image && (
        <div className="image-backdrop">
          <Cropper
            ref={cropperRef}
            style={{ height: "100%", width: "100%" }}
            zoomTo={1}
            viewMode={1}
            aspectRatio={1}
            autoCropArea={1}
            dragMode="move"
            preview=".img-preview"
            src={image}
            guides
            movable
            responsive
            // minCropBoxHeight={10}
            // minCropBoxWidth={10}
            minContainerWidth={400}
            minCropBoxWidth={400}
            minCanvasWidth={400}
            zoomable={false}
            background={false}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          />
          <div className="upload-buttons">
            <Button sx={{ color: "white" }} variant="text" onClick={clear}>
              cancel
            </Button>
            <Button
              sx={{ color: "white" }}
              variant="text"
              onClick={() => {
                cropperRef.current?.cropper.rotate(-90);
              }}
            >
              <Rotate90DegreesCw style={{ transform: "scaleX(-1)" }} />
            </Button>
            <Button
              sx={{ color: "white" }}
              variant="text"
              onClick={() => {
                cropperRef.current?.cropper.rotate(90);
              }}
            >
              <Rotate90DegreesCw />
            </Button>
            <Button
              variant="text"
              onClick={() => {
                if (cropperRef?.current?.cropper) {
                  cropperRef.current.cropper
                    .getCroppedCanvas()
                    .toBlob(async (blob) => {
                      await onComplete(file!.name, blob);
                      clear();
                    }, "image/png");
                }
              }}
            >
              choose
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
