import { cn } from "@/utilities";

export function BubbleIcon(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn("w-[40px] h-[25px]", props.className)} {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 40 25"
        xmlns="http://www.w3.org/2000/svg"
        className="h-[100%]"
      >
        <g>
          <circle
            r="10"
            id="circle_left"
            cy="12"
            cx="13"
            strokeWidth="2.25"
            stroke="currentColor"
            fill="none"
          />
          <circle
            r="10"
            id="circle_right"
            cy="12"
            cx="28"
            strokeWidth="2.25"
            stroke="currentColor"
            fill="none"
          />
        </g>
      </svg>
    </div>
  );
}
