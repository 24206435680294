import { IElement, IField, IFieldReference } from "./field";
import { ICondition } from "./utils";

/**
 * Like FieldReferences. This lets you reuse a previously configured field group.
 * An example of this would be a standard Address field-group that can be reused
 */
export interface IFieldGroupReference {
  name: string;
  label: string;
  groupId: string;

  // conditions that must be met in order to render this group
  conditions?: ICondition[];
}

// check required fields and exclude all types of objects that may be comparable to this one.
// this is a very brittle check
export function isFieldGroup(group?: unknown): group is IFieldGroup {
  if (!group) {
    return false;
  }
  if (Object.getOwnPropertyNames(group).includes("groups")) {
    return true;
  }
  if (Object.getOwnPropertyNames(group).includes("fields")) {
    return true;
  }
  return (
    !Object.getOwnPropertyNames(group).includes("groupId") &&
    !Object.getOwnPropertyNames(group).includes("fieldType") &&
    !Object.getOwnPropertyNames(group).includes("fieldId") &&
    Object.getOwnPropertyNames(group).includes("label") &&
    Object.getOwnPropertyNames(group).includes("name")
  );
}

export function isFieldGroupReference(
  group?: unknown,
): group is IFieldGroupReference {
  return (
    group !== undefined &&
    Object.getOwnPropertyNames(group).includes("groupId") &&
    Object.getOwnPropertyNames(group).includes("label") &&
    Object.getOwnPropertyNames(group).includes("name")
  );
}

// eslint-disable-next-line no-shadow
export enum FieldGroupOrderingTypes {
  "group" = "group",
  "field" = "field",
}
export type FieldGroupOrdering = {
  type: FieldGroupOrderingTypes;
  orderIdx: number;
  entryIdx: number;
  name: string;
};

export type FieldGroupOrderingWithRef<
  G = IFieldGroup | IFieldGroupReference,
  F = IElement | IField | IFieldReference,
> =
  | (FieldGroupOrdering & {
      type: FieldGroupOrderingTypes.field;
      entry: F;
    })
  | (FieldGroupOrdering & {
      type: FieldGroupOrderingTypes.group;
      entry: G;
    });

/**
 * Form Field configuration. used to dynamically render a field within a form within the react app.
 * This structure gets saved in the database within a FieldSchema or a FieldGroupSchema
 */
interface IFieldGroupBase<G, F> {
  name: string;
  label: string;
  description?: string;

  // conditions that must be met in order to render this group
  conditions?: ICondition[];

  // For custom rendering of groups, e.g., risk matrix or table question grid
  type?: string;

  groups?: G[];
  fields?: F[];

  /**
   * order of field/groups is an array with reference to the group or field
   * reference keys will be an object:
   *
   *
   * `{ type: "group", idx: groupIdx, name: groupName}` or
   * `{ type: "field", idx: fieldIdx, name: fieldName}`
   *
   * where groupIdx and fieldIdx are their positions within the fields ond groups array.
   *
   * eg: [
   *  {type: "field", idx:0, name: "field1" },
   *  {type: "group", idx:0, name: "group-section"},
   *  {type: "field", idx:1, name: "filed-2"}
   * ]
   **/
  ordering?: FieldGroupOrdering[];

  // maximum iteration of groups
  max?: number;

  // minimum number of groups (this is essentially the 'required' property)
  min?: number;

  disabled?: boolean;
  readonly?: boolean;
  hidden?: boolean;
  headless?: boolean;
  isSection?: boolean;
}

export type IFieldGroup = IFieldGroupBase<
  IFieldGroup | IFieldGroupReference,
  IField | IElement | IFieldReference
>;

export type IFieldGroupBuilt = IFieldGroupBase<
  IFieldGroupBuilt,
  IField | IElement
>;
