// libraries
import dayjs, { extend } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
extend(relativeTime);

import { FileRoute, useParams, useNavigate } from "@tanstack/react-router";

import { Settings } from "@mui/icons-material";
import { Button, Divider, Typography, useMediaQuery } from "@mui/material";

// hooks
import { useFirestoreSubListener } from "@/hooks/useSubscription";
import { useAuthenticationContext } from "@/contexts/authentication/authentication.context";

// components
import { TagList } from "@/components/TagList";
import DefaultLayout from "@/layout/default-page";
import { FlexSpacer } from "@/components/FlexSpacer";
import { ImageCarousel } from "@/components/ProfileImageBanner/ImageCarousel";
import { ProfileImageBanner } from "@/components/ProfileImageBanner";
import { ProfileDescription } from "@/components/ProfileDescription";

// constants
import { getUserPath } from "@iluvatar/global/src/constants";

// types
import { UserEntity } from "@iluvatar/global/src/typings";

const UserHistoryPage: React.FC = () => {
  const params = useParams({ strict: false });
  const navigator = useNavigate();
  const isMedia = useMediaQuery("only screen and (max-width: 468px)");
  const { uid } = useAuthenticationContext();

  const [user] = useFirestoreSubListener<UserEntity>(
    getUserPath(params.userId),
  );

  const isAdmin = uid === params.userId;

  return (
    <DefaultLayout className="profile-container flex start-start relative pb-12">
      {!isMedia ? (
        <ProfileImageBanner
          className="mt-12"
          onOrderChange={() => {}}
          isAdmin={false}
          images={
            user?.profiles.default.imageIds
              ?.map((id) => ({
                ...(user?.profiles.default.imagesById![id] || {}),
                id: id,
              }))
              .filter((ref) => !ref.metadata.isProcessing) || []
          }
        />
      ) : (
        <ImageCarousel
          userId={uid || ""}
          images={
            user?.profiles.default.imageIds
              ?.map((id) => ({
                ...(user?.profiles.default.imagesById![id] || {}),
                id: id,
              }))
              .filter((ref) => !ref.metadata.isProcessing) || []
          }
        />
      )}
      {user && (
        <Typography variant="h3" className="flex flex-row">
          {user?.profiles.default.displayName}
          {isAdmin && <FlexSpacer />}
          {isAdmin && (
            <Settings
              className="mb-1 ml-5 opacity-80 hover:opacity-100 clickable"
              onClick={() =>
                navigator({
                  to: "/user/$userId/edit",
                  params: {
                    userId: params.userId,
                  },
                })
              }
            />
          )}
        </Typography>
      )}
      {user && (
        <Typography variant="subtitle2">
          {user?.about.birthDate
            ? `${dayjs(new Date()).diff(user.about.birthDate, "years")}, `
            : ""}
          {[user.about.gender, user.about.sexuality].join(" ")}
        </Typography>
      )}
      {user?.profiles.default.subTitle && (
        <Typography variant="subtitle2">
          {user.profiles.default.subTitle}
        </Typography>
      )}
      <ProfileDescription>
        {user?.profiles.default.description}
      </ProfileDescription>
      <TagList
        items={user?.profiles.default.interests || []}
        label="Interests"
      />
      <TagList items={user?.profiles.default.desires || []} label="Desires" />
      <TagList
        items={user?.profiles.default.characteristics || []}
        label="Characteristics"
      />
      <Divider />
      <Button variant="outlined" className="mt-8" color="info" disabled>
        Report Account
      </Button>
    </DefaultLayout>
  );
};

export const Route = new FileRoute("/user/$userId/").createRoute({
  component: UserHistoryPage,
});
