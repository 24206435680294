// libraries
import { clsx } from "clsx";

// MUI
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/system/Box";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

// hooks
import { useToggle } from "../../hooks/useToggle";

// components
import { AccordionHeader } from "./AccordionHeader";
import { SxProps } from "@mui/system";

// styles
import "./accordion.scss";

export const Accordion: React.FC<
  React.PropsWithChildren<{
    title?: string | JSX.Element;
    subTitle?: string;
    remove?: boolean;
    className?: string;
    onRemove?: () => void;
    sub?: boolean;
    sx?: SxProps;
    sxHeader?: SxProps;
    open?: boolean;
    headless?: boolean;
    initialOpen?: boolean;
    slots?: {
      startAdornment?: React.ReactNode;
      endAdornment?: React.ReactNode;
      BeforeEnd?: React.ReactNode;
    };
    toggleOpenHandler?: () => void;
  }>
> = ({
  children,
  title,
  className,
  remove,
  onRemove,
  sub,
  subTitle,
  headless,
  initialOpen = true,
  sxHeader = {},
  slots,
  toggleOpenHandler,
  ...rest
}) => {
  const [internalOpen, toggleSetIsOpen] = useToggle(initialOpen);
  const isOpen = toggleOpenHandler ? rest.open : internalOpen;
  const toggleOpen = toggleOpenHandler ? toggleOpenHandler : toggleSetIsOpen();

  return (
    <Grid
      className={clsx("accordion-container", {
        headless,
        open: isOpen,
      })}
      {...rest}
    >
      {!headless && (
        <AccordionHeader
          title={title}
          subTitle={subTitle}
          open={isOpen}
          onClick={toggleOpen}
          variant={sub ? "h4" : "h3"}
          sx={{
            ...sxHeader,
            // ...(!isOpen
            //   ? {
            //       p: sub ? 1.5 : 2,
            //       pr: sub ? undefined : 6,
            //       pl: sub ? undefined : 5,
            //       mb: 0,
            //     }
            //   : { p: sub ? 1.5 : 2 }),
          }}
          slots={{
            startAdornment: slots?.startAdornment,
            endAdornment: (
              <>
                {slots?.endAdornment && slots.endAdornment}
                {remove && (
                  <IconButton sx={{ p: 0 }} onClick={onRemove}>
                    <RemoveCircleIcon color="secondary" fontSize="large" />
                  </IconButton>
                )}
              </>
            ),
          }}
        />
      )}
      {headless && remove && (
        <div className="floating-remove-button">
          <IconButton sx={{ p: 0 }} onClick={onRemove}>
            <RemoveCircleIcon color="secondary" fontSize="large" />
          </IconButton>
        </div>
      )}

      {/* TODO; refactor this box and its style into parent accordion-container element */}
      {isOpen && (
        <Box
          className={className}
          sx={{
            px: headless ? 0 : 2,
          }}
        >
          {children}
        </Box>
      )}
    </Grid>
  );
};
