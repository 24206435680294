import { useFormikContext } from "formik";
import { useMatch } from "@tanstack/react-router";

const FormikBlockRouting: React.FC = () => {
  // const formik = useFormikContext();
  // const match = useMatch();
  // const usePromp = usePr;
  // usePrompt(
  //   `You have unsaved inputs, are you sure you want to go to ${location.current.pathname}?`,
  //   formik.dirty,
  // );
  return <></>;
};

export default FormikBlockRouting;
