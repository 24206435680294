import { Link, NotFoundRoute } from "@tanstack/react-router";
import { Route as rootNode } from "./__root";

// components
import UnAuthLayout from "../layout/unauthed-page";

// styles
import "../scss/auth.scss";
import { Typography } from "@mui/material";

function NotFound(): JSX.Element {
  return (
    <UnAuthLayout>
      <div className="flex flex-col h-[100vh] justify-center place-items-center">
        <Typography variant="h2">Page Not Found</Typography>
        <Link to="/" className="mt-1 hover:text-[var(--color-primary)]">
          Take me home
        </Link>
      </div>
    </UnAuthLayout>
  );
}

export const Route = new NotFoundRoute({
  getParentRoute: () => rootNode,
  component: NotFound,
});
