// libraries
import { useCallback, useEffect } from "react";
import { useField } from "formik";

// context
import { useFormContext } from "../../../../contexts/form.context";

// MUI
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import Radio, { RadioProps } from "@mui/material/Radio";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";

// components
import { fieldValidator } from "..";

// types
import { IFieldBase, IFieldOptions } from "@iluvatar/global/src/typings";

interface RadioGroupInterface extends Omit<IFieldBase, "label"> {
  label?: string;
  className?: string;
  options?: IFieldOptions[];
  radioProps?: Partial<RadioProps>;
  formControlProps?: Partial<FormControlProps>;
  formControlLabelProps?: Omit<Partial<FormControlLabelProps>, "control">;
}

export const RadioField: React.FC<RadioGroupInterface> = ({
  className,
  name,
  label,
  disabled,
  required,
  readonly,
  defaultValue,
  description,
  options = [],
  formControlLabelProps = {},
  formControlProps = {},
  radioProps = {},
}) => {
  const { onFieldChange } = useFormContext();

  const [field, { touched, error }, { setValue }] = useField({
    name,
    validate: fieldValidator(required),
  });

  useEffect(() => {
    if (field.value === undefined && defaultValue !== undefined) {
      setValue(defaultValue);
      onFieldChange(name, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Derived Properties
   */
  const changeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!readonly) {
        setValue(e.currentTarget.value);
        onFieldChange(name, e.currentTarget.value);
      }
    },
    [readonly, setValue, onFieldChange, name],
  );

  const hasError = Boolean(touched && error);
  const note = hasError ? error : description;

  return (
    <FormControl
      {...formControlProps}
      error={hasError}
      disabled={disabled || readonly}
      required={required}
      className={className}
    >
      {label && (
        <FormLabel id={`${name}-radio-buttons-group-label`}>{label}</FormLabel>
      )}
      <RadioGroup
        name={name}
        defaultValue={defaultValue}
        aria-labelledby={`${name}-radio-buttons-group-label`}
      >
        {options.map((option) => (
          <FormControlLabel
            key={`${option.label}-${option.value}`}
            {...formControlLabelProps}
            value={option.value}
            label={option.label}
            control={
              <Radio
                color="primary"
                {...radioProps}
                {...field}
                onChange={changeHandler}
                value={option.value}
                inputProps={{
                  ...(radioProps.inputProps || {}),
                  enterKeyHint: "next",
                }}
                checked={option.value === field.value}
              />
            }
          />
        ))}
      </RadioGroup>

      {Boolean(note) && (
        <FormHelperText error={hasError}>{note}</FormHelperText>
      )}
    </FormControl>
  );
};
