// components
import { Section } from "../FormSection";
import { FormFieldOrElement } from "../Fields";
import { FormGroupComponent } from "../FormGroupComponent";

// utilities
import { isObject } from "@iluvatar/global/src/utilities";
import { sectionOrdering } from "../helpers";
import { fieldGroupOrdering, joinPath } from "../utilities";

// types
import {
  IElement,
  IField,
  IFieldGroupBuilt,
  isFieldGroup,
} from "@iluvatar/global/src/typings";
import { FormDefinitionProps } from "../types";

function isSection(
  obj: unknown,
): obj is { sections: Record<string, IFieldGroupBuilt>; ordering: string[] } {
  return Boolean(isObject(obj) && obj.sections);
}

export function RenderFormDefinition({
  formDefinition,
  path,
  readonly,
  disabled,
  parentPath,
  CatFishComponent,
  slots,
}: FormDefinitionProps) {
  /**
   * If the formDefinition passed in is an ObjectConfig (sections) then render the sections
   */
  if (isSection(formDefinition)) {
    return (
      <>
        {sectionOrdering(formDefinition).map(([_idx, section]) => (
          <Section
            key={_idx}
            sectionKey={_idx}
            {...section}
            parentPath={parentPath}
            name={joinPath(path, section.name)}
            readonly={section.readonly || readonly}
            disabled={section.disabled || disabled}
            CatFishComponent={CatFishComponent}
            slots={{
              AfterHeader: slots?.TopLevelSectionHeader && (
                <slots.TopLevelSectionHeader sectionId={_idx} />
              ),
            }}
          />
        ))}
      </>
    );
  }

  /**
   * If the formDefinition passed in is a FieldGroup then render fields and groups
   */
  if (isFieldGroup(formDefinition)) {
    return (
      <div className="form-fields">
        {fieldGroupOrdering<IFieldGroupBuilt, IField | IElement>(
          formDefinition,
        ).map((order) => {
          if (order.type === "field") {
            return CatFishComponent !== undefined ? (
              <CatFishComponent
                {...order.entry}
                key={joinPath(path, order.entry.name)}
                name={joinPath(path, order.entry.name)}
                readonly={order.entry?.readonly || readonly}
                disabled={order.entry?.disabled || disabled}
                className={
                  "flex-" + (order.entry.span || 1) + "-cols form-field"
                }
              />
            ) : (
              <FormFieldOrElement
                {...order.entry}
                key={joinPath(path, order.entry.name)}
                name={joinPath(path, order.entry.name)}
                readonly={order.entry?.readonly || readonly}
                disabled={order.entry?.disabled || disabled}
                className={
                  "flex-" + (order.entry.span || 1) + "-cols form-field"
                }
              />
            );
          } else if (order.entry.isSection) {
            return (
              <Section
                {...order.entry}
                key={joinPath(path, order.entry.name)}
                sectionKey={joinPath(path, order.entry.name)}
                readonly={order.entry.readonly || readonly}
                disabled={order.entry.disabled || disabled}
                parentPath={parentPath}
                CatFishComponent={CatFishComponent}
              />
            );
          } else {
            return (
              <FormGroupComponent
                {...order.entry}
                key={joinPath(path, order.entry.name)}
                name={joinPath(path, order.entry.name)}
                readonly={order.entry.readonly || readonly}
                disabled={order.entry.disabled || disabled}
                CatFishComponent={CatFishComponent}
              />
            );
          }
        })}
      </div>
    );
  }
  return <></>;
}
