// MUI
import { Typography } from "@mui/material";

// types
import { IElementText } from "@iluvatar/global/src/typings";

interface TextElementInterface extends IElementText {
  className?: string;
}

export const Text: React.FC<Omit<TextElementInterface, "fieldType">> = (
  props,
) => {
  return (
    <Typography
      className={props.className}
      variant="body2"
      sx={{ whiteSpace: "pre-line" }}
    >
      {props?.text}
    </Typography>
  );
};
