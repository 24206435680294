// libraries
import dayjs, { extend } from "dayjs";
import { useState } from "react";
import { FileRoute } from "@tanstack/react-router";
import relativeTime from "dayjs/plugin/relativeTime";
extend(relativeTime);

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Grade, Remove } from "@mui/icons-material";

import { cn } from "@/utilities";
import { imgRefSize } from "@/services/file";

// hooks
import { useQueryLikes } from "@/hooks/getMatchQuery";
import { useStorageUrl } from "@/hooks/useStorageUrl";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useNotificationDismissal } from "@/hooks/notificationDismissal";

// components
import DefaultLayout from "@/layout/default-page";
import { FlexSpacer } from "@/components/FlexSpacer";
import { AspectRatio } from "@/components/ui/aspect-ratio";

// types
import { IFileReference } from "@iluvatar/global/src/typings";

const LikesPage: React.FC = () => {
  useNotificationDismissal("likes");

  const { user, api } = useCurrentUser();
  const [filtered, setFiltered] = useState<string[]>([]);
  const [communityId, setCommunityId] = useState("default");
  const [likes, total, errors, loadMore] = useQueryLikes({ communityId });

  const likeUser = function likeUserHandler(userId: string) {
    return async function innerClickHandler() {
      setFiltered((u) => u.concat(userId));
      await api.likeUser(userId);
    };
  };

  const hideUser = function likeUserHandler(userId: string) {
    return async function innerClickHandler() {
      setFiltered((u) => u.concat(userId));
      await api.ignoreUser(userId);
    };
  };
  const _total = user?.likesCountMap[communityId] || 0;

  return (
    <DefaultLayout className="profile-container flex start-start pb-12">
      <Typography variant="h2" className="mt-8">
        <span className="text-[var(--color-primary)] mr-2">
          {isNaN(_total) ? 0 : _total}
        </span>
        Like{_total !== 1 ? "s" : ""}
      </Typography>
      {!user?.likesCountMap[communityId] && <div>No Likes yet</div>}
      {(likes || [])
        ?.filter((m) => !m?.uid || !filtered.includes(m?.uid))
        .map((userProfile) => {
          return userProfile.profiles && userProfile.profiles[communityId] ? (
            <LikedUserPreview
              isSubscribed={user?.isSubscribed}
              key={userProfile.uid}
              uid={userProfile.uid}
              gender={userProfile?.about?.gender}
              subTitle={userProfile?.about?.pronouns}
              sexuality={userProfile?.about?.sexuality}
              birthDate={userProfile?.about?.birthDate}
              displayName={userProfile.profiles[communityId].displayName!}
              imagesById={userProfile.profiles[communityId].imagesById || {}}
              imageIds={userProfile.profiles[communityId].imageIds || []}
              likeUser={likeUser}
              removeUser={hideUser}
            />
          ) : (
            <div>err</div>
          );
        })}

      {loadMore && (
        <Button className="mt-5" onClick={loadMore}>
          Load More
        </Button>
      )}
    </DefaultLayout>
  );
};

export const Route = new FileRoute("/likes").createRoute({
  component: LikesPage,
});

function LikedUserPreview({
  uid,
  displayName,
  imagesById,
  imageIds,
  className,
  birthDate,
  gender,
  sexuality,
  subTitle,
  isSubscribed,
  removeUser = () => () => {},
  likeUser = () => () => {},
  ...rest
}: {
  uid?: string;
  displayName: string;
  birthDate?: number;
  gender?: string;
  sexuality?: string;
  subTitle?: string;
  imagesById: Record<string, IFileReference>;
  imageIds: string[];
  isSubscribed?: boolean;
  removeUser?: (_uid: string) => React.MouseEventHandler;
  likeUser?: (_uid: string) => React.MouseEventHandler;
} & React.HTMLAttributes<HTMLDivElement>) {
  if (!displayName) {
    return <div>err</div>;
  }
  return (
    <div {...rest} className={cn(className, "mt-3 mb-3")}>
      <div className="max-h-[122px] flex row gap-1 flex-nowrap overflow-x-auto overflow-y-hidden ">
        {(imageIds || []).map((iid) => (
          <ImagePrev key={iid} imgRef={imagesById[iid]} blur={!isSubscribed} />
        ))}
      </div>
      <div className="flex row items-center gap-3 mt-1">
        <div>
          <Typography variant="h4" className="mt-1 mb-1">
            {displayName}
          </Typography>
          {(birthDate || gender || sexuality) && (
            <Typography variant="subtitle2">
              {birthDate
                ? `${dayjs(new Date()).diff(birthDate, "years")}, `
                : ""}
              {[gender, sexuality].filter(Boolean).join(" ")}
            </Typography>
          )}
          {subTitle && <Typography variant="subtitle2">{subTitle}</Typography>}
        </div>
        <FlexSpacer />
        <Button
          color="info"
          disabled={!uid}
          sx={{ borderRadius: "12px" }}
          onClick={uid ? removeUser(uid) : undefined}
          className="bg-[var(--color-primary--hover)] p-[9px] min-w-0"
        >
          <Remove />
        </Button>
        <Button
          disabled={!uid}
          sx={{ borderRadius: "12px" }}
          onClick={uid ? likeUser(uid) : undefined}
          className="bg-[var(--color-primary--hover)] p-[9px] min-w-0"
        >
          <Grade />
        </Button>
      </div>
    </div>
  );
}

function ImagePrev({
  imgRef,
  blur,
}: {
  imgRef?: IFileReference;
  blur?: boolean;
}) {
  const [fileUrl, loading, error] = useStorageUrl(imgRefSize(imgRef, 40));
  return (
    <div className="min-w-[120px] w-[120px] h-[120px] min-h-[120px]">
      <AspectRatio ratio={1} className="overflow-hidden rounded-[3px]">
        <img
          className={cn("image-thumb rounded-[0px]", { "blur-[8px]": blur })}
          src={fileUrl}
        />
      </AspectRatio>
    </div>
  );
}
