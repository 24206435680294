// libraries
import { FormikHelpers, FormikValues } from "formik";

// utilities

// constants
import { FORM_GLOBAL_ERROR_KEY } from "@iluvatar/global/src/constants";

// types
import { TFormOnChangeFunction } from "../../contexts/form.context";
import {
  IErrorResponse,
  IValidationError,
} from "@iluvatar/global/src/typings/generics";
import {
  IFieldGroup,
  IField,
  IFieldGroupBuilt,
  IFieldGroupReference,
  IFieldReference,
} from "@iluvatar/global/src/typings";
import {
  entriesToValues,
  sortObj,
  traverseObjectPath,
} from "@iluvatar/global/src/utilities";

/**
 * Only for use within the onChanges feature of the dynamic form
 */
export function clearField<Value extends FormikValues>(
  targetField: string,
  clearValue: unknown = "",
): TFormOnChangeFunction<Value> {
  return function innerOnChangeHandler(_value, { touched, setFieldValue }) {
    traverseObjectPath(targetField, touched) &&
      setFieldValue(targetField, clearValue);
  };
}

export const copyFieldValue =
  <Value extends FormikValues>(
    targetField: string,
  ): TFormOnChangeFunction<Value> =>
  (value, { touched, setFieldValue }) => {
    // only update target field when it hasn't been edited
    !traverseObjectPath(targetField, touched) &&
      setFieldValue(targetField, value, false);
  };

export function formErrorHandler<Values>(
  errors: unknown,
  formHelpers: FormikHelpers<Values>,
): void {
  const err = errors as IErrorResponse;
  // Backend Yup Validation errors
  formHelpers.setFieldError(FORM_GLOBAL_ERROR_KEY, "Form Submission Error");
  if (err?.errors && Array.isArray(err.errors)) {
    err.errors.forEach((er: IValidationError) => {
      formHelpers.setFieldError(er.path || FORM_GLOBAL_ERROR_KEY, er.message);
    });
  } else if (err.message) {
    formHelpers.setFieldError(FORM_GLOBAL_ERROR_KEY, err.message.toString());
  }
  // eslint-disable-next-line no-console
  console.warn(errors);
}

export function stringToKey<Value extends FormikValues>(
  targetField: string,
): TFormOnChangeFunction<Value> {
  return function changeHandler(value, { touched, setFieldValue }) {
    if (
      !traverseObjectPath(targetField, touched) &&
      typeof value === "string"
    ) {
      setFieldValue(
        targetField,
        value.toLowerCase().trim().replaceAll(" ", "-"),
      );
    }
  };
}

export function filterField(
  _f: IField | IFieldReference | undefined,
): _f is IField | IFieldReference {
  return _f !== undefined;
}

export function sectionFilter(
  args: [string, IFieldGroupBuilt | undefined],
): args is [string, IFieldGroupBuilt] {
  return args[1] !== undefined;
}

export function sectionOrdering<
  G = IFieldGroup | IFieldGroupReference | IFieldGroupBuilt,
>(object?: { sections: Record<string, G>; ordering: string[] }): [string, G][] {
  if (!object) {
    return [];
  }

  const mapsGroup = new Map<string, G>();

  Object.entries(object.sections).forEach(([_idx, _group]) => {
    if (_group) {
      mapsGroup.set(_idx, _group as G);
    }
  });

  if (object.ordering) {
    return object.ordering
      .map<[string, G | undefined]>((_name) => [_name, mapsGroup.get(_name)])
      .filter((args): args is [string, G] => args[1] !== undefined);
  }

  return Object.entries(object?.sections || {})
    .sort(entriesToValues(sortObj("label")))
    .filter<[string, G]>((args): args is [string, G] => args[1] !== undefined);
}

export function sectionOrderingFilter(
  _a: [string, IFieldGroup | IFieldGroupReference | undefined],
): _a is [string, IFieldGroup | IFieldGroupReference] {
  return _a[1] !== undefined;
}
