// libraries
import { v4 } from "uuid";

export const isPdf = (fileType?: string | null): boolean => {
  return Boolean(fileType?.includes("pdf"));
};

export const isImage = (fileType?: string): boolean => {
  return Boolean(fileType?.includes("img") || fileType?.includes("image"));
};

/**
 * return file uuid
 */
export function fileUUID(): string {
  return v4();
}

/**
 * Returns the extension of the file. e.g., `.pdf` or `.jpeg`
 * @param filename {string}
 * @returns string
 */
export function getFileExtension(filename: string): string {
  const match = filename.match(/(\.\w+)$/);
  return match ? match[0] : "";
}
