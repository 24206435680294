// libraries
import { useCallback, useMemo } from "react";
import { useField } from "formik";

// MUI
import {
  TextField,
  Autocomplete,
  Chip,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderGetTagProps,
} from "@mui/material";

// context
import { useFormFieldContext } from "../../../../contexts/form.context";

// components
import { fieldValidator } from "..";

// types
import { IFieldBase, IFieldOptions } from "@iluvatar/global/src/typings";

// styles
import "./autocomplete.scss";

type AutoCompTypes = AutocompleteProps<
  IFieldOptions,
  boolean,
  boolean,
  boolean
>;
interface AutocompleteFieldProps
  extends Omit<IFieldBase, "defaultValue">,
    Omit<AutoCompTypes, "renderInput" | "defaultValue"> {
  options: IFieldOptions[];
  freeSolo?: boolean;
  multiple?: boolean;
  className?: string;
  freeSoloMaxLength?: number;
}

function equalityCheck(
  option: IFieldOptions | string,
  val?: IFieldOptions | string | undefined,
): boolean {
  const opt = typeof option === "string" ? option : option?.value;
  const eqValue = typeof val === "string" ? val : val?.value;
  return opt === eqValue;
}

export const AutocompleteField: React.FC<AutocompleteFieldProps> = ({
  className,
  label,
  name,
  description,
  disabled,
  required,
  readonly,
  options = [],
  freeSolo,
  multiple,
  onChange,
  renderOption = undefined,
  freeSoloMaxLength,
  ...props
}) => {
  const { onFieldChange, options: optionsOverride } = useFormFieldContext(name);
  const [fieldProps, meta, helpers] = useField({
    name,
    validate: fieldValidator(required),
  });
  const { touched, error } = meta;
  const { setValue } = helpers;
  const { value } = fieldProps;

  /*
   * Derived Properties
   */
  const changeHandler = useCallback<NonNullable<AutoCompTypes["onChange"]>>(
    (_e, selectedValue, reason) => {
      let selectedValues;
      if (Array.isArray(selectedValue)) {
        selectedValues = selectedValue.map(
          (optionValue: IFieldOptions | string) => {
            if (typeof optionValue === "string") {
              return optionValue;
            }
            return optionValue.value;
          },
        );
      } else {
        selectedValues =
          typeof selectedValue === "string"
            ? selectedValue
            : selectedValue?.value;
      }
      onChange && onChange(_e, selectedValue, reason);
      setValue(selectedValues);
      onFieldChange(selectedValues);
    },
    [onChange, onFieldChange, setValue],
  );

  const hasError = Boolean(touched && error);
  const note = hasError ? error : description;

  // optionsOverride take presidency over default ones
  const renderOptions =
    optionsOverride && optionsOverride.length ? optionsOverride : options;

  // Selected value must be the same type as the option
  let fixedValue: IFieldOptions[] | IFieldOptions | string | undefined;
  if (multiple) {
    // set default values to empty array when multi select option enabled
    fixedValue = (Array.isArray(value) ? value : value ? [value] : []).map(
      (val: string) =>
        renderOptions.find((v) => v.value === val) || {
          key: val,
          value: val,
          label: val,
        },
    );
  } else {
    fixedValue = value
      ? renderOptions.find((val) => val.value === value) || value
      : null;
  }

  // TODO: figure out if freeSolo is working. and how the UI can display the selection better
  const onKeyDown: NonNullable<AutoCompTypes["onKeyDown"]> = (event) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const target = event.target as any;
    // set timeout needed to have html .value set with the correct value
    // if free solo is set on enter add current option.
    if (freeSolo && event.key === "Enter") {
      // Prevent's default 'Enter' behavior.
      setValue(target.value);
      onFieldChange(target.value);
      event.preventDefault();
    } else if (freeSolo && !multiple) {
      setValue(target.value);
      onFieldChange(target.value);
    }
  };

  const renderTags = useMemo(
    function renderTagsMemo() {
      if (multiple) {
        return (
          optionValue: readonly IFieldOptions[],
          getTagProps: AutocompleteRenderGetTagProps,
        ) =>
          optionValue.map((option: IFieldOptions, index: number) => (
            <Chip
              {...getTagProps({ index })}
              key={getTagProps({ index }).key.toString()}
              variant="outlined"
              label={typeof option === "string" ? option : option?.label}
            />
          ));
      }
      return undefined;
    },
    [multiple],
  );

  const getOptionLabel = useMemo(
    function getOptionLabel() {
      if (renderOption) {
        return undefined;
      }
      return (option: string | IFieldOptions<Record<string, unknown>>) =>
        typeof option === "string" ? option : option?.label;
    },
    [renderOption],
  );

  return (
    <Autocomplete<IFieldOptions, boolean, boolean, boolean>
      filterSelectedOptions
      value={fixedValue}
      disabled={disabled || readonly}
      freeSolo={freeSolo}
      multiple={multiple}
      className={className}
      renderOption={renderOption}
      onKeyDown={onKeyDown}
      options={renderOptions}
      onChange={changeHandler}
      isOptionEqualToValue={equalityCheck}
      getOptionLabel={getOptionLabel}
      renderTags={renderTags}
      {...props}
      renderInput={function renderInputHandler(params) {
        return (
          <TextField
            {...params}
            label={label}
            error={hasError}
            required={required}
            disabled={disabled || readonly}
            helperText={note}
            inputProps={{
              ...(params.inputProps || {}),
              enterKeyHint: "next",
              maxLength: freeSoloMaxLength,
            }}
          />
        );
      }}
    />
  );
};
