// libraries
import { useCallback, useEffect } from "react";
import { useField } from "formik";
import { clsx } from "clsx";

// MUI
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import InputLabel, { InputLabelProps } from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";

// context
import { useFormContext } from "../../../../contexts/form.context";

// components

// types
import { IFieldBase } from "@iluvatar/global/src/typings";
import { numberValidator } from "..";

interface NumberFieldInterface extends IFieldBase {
  className?: string;
  showMaxLength?: number;
  outlinedInputProps?: Partial<OutlinedInputProps>;
  formControlProps?: FormControlProps;
  inputLabelProps?: InputLabelProps;
}

export const NumberField: React.FC<NumberFieldInterface> = ({
  min,
  max,
  name,
  label,
  disabled,
  readonly,
  required,
  autoFocus,
  className,
  description,
  defaultValue,
  labelPlacement = "inline",
  inputLabelProps,
  formControlProps,
  outlinedInputProps,
}) => {
  const { onFieldChange } = useFormContext();

  const [fieldProps, meta, helpers] = useField({
    name,
    validate: numberValidator(required, min, max),
  });
  const { value, onBlur } = fieldProps;
  const { touched, error } = meta;

  useEffect(() => {
    if (!value && defaultValue) {
      helpers.setValue(defaultValue);
      onFieldChange(name, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Derived Properties
   */
  const changeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const _value = e.currentTarget.value;
      let nextValue = undefined;

      if (!_value || _value === "") {
        nextValue = undefined;
      } else if (
        _value.endsWith(".") &&
        !_value.substring(0, _value.length - 1).includes(".")
      ) {
        nextValue = _value;
      } else if (
        (_value.endsWith(".") &&
          _value.substring(0, _value.length - 1).includes(".")) ||
        isNaN(Number(e.currentTarget.value))
      ) {
        nextValue = _value.substring(0, _value.length - 1);
      } else {
        nextValue = Number(e.currentTarget.value);
      }

      helpers.setValue(nextValue);
      onFieldChange(name, nextValue);
    },
    [helpers, onFieldChange, name],
  );

  // fixes display bug with required star overlapping border
  const fixedLabel = required ? label + "*" : label;
  const labelAbove = labelPlacement === "above";
  const labelInline = labelPlacement === "inline";

  // || "" so so this is a controlled field
  const fixedValue = value || "";

  const hasError = Boolean(touched && error);
  const note = hasError ? error : description;
  return (
    <FormControl
      variant="outlined"
      className={clsx({ readonly }, className)}
      {...formControlProps}
      disabled={disabled || readonly}
      required={required}
      error={hasError}
    >
      {labelAbove && <div className="label-above">{fixedLabel}</div>}
      {labelInline && <InputLabel {...inputLabelProps}>{label}</InputLabel>}
      <OutlinedInput
        {...outlinedInputProps}
        name={name}
        id={name}
        onChange={changeHandler}
        autoFocus={autoFocus}
        onBlur={onBlur}
        label={labelInline ? fixedLabel : undefined}
        value={fixedValue}
        inputProps={{
          maxLength: max,
          enterKeyHint: "next",
          inputMode: "decimal",
        }}
      />

      {Boolean(note) && (
        <FormHelperText
          id={`${name}-help-text`}
          className="flex"
          data-error={error}
          error={hasError}
        >
          {note}
        </FormHelperText>
      )}
    </FormControl>
  );
};
