import { Typography } from "@mui/material";

import "./image-thumb.scss";

export function ProcessingImage({ children }: { children?: React.ReactNode }) {
  return (
    <div className="image-processing flex-col item image-thumb center-center relative">
      <Typography variant="overline" className="mb-3">
        Processing
      </Typography>
      <Typography variant="caption" className="mb-3 text-center">
        Image uploaded and being processed
      </Typography>
      {children}
    </div>
  );
}
