// MUI
import { Button, SxProps } from "@mui/material";

import Close from "@mui/icons-material/Close";

export const RemoveFileButton: React.FC<{
  onClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  error?: boolean;
  sx?: SxProps;
}> = ({ disabled, error, sx = {}, onClick }) => {
  if (!onClick) {
    return null;
  }

  return (
    <Button
      size="small"
      className="close"
      color={error ? "error" : "info"}
      variant="outlined"
      disabled={disabled}
      sx={{
        "backgroundColor": "var(--color-background-default)",
        "position": "absolute",
        "minWidth": "unset",
        "right": "-4px",
        "top": "-4px",
        "p": "2px",
        ...sx,
        "&:hover": {
          backgroundColor: "var(--color-background-default)",
        },
      }}
      onClick={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        onClick && onClick(ev);
      }}
    >
      <Close sx={{ width: "16px", height: "16px" }} />
    </Button>
  );
};
