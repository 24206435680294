// MUI
import Divider from "@mui/material/Divider";

// types
import { IFormElementBase } from "@iluvatar/global/src/typings";

interface HorizontalDividerInterface extends IFormElementBase {
  className?: string;
}

export const HorizontalDivider: React.FC<
  Omit<HorizontalDividerInterface, "fieldType">
> = (props) => {
  return (
    <div className={props.className}>
      <Divider flexItem sx={{ mt: "16px", mb: "24px", minWidth: "100%" }} />
    </div>
  );
};
