// MUI
import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";

export const FilePreviewContainer: React.FC<{
  disabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  error?: boolean;
  sx?: SxProps;
  className?: string;
}> = ({
  className,
  disabled,
  children,
  error,
  sx = {},
  onClick = () => null,
}) => {
  return (
    <Box
      className={className}
      onClick={onClick}
      sx={{
        width: "80px",
        height: "80px",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: disabled ? 0.7 : 1,
        borderRadius: "16px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: error
          ? "var(--color-chart--overdue--dark)"
          : "var(--color-divider)",
        m: "4px",
        ["&:hover .close"]: {
          display: "flex",
        },
        ["& .close"]: {
          display: "none",
        },
        ...sx,
      }}
    >
      {children && children}
    </Box>
  );
};
