// Firebase Collection keys
export const USERS_COLLECTION = "users";
export const MATCH_COLLECTION = "matches";
export const AUDIT_COLLECTION = "audit";
export const CONFIG_COLLECTION = "config";
export const NOTIFICATION_COLLECTION = "notifications";

export function getUserPath(uid: string) {
  return [USERS_COLLECTION, uid].join("/");
}
