// libraries
import { clsx } from "clsx";
import { useField } from "formik";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useMemo } from "react";

// MUI
import FormHelperText from "@mui/material/FormHelperText";
import FormControl, { FormControlProps } from "@mui/material/FormControl";

import {
  TimePicker as MuiTimePicker,
  TimePickerProps,
  TimePickerToolbar,
} from "@mui/x-date-pickers/TimePicker";

// context
import { useFormContext } from "../../../../contexts/form.context";

// components
import { fieldValidator } from "..";

// types
import { IFieldBase } from "@iluvatar/global/src/typings";

interface KeyboardDatePickerInterface extends IFieldBase {
  formControlProps?: FormControlProps;
  keyboardDatePicketProps?: Partial<TimePickerProps<Dayjs>>;
  className?: string;
  outputFormat?: string;
  outputString?: boolean;
}

const ClockPicker: React.FC<KeyboardDatePickerInterface> = ({
  className,
  name,
  label,
  labelPlacement = "inline",
  description,
  required,
  disabled,
  readonly,
  outputFormat = "THH:mm:ss.SSS",
  formControlProps = {},
  keyboardDatePicketProps = {},
}) => {
  const { onFieldChange } = useFormContext();
  const [fieldProps, meta, helpers] = useField({
    name,
    validate: fieldValidator(required),
  });

  const { value: fieldValue } = fieldProps;
  const { setValue } = helpers;

  const value = useMemo(() => {
    if (fieldValue) {
      return dayjs(fieldValue, outputFormat);
    } else {
      return null;
    }
  }, [fieldValue, outputFormat]);

  const onChange = useCallback(
    (time: Dayjs | null) => {
      if (readonly) {
        return;
      }
      const nextValue = time?.format(outputFormat);
      setValue(nextValue);
      onFieldChange(name, nextValue);
    },
    [readonly, setValue, onFieldChange, name, outputFormat],
  );

  const fixedLabel = required ? label + "*" : label;
  const labelAbove = labelPlacement === "above";
  const labelInline = labelPlacement === "inline";

  const hasError = Boolean(meta.touched && meta.error);
  const note = hasError ? meta.error : description;

  return (
    <FormControl
      {...formControlProps}
      className={clsx({ readonly }, className)}
      disabled={disabled || readonly}
      required={required}
      error={hasError}
    >
      {labelAbove && <div className="label-above">{fixedLabel}</div>}
      <MuiTimePicker
        {...fieldProps}
        {...keyboardDatePicketProps}
        closeOnSelect
        label={labelInline ? fixedLabel : undefined}
        value={value}
        readOnly={readonly}
        disabled={disabled || readonly}
        desktopModeMediaQuery="@media (fine)"
        onChange={onChange}
        onOpen={() => {
          if (!value) {
            onChange(dayjs(Date.now()));
          }
        }}
        slots={{
          toolbar: (props) => (
            <TimePickerToolbar<Dayjs>
              {...props}
              sx={{
                "& .MuiTimePickerToolbar-ampmSelection": {
                  "& .MuiButtonBase-root.MuiPickersToolbar-root": {
                    borderRadius: "4px",
                    px: "2px",
                  },
                },
                "& .MuiTimePickerToolbar-hourMinuteLabel": {
                  "ml": "auto",
                  "alignItems": "center",
                  "& .MuiButtonBase-root.MuiPickersToolbar-root": {
                    "py": "6px",
                    "px": "12px",
                    "minWidth": "60px",
                    "borderRadius": "8px",
                    "& .MuiPickersToolbarText-root": {
                      fontSize: "42px",
                      lineHeight: "42px",
                    },
                  },
                },
              }}
            />
          ),
        }}
        slotProps={{
          textField: {
            size: formControlProps.size,
          },
          actionBar: {
            actions: ["clear", "accept"],
          },
        }}
      />
      {Boolean(note) && (
        <FormHelperText error={hasError}>{note}</FormHelperText>
      )}
    </FormControl>
  );
};

export default ClockPicker;
