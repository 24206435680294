// libraries
import { clsx } from "clsx";
import { useField } from "formik";
import tinycolor from "tinycolor2";
import {
  ChangeEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

// MUI
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputBaseComponentProps,
  InputLabel,
  InputLabelProps,
  OutlinedInput,
  OutlinedInputProps,
  Popover,
} from "@mui/material";

// assets
// import { ReactComponent as PaletteIcon } from "../../../../assets/font-awesome/light/fill-drip.svg";

// utilities
import { fieldValidator } from "..";
import { isValidHex } from "@iluvatar/global/src/utilities/colors";

// hooks
import { useFormContext } from "../../../../contexts/form.context";

// components
import { ColorSwatch } from "./ColorSwatch";

// types
import { IFieldBase } from "@iluvatar/global/src/typings";

// styles
import "./color-field.scss";

// IFieldColorSelect
interface ColorFieldInterface extends IFieldBase {
  className?: string;
  inline?: boolean;
  outlinedInputProps?: Partial<OutlinedInputProps>;
  formControlProps?: FormControlProps;
  inputLabelProps?: InputLabelProps;
}

export const ColorField: React.FC<ColorFieldInterface> = ({
  className,
  name,
  label,
  inline,
  disabled,
  readonly,
  required,
  description,
  defaultValue,
  outlinedInputProps,
  inputLabelProps,
  formControlProps,
}) => {
  const { onFieldChange } = useFormContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);

  const [{ value }, { touched, error }, helpers] = useField({
    name,
    validate: fieldValidator(required),
  });

  useEffect(() => {
    if (!value && defaultValue) {
      helpers.setValue(defaultValue);
      onFieldChange(name, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * hex color value
   */
  const colorChangeHandler = useCallback(
    (hexColor: string) => {
      helpers.setValue(hexColor);
      onFieldChange(name, hexColor);
    },
    [helpers, onFieldChange, name],
  );

  // fixes display bug with required star overlapping border
  const fixedLabel = required ? label + "*" : label;

  // || "" so so this is a controlled field
  const fixedValue = value || "#ffffee";

  /*
   * this is a naive string length approach, emoji and unicode characters may be
   * considered a single character but count for multiple length values.
   */
  const hasError = Boolean(touched && error);
  const note = hasError ? error : description;
  const defaultColors: string[] = [
    "#FF6900",
    "#FCB900",
    "#7BDCB5",
    "#00D084",
    "#8ED1FC",
    "#069383",
    "#ABE8CC",
    "#EBE44C",
    "#F70DA7",
    "#9910E1",
  ];

  if (inline) {
    return (
      <>
        <ColorSwatch
          color={fixedValue}
          onClick={(ev) => setAnchorEl(ev.currentTarget)}
          style={{ margin: "auto" }}
        >
          {/* <PaletteIcon
            width="20px"
            color={
              tinycolor(fixedValue).isLight()
                ? "var(--color-text--light)"
                : "var(--color-text--dark)"
            }
          /> */}
        </ColorSwatch>
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <ColorComponent
            style={{ maxWidth: "280px" }}
            value={fixedValue}
            colors={defaultColors}
            colorChangeHandler={colorChangeHandler}
          />
        </Popover>
      </>
    );
  }

  return (
    <FormControl
      variant="outlined"
      {...formControlProps}
      className={clsx({ readonly }, className)}
      disabled={disabled || readonly}
      required={required}
      error={hasError}
    >
      <InputLabel {...inputLabelProps}>{label}</InputLabel>
      <OutlinedInput
        {...outlinedInputProps}
        id={name}
        name={name}
        label={fixedLabel}
        value={fixedValue}
        inputProps={{
          colorChangeHandler,
          colors: defaultColors,
        }}
        inputComponent={ColorComponent}
      />

      {Boolean(note) && (
        <FormHelperText
          id={`${name}-help-text`}
          className="flex"
          data-error={error}
          error={hasError}
        >
          {note}
        </FormHelperText>
      )}
    </FormControl>
  );
};

type ColorComponentProps = InputBaseComponentProps & {
  colors: string[];
  value: string;
  colorChangeHandler: (color: string) => void;
};

const ColorComponent =
  forwardRef<Partial<HTMLInputElement>, ColorComponentProps>( // prettier-ignore
    function ColorComponent({ colorChangeHandler, ...props }, forwardedRef) {
      const innerRef = useRef<HTMLInputElement>(null);

      useEffect(() => {
        if (innerRef.current) {
          innerRef.current.value = props.value.replace("#", "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.value]);

      // TODO: I don't thin this useImperativeHandler does anything. The focus event goes to the inner input element with and without the hook
      // But the blue border that should be there is not. Not sure whats happening
      useImperativeHandle(
        forwardedRef,
        () => ({
          onfocus: () => {
            if (innerRef.current && innerRef.current.focus) {
              innerRef.current?.focus();
            }
          },
          onblur: () => {
            innerRef.current?.blur();
          },
        }),
        [],
      );

      const changeHandler: ChangeEventHandler<HTMLInputElement> = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        const hex = ev.target.value.startsWith("#")
          ? ev.target.value
          : `#${ev.target.value}`;
        isValidHex(hex) && colorChangeHandler(hex);
      };

      const focusInput = () => {
        if (innerRef.current && innerRef.current.focus) {
          innerRef.current?.focus();
        }
      };

      return (
        <div className="color-field" onClick={focusInput} style={props.style}>
          {props.colors.map((c) => (
            <ColorSwatch
              color={c}
              key={c}
              active={c === props.value}
              onClick={function setColor() {
                isValidHex(c) && colorChangeHandler(c);
              }}
            />
          ))}
          <div>
            <span className="hash">#</span>
            <input
              className="color-field--input"
              // value={props.value.replace("#", "")}
              id={props.id}
              onClick={focusInput}
              onChange={changeHandler}
              ref={innerRef}
              autoCapitalize="false"
              autoCorrect="false"
              autoComplete="false"
              spellCheck="false"
            />
          </div>
        </div>
      );
    },
  );
