// MUI
import Typography from "@mui/material/Typography";

// hooks
import {
  joinPath,
  hasConditionalVisible,
  fieldGroupOrdering,
} from "../utilities";
import { useFormConditionals } from "../useFormConditionals";

// components
import { Accordion } from "../../Accordion";
import { FormGroupComponent } from "../FormGroupComponent";
import { FormFieldOrElement } from "../Fields/index";

// types
import {
  IElement,
  IField,
  IFieldGroupBuilt,
} from "@iluvatar/global/src/typings";

export type FormState = { hasChanges: boolean };
export const Section: React.FC<
  {
    sectionKey: string;
    parentPath?: string;
    CatFishComponent?: React.ComponentType<
      (IField | IElement) & {
        className?: string | undefined;
        readonly?: boolean | undefined;
        disabled?: boolean | undefined;
      }
    >;
    slots?: {
      AfterHeader: React.ReactNode;
    };
  } & IFieldGroupBuilt
> = (props) => {
  const {
    name,
    headless,
    disabled,
    readonly,
    sectionKey,
    parentPath,
    CatFishComponent,
    slots,
    ...group
  } = props;
  const conditionalRender = useFormConditionals(name, group.conditions);

  const internalDisabled = conditionalRender.includes("disabled") || disabled;

  const internalReadonly = conditionalRender.includes("readonly") || readonly;

  const internalHidden = conditionalRender.includes("hidden") || group.hidden;

  const notVisible =
    hasConditionalVisible(group.conditions || []) &&
    !conditionalRender.includes("visible") &&
    !conditionalRender.includes("readonly");

  if (internalHidden || notVisible) {
    return <></>;
  }

  return (
    <Accordion
      headless={headless}
      title={group?.label}
      subTitle={parentPath}
      className="form-fields"
      slots={{
        startAdornment: slots?.AfterHeader,
      }}
      {...{ ["data-form-path"]: name }}
    >
      {group.description && !headless && (
        <Typography variant="body2" sx={{ mb: 2 }}>
          <>{group.description}</>
        </Typography>
      )}
      {fieldGroupOrdering<IFieldGroupBuilt, IField>(props).map((order) => {
        if (order.type === "field") {
          return CatFishComponent !== undefined ? (
            <CatFishComponent
              {...order.entry}
              key={joinPath(name, order.entry.name)}
              name={joinPath(name, order.entry.name)}
              disabled={order.entry.disabled || internalDisabled}
              readonly={order.entry.readonly || internalReadonly}
              className={"flex-" + (order.entry.span || 1) + "-cols form-field"}
            />
          ) : (
            <FormFieldOrElement
              {...order.entry}
              key={joinPath(name, order.entry.name)}
              name={joinPath(name, order.entry.name)}
              disabled={order.entry.disabled || internalDisabled}
              readonly={order.entry.readonly || internalReadonly}
              className={"flex-" + (order.entry.span || 1) + "-cols form-field"}
            />
          );
        } else if (order.entry.isSection) {
          return (
            <Section
              parentPath={`${
                parentPath ? parentPath + " /" : ""
              } ${group?.label}`}
              key={joinPath(name, order.entry.name)}
              sectionKey={joinPath(name, order.entry.name)}
              readonly={order.entry.readonly || internalReadonly}
              disabled={order.entry.disabled || internalDisabled}
              CatFishComponent={CatFishComponent}
              {...order.entry}
            />
          );
        } else {
          return (
            <FormGroupComponent
              sub
              {...order.entry}
              parentPath={`${
                parentPath ? parentPath + " /" : ""
              } ${group?.label}`}
              key={joinPath(name, order.entry.name)}
              name={joinPath(name, order.entry.name)}
              disabled={order.entry.disabled || internalDisabled}
              readonly={order.entry.readonly || internalReadonly}
              CatFishComponent={CatFishComponent}
            />
          );
        }
      })}
    </Accordion>
  );
};
