// libraries
import dayjs, { extend } from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useState, useEffect } from "react";
import { FileRoute, useParams } from "@tanstack/react-router";
import relativeTime from "dayjs/plugin/relativeTime";
// eslint-disable-next-line import/no-named-as-default-member
extend(relativeTime);

// mui
import { ArrowUpward, Mic } from "@mui/icons-material";
import {
  TextField,
  LinearProgress,
  IconButton,
  Typography,
} from "@mui/material";

// services
import { cn } from "@/utilities";
import { Time } from "@iluvatar/global/src/utilities/time";
import { sortObj } from "@iluvatar/global/src/utilities";
import requestService from "@/services/request.service";

// hooks
import { useChat } from "@/hooks/useChat";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { useNotificationDismissal } from "@/hooks/notificationDismissal";
import { RedisResponse, parseRedisUser } from "@/hooks/getMatchQuery";

// components
import DefaultLayout from "@/layout/default-page";
import { FlexSpacer } from "@/components/FlexSpacer";
import { ImageAvatar } from "@/components/ImageRef";
import { RenderWhenInView } from "@/components/RenderWhenInView";

// types
import {
  IFileReference,
  ISuccessResponse,
  Message,
  ProfileDetails,
} from "@iluvatar/global/src/typings";
import { MatchResult } from "@/components/MatchUserPreview";

function getProfileAv(
  prof?: ProfileDetails | undefined,
): IFileReference | undefined {
  if (prof?.imageIds[0]) {
    return (prof?.imagesById || {})[prof?.imageIds[0]];
  }
  return;
}

const UserChat: React.FC = () => {
  const params = useParams({ strict: false });
  useNotificationDismissal(`matches.${params.chatId}`);

  const { user } = useCurrentUser();
  const { chat, error, state, chatApi } = useChat(params.chatId);
  const partnerId =
    chat?.sourceUID === user?.uid ? chat?.targetUID : chat?.sourceUID;
  const { data: partnerProfile } = useQuery({
    queryKey: ["matched-user", partnerId, chat?.communityId],
    cacheTime: Time.days,
    queryFn: () => {
      return requestService
        .request<ISuccessResponse<RedisResponse>>("/api/search-matches", {
          method: "post",
          body: JSON.stringify({
            communityId: chat?.communityId,
            from: 0,
            size: 2,
            userIds: [partnerId],
          }),
        })
        .then(([_r, res]) => {
          return parseRedisUser(
            [],
            chat?.communityId || "default",
            res.data,
          )[0] as MatchResult;
        });
    },
  });

  const [message, setMessage] = useLocalStorage(
    `${user?.uid}-${params.chatId}`,
    "",
  );

  const [loading, setLoading] = useState(false);

  const sendMessage = useCallback(async () => {
    setLoading(true);
    try {
      await chatApi.addMessage(params.chatId, { message: message });
      setMessage("");
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [chatApi, message, params.chatId, setMessage]);

  const inputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setMessage(e.target.value);
    },
    [setMessage],
  );

  const inputMouseDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!(e.ctrlKey || e.metaKey) && e.key === "Enter") {
        sendMessage();
      }

      if (
        (e.ctrlKey || e.metaKey) &&
        e.key === "Enter" &&
        e.target &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e.target as any).value
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setMessage((e.target as any).value + "\n");
      }
    },
    [sendMessage, setMessage],
  );

  useEffect(() => {
    document.getElementById("anchor")?.scrollIntoView({ block: "end" });
  }, []);

  return (
    <DefaultLayout
      className="profile-container pb-20"
      style={{
        marginTop: 0,
        paddingTop: "var(--nav-height)",
        minHeight: "calc(100vh + 1px)",
      }}
    >
      {chat && <FlexSpacer />}
      {/* Header needs a link to user profile view */}
      {chat &&
        Object.values(chat.messages || {})
          .sort(sortObj("createdAt"))
          .map((k) => (
            <RenderWhenInView dynamic key={k.id}>
              <MessageContainer
                dense
                user={{ isMe: k.senderId == user?.uid }}
                userImg={
                  user &&
                  (k.senderId === user?.uid ? (
                    <ImageAvatar
                      imgRef={getProfileAv(user.profiles[chat.communityId])}
                    />
                  ) : (
                    <ImageAvatar
                      imgRef={getProfileAv(
                        partnerProfile?.profiles[chat.communityId],
                      )}
                    />
                  ))
                }
                message={k}
              />
            </RenderWhenInView>
          ))}
      {(!chat?.messages || !Object.values(chat.messages).length) && (
        <>
          <FlexSpacer />
          <div
            className={cn(
              "my-8 flex flex-1 justify-center center items-center",
              "w-[100%] min-h-[80px]",
            )}
          >
            <Typography variant="h3">No Messages Yet</Typography>
            {/* <Typography variant="caption">Be bold! Be shy! Be you!</Typography> */}
          </div>
          <FlexSpacer />
        </>
      )}
      <div className="profile-container profile-container--input fixed bottom-4 px-0">
        <div className="flex row">
          <TextField
            disabled={loading}
            className="flex-1 border-[var(--color-background--alt)]"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "var(--color-background--alt)",
                },
                "&:hover fieldset": {
                  borderColor: "var(--color-background--alt)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "var(--color-background--alt)",
                },
              },
            }}
            InputProps={{
              sx: {
                borderRadius: 1.5,
                px: 2,
                py: 1.5,
                borderColor: "var(--color-background--alt)",
                background: "var(--color-background--alt)",
              },
            }}
            variant="outlined"
            placeholder="Message"
            multiline
            maxRows={4}
            minRows={1}
            value={message}
            onKeyDown={inputMouseDown}
            onChange={inputChange}
          />
          <div>
            {message !== "" ? (
              <IconButton
                disabled={loading}
                aria-label="send-message"
                className="bg-[var(--color-background-primary)] round-[16px] ml-2 text-[var(--color-text)] mb-1"
                onClick={(e) => {
                  sendMessage();
                }}
              >
                <ArrowUpward />
              </IconButton>
            ) : (
              <IconButton
                disabled
                aria-label="mic"
                className="round-[16px] ml-2 text-[var(--color-text)] mb-1"
                onClick={(e) => {
                  // eslint-disable-next-line no-console
                  console.log(e);
                }}
              >
                <Mic />
              </IconButton>
            )}
          </div>
        </div>
      </div>
      <LinearProgress
        className="fixed bottom-0 left-0"
        variant="query"
        sx={{ mx: -2, opacity: !state ? 1 : 0, width: "calc(100vw)" }}
      />
    </DefaultLayout>
  );
};

export const Route = new FileRoute("/chat/$chatId").createRoute({
  component: UserChat,
});

export function MessageContainer({
  children,
  className,
  message,
  user,
  dense,
  userImg,
  ...rest
}: {
  user: {
    isMe?: boolean;
    image?: string;
    displayName?: string;
  };
  dense?: boolean;
  message: Message;
  userImg?: React.ReactNode;
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(className, "flex flex-row gap-2", {
        "flex-row-reverse": user.isMe,
      })}
      {...rest}
    >
      {userImg ? (
        userImg
      ) : user.image ? (
        <img
          className="w-8 h-8 rounded-full"
          src={user.image}
          alt={user.displayName}
        />
      ) : (
        <></>
      )}
      <div
        className={cn(
          "flex flex-col w-full max-w-[480px] leading-1.5 border-gray-200",
        )}
      >
        <div
          className={cn(
            "flex flex-col w-full max-w-[480px] leading-1.5 p-2 border-gray-200",
            user.isMe
              ? "bg-[var(--color-background-primary)] rounded-b-xl rounded-tl-xl"
              : "bg-[var(--color-background-paper)] rounded-e-xl rounded-es-xl",
          )}
        >
          {!dense && (
            <div
              className={cn("flex items-center space-x-2 rtl:space-x-reverse")}
            >
              {user.isMe && <FlexSpacer />}
              <Typography variant="subtitle2">{user.displayName}</Typography>
              {message.updatedAt && (
                <Typography variant="subtitle2">
                  {dayjs(message.updatedAt).format("hh:mm")}
                </Typography>
              )}
            </div>
          )}
          <Typography
            variant="body2"
            className={cn("text-sm font-normal whitespace-pre-line", {
              "pt-2.5": !dense,
            })}
          >
            {atob(message.message)}
          </Typography>
        </div>
        {dense && message.updatedAt && (
          <Typography
            variant="caption"
            className={cn(
              "flex items-center flex-1 opacity-50",
              user.isMe ? "justify-end" : "",
            )}
          >
            {dayjs(message.updatedAt).format("hh:mm")}
          </Typography>
        )}
      </div>
      {/* <span className="text-sm font-normal">Delivered</span> */}
      {/* <button
        id="dropdownMenuIconButton"
        data-dropdown-toggle="dropdownDots"
        data-dropdown-placement="bottom-start"
        className="inline-flex self-center items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-900 dark:hover:bg-gray-800 dark:focus:ring-gray-600"
        type="button"
      >
        <svg
          className="w-4 h-4 text-gray-500 dark:text-gray-400"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 4 15"
        >
          <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
        </svg>
      </button> */}
    </div>
  );
}
