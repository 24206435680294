// libraries
import { clsx } from "clsx";
import { forwardRef } from "react";

// layouts
import TopNav from "./PageTopNav";
import { PageInterface } from "./page";

const DefaultLayout = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<PageInterface> & React.HTMLAttributes<HTMLDivElement>
>(function DefaultLayout(
  { children, afterNavComponent, className, ...rest },
  ref,
) {
  return (
    <>
      <TopNav />
      <main
        ref={ref}
        className={clsx("main-container mt-[var(--nav-height)]", className)}
        {...rest}
      >
        {children}
      </main>
    </>
  );
});

export default DefaultLayout;
