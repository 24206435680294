// libraries
import { getDownloadURL, getMetadata } from "firebase/storage";

// services
import storageService, { storage } from "../../services/storage.service";

export const onDownload = (fileUrl: string, fileName: string) => {
  fetch(fileUrl)
    .then((res) => res.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.download = fileName;
      link.href = URL.createObjectURL(blob);
      link.target = "_blank";
      link.click();
    });
};

export const downloadFirebaseFileRef = (ref?: string) => {
  if (!ref) {
    return;
  }
  const _ref = storageService.ref(storage, ref);
  Promise.all([getDownloadURL(_ref), getMetadata(_ref)]).then(
    ([url, metaResponse]) => {
      onDownload(url, metaResponse.customMetadata?.name || metaResponse.name);
    },
  );
};
