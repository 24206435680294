import { ICondition, TFieldValue } from "./utils";

import { isObject } from "../../utilities/object";

// eslint-disable-next-line no-shadow
export enum IFieldTypes {
  "geolocationCheckField" = "geolocationCheckField",
  "rangeField" = "rangeField",
  "matrixField" = "matrixField",
  "dateField" = "dateField",
  "timeField" = "timeField",
  "textArea" = "textArea",
  "textField" = "textField",
  "phoneField" = "phoneField",
  "emailField" = "emailField",
  "signatureField" = "signatureField",
  "numberField" = "numberField",
  "toggle" = "toggle",
  "checkbox" = "checkbox",
  "password" = "password",
  "timezone" = "timezone",
  "autocomplete" = "autocomplete",
  "select" = "select",
  "radio" = "radio",
  "colorField" = "colorField",
  "userSelectField" = "userSelectField",
  "fileSelect" = "fileSelect",
  "imageSelect" = "imageSelect",
  "videoSelect" = "videoSelect",
  "inspectionQuestion" = "inspectionQuestion",
}

// eslint-disable-next-line no-shadow
export enum IElementTypes {
  "divider" = "divider",
  "header" = "header",
  "text" = "text",
}

/**
 * Type expected for Lookup (Select) filed options
 */
export interface IFieldOptions<T = Record<string, unknown>> {
  key?: string;
  label: string;
  value: string | number | boolean | null;
  meta?: T;
}

/**
 * Reference to a field definition.
 * Within the field/group configuration you can reuse field configs and give them new names/labels
 */

export interface IFieldReference {
  name: string;
  label: string;
  fieldId: string;

  // conditions that must be met in order to render this group
  conditions?: ICondition[];
}

export function isFieldReference(field?: unknown): field is IFieldReference {
  return (
    field !== undefined &&
    Object.getOwnPropertyNames(field).includes("fieldId") &&
    Object.getOwnPropertyNames(field).includes("label") &&
    Object.getOwnPropertyNames(field).includes("name")
  );
}

export const isField = (obj: unknown): obj is IField => {
  return Boolean(
    isObject(obj) &&
      obj.fieldType &&
      Object.values(IFieldTypes as Record<string, unknown>).includes(
        obj.fieldType,
      ),
  );
};

export const isElement = (obj: unknown): obj is IElement => {
  return Boolean(
    isObject(obj) &&
      obj.fieldType &&
      Object.values(IElementTypes as Record<string, unknown>).includes(
        obj.fieldType,
      ),
  );
};

/**
 * Form Field configuration. used to dynamically render a field within a form within the react app.
 * This structure gets saved in the database within a FieldSchema or a FieldGroupSchema
 */
export interface IFormElementBase {
  name: string;
  conditions?: ICondition[];
  hidden?: boolean;
  span?: 1 | 2 | 3 | 4;
  disabled?: boolean;
  readonly?: boolean;
}

export interface IFieldBase extends IFormElementBase {
  label: string;
  labelPlacement?: "inline" | "above";
  defaultValue?: TFieldValue | string[];
  hidden?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  // not strictly required for select
  required?: boolean;
  description?: string;

  autoFocus?: boolean;
  // max length or number of selected options
  min?: number;
  // min length or number of selected options
  max?: number;
}

export type IFieldAutocomplete = IFieldBase & {
  fieldType: IFieldTypes.autocomplete;
  options: IFieldOptions[];
  freeSolo?: boolean;
  freeSoloMaxLength?: number;
  multiple?: boolean;
};

export type IElementText = IFormElementBase & {
  fieldType: IElementTypes.text;
  text?: string;
  disabled?: boolean;
  readonly?: boolean;
};

// eslint-disable-next-line no-shadow
export enum HeaderOptions {
  "h1" = "h1",
  "h2" = "h2",
  "h3" = "h3",
}

export type IElementHeader = IFormElementBase & {
  fieldType: IElementTypes.header;
  text?: string;
  meta?: { headerType: HeaderOptions; [s: string]: unknown };
  disabled?: boolean;
  readonly?: boolean;
};

export type IFieldUserSelect = Omit<
  IFieldAutocomplete,
  "fieldType" | "options"
> & {
  fieldType: IFieldTypes.userSelectField;
};

export type IFieldSelect =
  | (IFieldBase & {
      fieldType: IFieldTypes.select;
      options: IFieldOptions[];
      multiple?: boolean;
      defaultValue?: TFieldValue | TFieldValue[];
    })
  | (IFieldBase & {
      fieldType: IFieldTypes.select;
      options: IFieldOptions[];
      multiple?: true;
      defaultValue?: TFieldValue[];
    });

export type IFieldRadio = IFieldBase & {
  fieldType: IFieldTypes.radio;
  defaultValue?: TFieldValue;
  options: IFieldOptions[];
};

export type IFieldDate = IFieldBase & {
  fieldType: IFieldTypes.dateField;
  outputNumber?: boolean;
  outputFormat?: string;
};

export type IFieldTime = IFieldBase & {
  fieldType: IFieldTypes.timeField;
  outputNumber?: boolean;
};

export type IFieldFileSelect = IFieldBase & {
  fieldType: IFieldTypes.fileSelect;
  accept?: string;
  multiple?: boolean;
};

export type IFieldVideoSelect = IFieldBase & {
  fieldType: IFieldTypes.videoSelect;
};

export type IFieldColorSelect = IFieldBase & {
  fieldType: IFieldTypes.colorField;
  inline?: boolean;
};

export type IFieldImageSelect = IFieldBase & {
  fieldType: IFieldTypes.imageSelect;
  multiple?: boolean;
};

export type InspectionQuestionField = IFieldBase & {
  fieldType: IFieldTypes.inspectionQuestion;
  options: IFieldOptions[];
};

export type IFieldRange = IFieldBase & {
  defaultValue: number | [number, number];
  fieldType: IFieldTypes.rangeField;
  supremum: number;
  infimum: number;
};

export type IField =
  | (IFieldBase & {
      fieldType:
        | IFieldTypes.textArea
        | IFieldTypes.textField
        | IFieldTypes.numberField
        | IFieldTypes.signatureField
        | IFieldTypes.phoneField
        | IFieldTypes.emailField
        | IFieldTypes.toggle
        | IFieldTypes.checkbox
        | IFieldTypes.geolocationCheckField
        | IFieldTypes.password
        | IFieldTypes.timezone;
    })
  | IFieldColorSelect
  | IFieldAutocomplete
  | IFieldFileSelect
  | IFieldImageSelect
  | IFieldVideoSelect
  | IFieldDate
  | IFieldTime
  | IFieldSelect
  | IFieldRadio
  | IFieldUserSelect
  | InspectionQuestionField
  | IFieldRange;

export type IElement =
  | (IFormElementBase & {
      fieldType: IElementTypes.divider;
    })
  | IElementText
  | IElementHeader;

/**
 * Field Configurations override the default behavior of a field.
 * It keys of of the fieldId
 */
export interface IFieldConfiguration {
  fieldId: string;
  conditions?: ICondition[];
  options?: IFieldOptions[];
  min?: number;
  max?: number;
  defaultValue?: TFieldValue;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
}
