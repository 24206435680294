// libraries
import { useDrop } from "react-dnd";

// utilities
import { cn } from "@/utilities";

// styles
import "./drop-zone.scss";

export function DropTarget({
  targetIdx,
  className,
  isEnd,
  onDrop,
}: {
  targetIdx: number;
  isEnd?: boolean;
  onDrop?: (sourceIdx: number) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "onDrop">) {
  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: "CARD",
    drop(item: { id: number }) {
      onDrop && onDrop(item.id);
    },
    collect: (monitor) => {
      const id = monitor.getItem<{ id: number }>()?.id;
      return {
        isOver:
          !(targetIdx === id || targetIdx - (!isEnd ? 1 : 0) == id) &&
          monitor.isOver(),
        canDrop:
          !(targetIdx === id || targetIdx - (!isEnd ? 1 : 0) == id) &&
          monitor.canDrop(),
      };
    },
  });

  return (
    <div
      id={`image-drop-zone-${targetIdx}`}
      className={cn(
        {
          canDrop,
          isOver,
          ["left-[-20px] w-[40px]"]: canDrop && !isEnd,
          ["right-[-20px] w-[40px]"]: canDrop && isEnd,
        },
        className,
        "absolute top-[-4px] bottom-[-4px] z-[100] ml-[-8px] drop-zone",
      )}
      ref={dropRef}
    />
  );
}
