// libraries
import { clsx } from "clsx";
import { useField } from "formik";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useMemo } from "react";

// MUI
import FormHelperText from "@mui/material/FormHelperText";
import FormControl, { FormControlProps } from "@mui/material/FormControl";

import {
  DatePicker as MuiDatePicker,
  DatePickerProps,
} from "@mui/x-date-pickers/DatePicker";

// context
import { useFormContext } from "../../../../contexts/form.context";

// components
import { fieldValidator } from "..";

// types
import { IFieldBase } from "@iluvatar/global/src/typings";

interface KeyboardDatePickerInterface extends IFieldBase {
  formControlProps?: FormControlProps;
  keyboardDatePicketProps?: Partial<DatePickerProps<Dayjs>>;
  className?: string;
  outputFormat?: string;
  outputNumber?: boolean;
}

const DatePicker: React.FC<KeyboardDatePickerInterface> = ({
  className,
  name,
  label,
  description,
  required,
  disabled,
  readonly,
  outputFormat = "YYYY-MM-DDTHH:mm:ssZ",
  outputNumber,
  labelPlacement = "inline",
  formControlProps = {},
  keyboardDatePicketProps = {},
}) => {
  const { onFieldChange } = useFormContext();
  const [fieldProps, meta, helpers] = useField({
    name,
    validate: fieldValidator(required),
  });

  const { value: fieldValue } = fieldProps;
  const { setValue } = helpers;

  const value = useMemo(() => {
    if (fieldValue && typeof fieldValue === "number") {
      return dayjs(new Date(fieldValue).toISOString(), outputFormat);
    } else if (fieldValue) {
      return dayjs(fieldValue, outputFormat);
    } else {
      return null;
    }
  }, [fieldValue, outputFormat]);

  const onChange = useCallback(
    (date: Dayjs | null) => {
      if (!readonly) {
        const nextValue = outputNumber
          ? date?.toDate().getTime() || null
          : date?.format(outputFormat) || null;
        setValue(nextValue);
        onFieldChange(name, nextValue);
      }
    },
    [readonly, outputNumber, outputFormat, setValue, onFieldChange, name],
  );

  const fixedLabel = required ? label + "*" : label;
  const labelAbove = labelPlacement === "above";
  const labelInline = labelPlacement === "inline";

  const hasError = Boolean(meta.touched && meta.error);
  const note = hasError ? meta.error : description;

  return (
    <FormControl
      {...formControlProps}
      className={clsx({ readonly }, className)}
      disabled={disabled || readonly}
      required={required}
      error={hasError}
    >
      {labelAbove && <div className="label-above">{fixedLabel}</div>}
      <MuiDatePicker
        closeOnSelect
        {...fieldProps}
        {...keyboardDatePicketProps}
        label={labelInline ? fixedLabel : undefined}
        value={value}
        onChange={onChange}
        readOnly={readonly}
        disabled={disabled || readonly}
        desktopModeMediaQuery="@media (fine)"
        slotProps={{
          textField: {
            size: formControlProps.size,
          },
          actionBar: {
            actions: ["today", "clear", "accept"],
          },
        }}
      />
      {Boolean(note) && (
        <FormHelperText error={hasError}>{note}</FormHelperText>
      )}
    </FormControl>
  );
};

export default DatePicker;
