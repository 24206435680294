// services
import storageService from "../storage.service";

// types
import { StorageReference, UploadTaskSnapshot } from "firebase/storage";
import { FileRecord, FormFile } from "./types";

// internal types
type _FileList = { path: string; file: FormFile };

async function _uploadFile(
  baseRef: StorageReference,
  file: FormFile,
  cb: (process: number, snapshot: UploadTaskSnapshot) => void,
): Promise<UploadTaskSnapshot> {
  const fileName = `${file.fileUUID}_${file.file.name}`;
  const ref = storageService.ref(baseRef, `/${fileName}`);

  const snapshot = await storageService.put(ref, file.file, (ev) => {
    cb((ev.bytesTransferred / ev.totalBytes) * 100, ev);
  });
  return snapshot;
}

export async function uploadFiles(
  files: FileRecord,
  ref: StorageReference,
  cb: (
    process: number,
    ev: UploadTaskSnapshot,
    path: string,
    file: FormFile,
  ) => void,
  complete?: (ev: UploadTaskSnapshot, path: string, file: FormFile) => void,
): Promise<void> {
  try {
    const allFiles = Object.entries(files).reduce<_FileList[]>(
      (fs, [path, fileArray]) => [
        ...fs,
        ...(fileArray || []).map((file, key) => ({
          path: `${path}.${key}`,
          file,
        })),
      ],
      [],
    );

    while (allFiles.length) {
      const currentFile = allFiles.pop();
      if (currentFile && !currentFile.file.ref) {
        await _uploadFile(
          ref,
          currentFile.file,
          function processHandler(process, snapshot) {
            cb(process, snapshot, currentFile.path, currentFile.file);
          },
        ).then(function successHandler(snapshot) {
          complete && complete(snapshot, currentFile.path, currentFile.file);
          return;
        });
      }
    }

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
}
