// libraries
import { extend } from "dayjs";
import { FormikHelpers } from "formik";
import { useState, useCallback } from "react";
import { FileRoute, useParams } from "@tanstack/react-router";
import relativeTime from "dayjs/plugin/relativeTime";
// eslint-disable-next-line import/no-named-as-default-member
extend(relativeTime);

// mui
import { Button, Divider, Typography } from "@mui/material";

// services
import { cn } from "@/utilities";
import {
  defaultDesireOptions,
  genderOptions,
  getUserPath,
  pronounOptions,
  sexualityOptions,
} from "@iluvatar/global/src/constants";

// hooks
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useFirestoreSubListener } from "@/hooks/useSubscription";

// components
import DefaultLayout from "@/layout/default-page";
import { FlexSpacer } from "@/components/FlexSpacer";
import { Form, FormState } from "@/components/Form";
import { ProfileImageBanner } from "@/components/ProfileImageBanner";

// constants
import { formErrorHandler } from "@/components/Form/helpers";
import { flattenObject, unFlattenObject } from "@iluvatar/global/src/utilities";

// types
import {
  IFieldGroupBuilt,
  IFieldTypes,
  UserEntity,
} from "@iluvatar/global/src/typings";
import { DeepPartial } from "@iluvatar/global/src/typings/ts-utils";

const UserProfileSettings: React.FC = () => {
  const params = useParams({ strict: false });
  const [loading, setLoader] = useState(false);
  const [profileForm, setProfile] = useState<FormState>({
    hasChanges: false,
  });

  const { api } = useCurrentUser();
  const [user] = useFirestoreSubListener<UserEntity>(
    getUserPath(params.userId),
  );

  const onUserUpdate = useCallback(
    async function innerUserHandler(
      userData: DeepPartial<UserEntity>,
      helpers: FormikHelpers<DeepPartial<UserEntity>>,
    ) {
      try {
        await api.updateUser(params.userId, userData);
        helpers.resetForm();
      } catch (error) {
        formErrorHandler(error, helpers);
      }
    },
    [api, params],
  );

  const uploadImageOrder = useCallback(
    async (targetIdx: number, sourceIdx: number): Promise<undefined> => {
      if (!user) {
        return;
      }
      try {
        setLoader(true);
        const sourceId = user.profiles.default.imageIds[sourceIdx];
        const newOrder = [
          ...user.profiles.default.imageIds
            .slice(0, targetIdx)
            .filter((id) => id !== sourceId),
          sourceId,
          ...user.profiles.default.imageIds
            .slice(targetIdx)
            .filter((id) => id !== sourceId),
        ];

        await api.updateUser(params.userId, {
          profiles: { default: { imageIds: newOrder } },
        });
      } finally {
        setLoader(false);
      }
      return;
    },
    [api, params.userId, user],
  );

  return (
    <DefaultLayout className="profile-container flex start-start pb-12">
      {user && (
        <Typography variant="h2" className="flex mt-12 mb-8">
          Default Profile
          <FlexSpacer />
          {profileForm.hasChanges && (
            <Button type="submit" form="default-profile">
              Save
            </Button>
          )}
        </Typography>
      )}

      <ProfileImageBanner
        isAdmin
        disabled={loading}
        className={cn("mb-12", { ["opacity-50"]: loading })}
        onOrderChange={uploadImageOrder}
        images={
          user?.profiles.default.imageIds?.map((id) => ({
            ...(user?.profiles.default.imagesById![id] || {}),
            id: id,
          })) || []
        }
      />

      {user && (
        <Form<DeepPartial<UserEntity>>
          id="default-profile"
          enableReinitialize
          initialValues={pickOutFormData(user, profileFormDefinition)}
          formDefinition={profileFormDefinition}
          setFormMetadata={setProfile}
          onSubmit={onUserUpdate}
        />
      )}
    </DefaultLayout>
  );
};

const profileFormDefinition: IFieldGroupBuilt = {
  name: "defaultProfile",
  label: "default profile",
  fields: [
    {
      name: "profiles.default.displayName",
      label: "Display Name",
      // defaultValue: user.about.displayName,
      span: 2,
      fieldType: IFieldTypes.textField,
    },
    {
      name: "about.sexuality",
      label: "Sexuality",
      span: 1,
      fieldType: IFieldTypes.autocomplete,
      options: sexualityOptions,
      freeSolo: true,
    },
    {
      name: "about.gender",
      label: "Gender",
      span: 1,
      fieldType: IFieldTypes.autocomplete,
      options: genderOptions,
      freeSolo: true,
    },
    {
      name: "about.pronouns",
      label: "Pronouns",
      hidden: true,
      span: 1,
      fieldType: IFieldTypes.autocomplete,
      options: pronounOptions,
      freeSolo: true,
    },
    {
      name: "profiles.default.description",
      label: "Description",
      span: 4,
      fieldType: IFieldTypes.textArea,
    },
    {
      name: "profiles.default.interests",
      label: "Interests",
      span: 4,
      fieldType: IFieldTypes.autocomplete,
      options: [],
      max: 10,
      multiple: true,
      freeSolo: true,
      freeSoloMaxLength: 20,
    },
    {
      name: "profiles.default.desires",
      label: "Desires",
      span: 4,
      fieldType: IFieldTypes.autocomplete,
      options: defaultDesireOptions,
      max: 10,
      multiple: true,
      freeSolo: true,
      freeSoloMaxLength: 20,
    },
    {
      name: "profiles.default.characteristics",
      label: "Characteristics",
      description: `Things you would like others to know about you. (e.g., covid-aware, tall, partnered 
        and poly, mobility impaired). Note, you are posting information about yourself in a public space, 
        be careful not to overshare information a stranger doesn't deserve to know.`,
      span: 4,
      fieldType: IFieldTypes.autocomplete,
      options: [],
      max: 10,
      multiple: true,
      freeSolo: true,
      freeSoloMaxLength: 20,
    },
    {
      name: "profiles.default.active",
      description: "Turn off to pauses your account.",
      label: "active",
      span: 1,
      fieldType: IFieldTypes.toggle,
    },
    {
      name: "profiles.default.incognito",
      description:
        "Go incognito for this profiles. You will be able to see and like profiles, but not be seen by others.",
      label: "Incognito",
      disabled: true,
      span: 1,
      fieldType: IFieldTypes.toggle,
    },
  ],
};

function pickOutFormData(
  data: UserEntity,
  form: IFieldGroupBuilt,
): DeepPartial<UserEntity> {
  const flat = flattenObject(data, undefined, { preserveArray: true });
  return unFlattenObject(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.keys(flat).reduce<any>((acc, key) => {
      if (form.fields!.some((f) => f.name === key)) {
        acc[key] = flat[key];
      }
      return acc;
    }, {}),
  ) as DeepPartial<UserEntity>;
}

export const Route = new FileRoute("/user/$userId/edit").createRoute({
  component: UserProfileSettings,
});
