// libraries
import { useCallback, useEffect, useMemo } from "react";
import { useField } from "formik";

// MUI
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";

// context
import { useFormContext } from "../../../../contexts/form.context";

// components
import { fieldValidator } from "..";

// types
import { IFieldBase } from "@iluvatar/global/src/typings";

const timezones = [
  "Canada/Atlantic",
  "Canada/Central",
  "Canada/Eastern",
  "Canada/Mountain",
  "Canada/Newfoundland",
  "Canada/Pacific",
  "Canada/Saskatchewan",
  "Canada/Yukon",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Samoa",
].map((zone) => ({ label: zone, value: zone }));

export const TimezoneField: React.FC<IFieldBase> = ({
  name,
  label,
  description,
  defaultValue,
  disabled,
  readonly,
  required,
}) => {
  const { onFieldChange } = useFormContext();
  const [fieldProps, meta, helpers] = useField({
    name,
    validate: fieldValidator(required),
  });
  const { value } = fieldProps;
  const { touched, error } = meta;
  const { setValue } = helpers;

  useEffect(() => {
    if (!value && defaultValue) {
      setValue(defaultValue);
      onFieldChange(name, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Derived Properties
   */
  const hasError = Boolean(touched && error);
  const note = hasError ? error : description;
  const fixedValue = value ? value : null;

  const changeHandler = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_e: any, newValue: any) => {
      setValue(newValue?.value || null);
      onFieldChange(name, newValue?.value || null);
    },
    [setValue, onFieldChange, name],
  );

  const equalityCheck = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (option: any, val: any) => val && option?.value === val,
    [],
  );

  const InputElement = useMemo(
    () =>
      function InputElm(inputParams: AutocompleteRenderInputParams) {
        return (
          <>
            <TextField
              variant="outlined"
              {...inputParams}
              inputProps={{
                ...(inputParams.inputProps || {}),
                enterKeyHint: "next",
              }}
              error={hasError}
              required={required}
              disabled={disabled || readonly}
              label={label}
            />
            {note && <FormHelperText error={hasError}>{note}</FormHelperText>}
          </>
        );
      },
    [hasError, required, disabled, readonly, label, note],
  );

  return (
    <Autocomplete
      {...fieldProps}
      onChange={changeHandler}
      isOptionEqualToValue={equalityCheck}
      value={fixedValue}
      disabled={disabled}
      disableClearable={!fixedValue || disabled}
      options={timezones}
      renderInput={InputElement}
    />
  );
};
