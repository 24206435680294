// libraries
import { useCallback, useEffect } from "react";
import { useField } from "formik";
import { clsx } from "clsx";

// MUI
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";

// components
import { fieldValidator } from "..";

// context
import { useFormContext } from "../../../../contexts/form.context";

// types
import { IFieldBase } from "@iluvatar/global/src/typings";

interface CheckboxInterface extends IFieldBase {
  formControlProps?: Partial<FormControlProps>;
  formControlLabelProps?: Omit<Partial<FormControlLabelProps>, "control">;
  checkboxProps?: Partial<CheckboxProps>;
  className?: string;
}

export const CheckboxField: React.FC<CheckboxInterface> = ({
  className,
  name,
  label,
  description,
  required,
  readonly,
  disabled,
  defaultValue,
  formControlProps = {},
  formControlLabelProps = {},
  checkboxProps = {},
}) => {
  const { onFieldChange } = useFormContext();
  const [fieldProps, meta, helpers] = useField({
    name,
    validate: fieldValidator(required),
  });

  const { touched, error } = meta;
  const { value } = fieldProps;
  const { setValue } = helpers;

  useEffect(() => {
    if (value === undefined && defaultValue !== undefined) {
      setValue(defaultValue);
      onFieldChange(name, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Derived Properties
   */
  const changeHandler = useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>, newValue: boolean) => {
      setValue(newValue);
      onFieldChange(name, newValue);
    },
    [setValue, onFieldChange, name],
  );

  const fixedLabel = required ? label + "*" : label;
  const hasError = Boolean(touched && error);
  const note = hasError ? error : description;

  return (
    <FormControl
      {...formControlProps}
      error={hasError}
      className={clsx({ readonly }, className)}
      disabled={disabled || readonly}
      required={required}
    >
      <FormControlLabel
        labelPlacement="end"
        {...formControlLabelProps}
        label={fixedLabel}
        control={
          <Checkbox
            color="primary"
            {...checkboxProps}
            required={required}
            {...fieldProps}
            inputProps={{
              ...checkboxProps.inputProps,
              enterKeyHint: "next",
            }}
            onChange={changeHandler}
            value={value}
            checked={value || false}
          />
        }
      />
      {Boolean(note) && (
        <FormHelperText error={hasError}>{note}</FormHelperText>
      )}
    </FormControl>
  );
};
