import { focusManager, UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useCurrentUser } from "./useCurrentUser";
import { PageTimings } from "@iluvatar/global/src/typings";

export function useNotificationDismissal(type: keyof PageTimings) {
  const { user, api } = useCurrentUser();
  useQuery({
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: true,
    queryKey: ["notification-dismissal", user?.uid, type || ""],
    queryFn: () => {
      if (
        focusManager.isFocused() &&
        user?.uid &&
        ((user?.pageUpdateTimes && user?.pageUpdateTimes[type]) || 0) >
          ((user?.pageViewsTimes && user?.pageViewsTimes[type]) || 0)
      ) {
        setTimeout(() => {
          api.updateUser(user.uid, { pageViewsTimes: { [type]: Date.now() } });
        }, 3000);
      }
      return Date.now();
    },
  });
}
