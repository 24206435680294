// libraries
import { useEffect } from "react";
import { FileRoute, useNavigate } from "@tanstack/react-router";

// MUI
import Typography from "@mui/material/Typography";

// hooks
import { useAuthenticationContext } from "../contexts/authentication/authentication.context";

// styles
import "../scss/auth.scss";

function LogoutPage(): JSX.Element {
  const { logout } = useAuthenticationContext();
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    navigate({ to: "/login" });
  }, [logout, navigate]);

  return (
    <div className="auth-page-container">
      <Typography variant="h5">Logging out</Typography>
    </div>
  );
}

export const Route = new FileRoute("/logout").createRoute({
  component: LogoutPage,
});
