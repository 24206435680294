// eslint-disable-next-line import/no-unresolved
import { useRegisterSW } from "virtual:pwa-register/react";
import { Alert, Button, Snackbar, SnackbarCloseReason } from "@mui/material";

// hooks
import { useConnectivityStatus } from "../../contexts/connectivity.context";

// utilities
import { Time } from "@iluvatar/global/src/utilities/time";

// components
import { Banner } from "../Banner";

function ReloadPrompt() {
  const online = useConnectivityStatus();
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(scriptUrl, r) {
      // eslint-disable-next-line
      console.log("SW Registered: " + scriptUrl, r);
      r &&
        setInterval(() => {
          r.update();
        }, 15 * Time.mins);
    },
    onRegisterError(error) {
      // eslint-disable-next-line
      console.log("SW registration error", error);
    },
  });

  const close = (
    // eslint-disable-next-line
    ev: Event | React.SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason,
  ) => {
    if (reason === "clickaway" || reason === "escapeKeyDown") {
      return;
    }
    setOfflineReady(false);
    setNeedRefresh(false);
  };

  return (
    <>
      {!online && (
        <Banner dismissible placement="bottom" style={{ padding: 0 }}>
          <Alert severity="info" variant="standard">
            You have lost connectivity. Some features may not be available
          </Alert>
        </Banner>
      )}
      <Snackbar
        open={offlineReady || needRefresh}
        onClose={close}
        message={
          offlineReady
            ? "App ready to work offline"
            : "Update available, reload to update application"
        }
        action={
          <>
            {needRefresh && (
              <Button
                variant="text"
                size="small"
                onClick={() => updateServiceWorker(true)}
              >
                Reload
              </Button>
            )}
            {!needRefresh && (
              <Button
                variant="text"
                size="small"
                onClick={() => {
                  setOfflineReady(false);
                  setNeedRefresh(false);
                }}
              >
                Close
              </Button>
            )}
          </>
        }
      />
    </>
  );
}

export default ReloadPrompt;
