import { useContext, useEffect, useState } from "react";

// services
import requestService from "@/services/request.service";

// hooks
import { useAuthenticationContext } from "@/contexts/authentication/authentication.context";

// types
import { MATCH_COLLECTION } from "@iluvatar/global/src/constants";
import { SubscriptionContext } from "@/contexts/subscription/subscriptions.context";
import { SubscriptionStateListener } from "@/contexts/subscription/subscription.types";

import { MatchRecord, Message } from "@iluvatar/global/src/typings";
import { DocumentData, DocumentSnapshot } from "@google-cloud/firestore";

type HookListenerState<Error> = {
  updatedAt?: number;
  res?: DocumentSnapshot<DocumentData>;
  data: SubscriptionStateListener<MatchRecord, Error>;
};

const chatApi = {
  addMessage: function (chatId: string, message: Partial<Message>) {
    return requestService.request(`/api/chat/${chatId}`, {
      method: "put",
      body: JSON.stringify(message),
    });
  },
};

export function useChat(chatId: string) {
  const { uid } = useAuthenticationContext();
  const { addFirestoreSnapshot, state } = useContext(SubscriptionContext);

  const [listener, setListener] = useState<HookListenerState<unknown>>({
    updatedAt: undefined,
    data: [undefined, undefined, undefined, undefined],
  });

  const targetState = state[[MATCH_COLLECTION, chatId].join("/")];

  /**
   * If target state is or becomes undefined and/or the path changes
   * - set initial undefined listened state
   * - add firestore document subscription to that path
   */
  useEffect(() => {
    if (uid) {
      // reset listener if path changes and there is no targetState
      setListener({
        updatedAt: undefined,
        data: [undefined, undefined, undefined, undefined],
      });
      addFirestoreSnapshot([MATCH_COLLECTION, chatId].join("/"));
    }
  }, [addFirestoreSnapshot, chatId, uid]);

  /**
   * Propagate target state to listener
   */
  useEffect(() => {
    if (targetState && targetState?.updatedAt !== listener.updatedAt) {
      if (targetState.error) {
        // eslint-disable-next-line
          console.warn(targetState);
      }
      setListener({
        updatedAt: targetState?.updatedAt,
        data: [
          targetState?.value as MatchRecord,
          targetState?.state,
          targetState?.error,
          undefined,
        ],
      });
    }
  }, [targetState, listener]);

  return {
    chat: listener.data[0],
    state: listener.data[1],
    error: listener.data[2],
    chatApi,
  };
}
