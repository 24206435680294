// ui
export const COOKIE_MAX_AGE = 60 * 60 * 24;
export const COOKIES = {
  AUTH: "iluvatar_auth_token",
};
export const DRAWER_WIDTH = 240;
export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";

export const MEDIAQUERY = {
  mobile: "(max-width: 440px), (max-device-width: 440px)",
  mobile_p: "(min-width: 441px) and (min-device-width: 441px)",
  xs: "(max-width:599px)",
  sm: "(min-width:600px)",
  tablet: "(max-width: 780px)",
  md: "(min-width:900px)",
  md_plus: "(min-width: 970px)",
  lg: "(min-width:1200px)",
  xl: "(min-width:1536px)",
};

export const USER_ICON_FILE_NAME = "user-icon.img";
export const FORM_GLOBAL_ERROR_KEY = "__root";
