import { useState } from "react";

// utilities
import requestService from "@/services/request.service";
import { unFlattenObject } from "@iluvatar/global/src/utilities";

// constants && types
import { ISuccessResponse, ProfileDetails } from "@iluvatar/global/src/typings";
import { useInfiniteQuery } from "@tanstack/react-query";

export type RedisResponse = {
  total: number;
  documents: { id: string; value: Record<string, unknown> }[];
};
type MatchSearchResult = {
  uid?: string;
  about?: {
    gender?: string;
    pronouns?: string;
    sexuality?: string;
    birthDate?: number;
  };
  profiles: {
    [communityId: string]: Pick<
      ProfileDetails,
      "imagesById" | "imageIds" | "displayName"
    >;
  };
};
type Errors = unknown;
type LoadMore = undefined | (() => void);

function tryJsonParse(d: unknown) {
  try {
    if (typeof d === "string" && d.startsWith(`{"${""}`)) {
      return JSON.parse(d);
    }
    return d;
  } catch (err) {
    return d;
  }
}

export function parseRedisUser(
  x: MatchSearchResult[],
  communityId: string,
  data: RedisResponse,
): MatchSearchResult[] {
  return x.concat(
    data.documents
      .filter((newU) => !x.some((user) => user.uid === newU.value["$.uid"]))
      .map((_x) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const d = unFlattenObject<any>(_x.value);
        if (d["$"]?.about?.birthDate) {
          d["$"].about.birthDate = Number(d["$"].about.birthDate);
        }

        try {
          return {
            ...d["$"],
            profiles: {
              [communityId]: {
                ...tryJsonParse(d["$"].profiles[communityId] || {}),
                ...(_x.value[`$.profiles.${communityId}.imagesById`]
                  ? {
                      imagesById: tryJsonParse(
                        _x.value[`$.profiles.${communityId}.imagesById`],
                      ),
                    }
                  : {}),
                ...(_x.value[`$.profiles.${communityId}.imageIds`]
                  ? {
                      imageIds: tryJsonParse(
                        _x.value[`$.profiles.${communityId}.imageIds`],
                      ),
                    }
                  : {}),
              },
            },
          };
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error("Failed to parse profile", d);
          return d;
        }
      }),
  );
}

const queryLimit = 15;
export function useQueryLikes({
  communityId = "default",
}: {
  communityId?: string;
}): [MatchSearchResult[], number, Errors, LoadMore] {
  const [total, setTotal] = useState(0);
  const { data, isLoading, error, ...rest } = useInfiniteQuery({
    cacheTime: 0,
    queryKey: [communityId],
    queryFn({ pageParam = { from: 0, lastDate: 0 } }) {
      return requestService
        .request<ISuccessResponse<RedisResponse>>("/api/search-likes", {
          method: "post",
          body: JSON.stringify({
            communityId,
            from: pageParam.from,
            size: queryLimit,
          }),
        })
        .then(([_r, res]) => {
          setTotal(res.data.total);
          return parseRedisUser([], communityId, res.data);
        });
    },
    getNextPageParam(lastPage, allPages) {
      if (lastPage.length === queryLimit) {
        return { from: allPages.length * queryLimit, lastDate: Date.now() };
      }
      return;
    },
  });

  const loadMore = rest.hasNextPage ? () => rest.fetchNextPage() : undefined;

  return [
    data?.pages.reduce<MatchSearchResult[]>((all, p) => all.concat(p), []) ||
      [],
    total,
    error,
    loadMore,
  ];
}
