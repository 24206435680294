import { isObject } from "@iluvatar/global/src/utilities";

export type FormFile = {
  metadata: {
    contentType?: string;
    [key: string]: unknown;
  };
  file: File;
  fileUUID: string;
  ref?: string;
};

export function isFormFile(obj: unknown): obj is FormFile {
  return isObject(obj) && Object.getOwnPropertyNames(obj).includes("file");
}

export type FileRecord = Record<string, FormFile[] | undefined>;
