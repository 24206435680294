import { Typography } from "@mui/material";
import { Badge } from "../ui/badge";
import { cn } from "@/utilities";

export function TagList({ items, label }: { items: string[]; label: string }) {
  if (!items.length) {
    return <></>;
  }

  return (
    <>
      <Typography variant="caption" className="my-2">
        {label}
      </Typography>
      <div className={cn("flex flex-wrap mb-4 gap-2")}>
        {items.map((item, idx) => (
          <Badge
            variant="outline"
            className="text-[var(--color-text)] font-normal"
            key={item + idx}
          >
            {item}
          </Badge>
        ))}
      </div>
    </>
  );
}
