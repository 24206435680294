/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Template literal tag creator.
 * copied from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals
 * - modified to removed dict option
 */
export type StringTemplateType = (...values: any[]) => string;
export function parseTemplate(
  strings: TemplateStringsArray,
  ...keys: any[]
): StringTemplateType {
  return function (...values: any[]): string {
    const result = [strings[0]];
    keys.forEach((key, i) => {
      result.push(values[key], strings[i + 1]);
    });
    return result.join("");
  };
}

// TODO this needs unit testing
// This Function is to check if the storage path to convert the image is valid.
// We only only want to resize image that is uploaded to a specified directory in the storage.
export const checkValidPath = (testPath: string, templatePath: string) => {
  const testFragments = testPath.split("/");
  const templateFragments = templatePath.split("/");

  if (testFragments?.length !== templateFragments?.length) {
    return false;
  }

  // loop through and throw error when non valid match is found
  for (let i = 0; i < templateFragments.length; i++) {
    if (
      templateFragments[i]?.startsWith("{") &&
      templateFragments[i]?.endsWith("}")
    ) {
      // if variable, match anything thats not null
      if (testFragments[i] === null) {
        return false;
      }
      // if non variable match exactly
    } else if (testFragments[i] !== templateFragments[i]) {
      return false;
    }
  }
  return true;
};

export const parsePath = (
  testPath: string,
  template: string,
): { [param: string]: string } => {
  const testFragments = testPath.split("/");
  const templateFragments = template.split("/");

  const params: { [param: string]: string } = {};

  for (let i = 0; i < testFragments.length; i++) {
    if (
      templateFragments[i]?.startsWith("{") &&
      templateFragments[i]?.endsWith("}")
    ) {
      const param = templateFragments[i].slice(1, -1);
      params[param] = testFragments[i];
    }
  }
  return params;
};

export function isString(str: unknown): str is string {
  return typeof str === "string";
}
