import { RefObject, useEffect, useRef, useState } from "react";

type HideOffscreenArgs = {
  dynamic?: boolean;
};

export function useHideOffscreen({ dynamic }: HideOffscreenArgs): {
  height?: number;
  visible?: boolean;
  ref: RefObject<HTMLDivElement>;
} {
  const [height, setHeight] = useState<undefined | number>();
  const [visible, setVisible] = useState(false);
  const observer = useRef(
    new IntersectionObserver(
      ([ev]) => {
        // eslint-disable-next-line
        setVisible(ev.isIntersecting || ev.rootBounds === null);
      },
      {
        root: null,
        threshold: [0, 1.0],
      },
    ),
  );
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current && observer.current.observe(ref.current);

    const cleanup = () => {
      if (ref.current) {
        observer.current.unobserve && observer.current.unobserve(ref.current);
      }
    };

    return () => {
      cleanup && cleanup();
    };
  }, []);

  useEffect(() => {
    if (visible && dynamic && ref.current?.clientHeight) {
      setHeight(ref.current?.clientHeight);
    }
    // eslint-disable-next-line
  }, [dynamic && ref.current]);

  return { height, visible, ref };
}
