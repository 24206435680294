import dayjs, { extend } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
extend(relativeTime);

import { useQuery } from "@tanstack/react-query";

import { Button, Typography } from "@mui/material";
import { Person2 } from "@mui/icons-material";

import { cn } from "@/utilities";
import { imgRefSize } from "@/services/file";
import requestService from "@/services/request.service";

import { useStorageUrl } from "@/hooks/useStorageUrl";
import { RedisResponse, parseRedisUser } from "@/hooks/getMatchQuery";

import {
  IFileReference,
  ISuccessResponse,
  ProfileDetails,
} from "@iluvatar/global/src/typings";
import { ImageAvatar } from "../ImageRef";
import { FlexSpacer } from "../FlexSpacer";
import { AspectRatio } from "../ui/aspect-ratio";

export type MatchResult = {
  uid?: string;
  about?: {
    gender?: string;
    pronouns?: string;
    sexuality?: string;
    birthDate?: number;
  };
  profiles: {
    [communityId: string]: ProfileDetails;
  };
};

export function QueryMatchUser({
  uid,
  communityId,
  ...rest
}: {
  uid: string;
  communityId: string;
} & React.HTMLAttributes<HTMLDivElement>) {
  const { data: userProfile } = useQuery({
    queryKey: ["matched-user", uid],
    cacheTime: 50000,
    queryFn: () => {
      return requestService
        .request<ISuccessResponse<RedisResponse>>("/api/search-matches", {
          method: "post",
          body: JSON.stringify({
            communityId,
            from: 0,
            size: 2,
            userIds: [uid],
          }),
        })
        .then(([_r, res]) => {
          return parseRedisUser([], communityId, res.data)[0] as MatchResult;
        });
    },
  });
  if (!userProfile) {
    return <div> loading</div>;
  }

  return (
    <MatchUserPreview
      {...rest}
      uid={uid}
      gender={userProfile.about?.gender}
      subTitle={userProfile.about?.pronouns}
      sexuality={userProfile.about?.sexuality}
      birthDate={userProfile.about?.birthDate}
      displayName={userProfile.profiles[communityId].displayName || "Anonymous"}
      imagesById={userProfile.profiles[communityId].imagesById || {}}
      imageIds={userProfile.profiles[communityId].imageIds || []}
    />
  );
}

function MatchUserPreview({
  uid,
  displayName,
  imagesById,
  imageIds,
  className,
  birthDate,
  gender,
  sexuality,
  subTitle,
  ...rest
}: {
  uid: string;
  displayName: string;
  birthDate?: number;
  gender?: string;
  sexuality?: string;
  subTitle?: string;
  imagesById: Record<string, IFileReference>;
  imageIds: string[];
} & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...rest} className={cn(className, "mt-3 mb-3")}>
      <div className="flex row items-center gap-3">
        <ImageAvatar imgRef={imagesById[imageIds[0]]} />
        <div>
          <Typography variant="h4" className="mt-1 mb-1">
            {displayName}
          </Typography>
          {(birthDate || gender || sexuality) && (
            <Typography variant="subtitle2">
              {birthDate ? `${dayjs(new Date()).diff(birthDate, "years")}` : ""}
              {Boolean([gender, sexuality].filter(Boolean).length) && ", "}
              {[gender, sexuality].filter(Boolean).join(" ")}
            </Typography>
          )}
          {subTitle && <Typography variant="subtitle2">{subTitle}</Typography>}
        </div>
        <FlexSpacer />

        <Button
          color="info"
          disabled
          sx={{ borderRadius: "12px" }}
          onClick={undefined}
          className="bg-[var(--color-primary--hover)] p-[9px] min-w-0"
        >
          <Person2 />
        </Button>
      </div>
    </div>
  );
}

function ImagePrev({
  imgRef,
  blur,
}: {
  imgRef?: IFileReference;
  blur?: boolean;
}) {
  const [fileUrl, loading, error] = useStorageUrl(imgRefSize(imgRef, 40));
  return (
    <div className="min-w-[120px] w-[120px] h-[120px] min-h-[120px]">
      <AspectRatio ratio={1} className="overflow-hidden rounded-[3px]">
        <img
          className={cn("image-thumb rounded-[0px]", { "blur-[8px]": blur })}
          src={fileUrl}
        />
      </AspectRatio>
    </div>
  );
}
