// libraries
import { useState, useCallback } from "react";

// MUI
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// eslint-disable-next-line valid-jsdoc
/**
 * The PasswordFields props are the MUI OutlinedInputProps with the addition of 'helperText'
 * All appropriate properties are passed into the FormControl and OutlinedInput
 *
 * the {...rest} are passed into the OutlinedInput
 */
const PasswordField: React.FC<
  OutlinedInputProps & { helperText?: React.ReactElement | string }
> = ({
  id,
  name,
  label,
  required,
  error,
  helperText,
  className,
  disabled,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword, setShowPassword]);

  return (
    <FormControl
      variant="outlined"
      disabled={disabled}
      className={className}
      required={required}
      error={error}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <OutlinedInput
        {...rest}
        // this label is to fix label overlay issue with Control and Outlined Inputs
        disabled={disabled}
        id={id || name}
        name={name}
        label={required ? `${label} *` : label}
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {helperText && (
        <FormHelperText
          id={`${name}-help-text`}
          data-error={error}
          error={error}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default PasswordField;
