// libraries
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFormikContext, FormikTouched, FormikValues } from "formik";

// MUI
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// utilities
import {
  flattenObject,
  isObject,
  traverseObjectPath,
} from "@iluvatar/global/src/utilities/object";
import { fileUUID } from "@iluvatar/global/src/utilities";

// type
import { IFieldGroupBuilt, IFieldOptions } from "@iluvatar/global/src/typings";
import { FileRecord, FormFile } from "../services/file";

/**
 * copied from Formik type file
 */
export type TSetFieldValue = (
  field: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldValue: any,
  shouldValidate?: boolean | undefined,
) => void;

export type TFormOnChangeFunction<Values> = (
  value: unknown,
  meta: {
    touched: FormikTouched<Values>;
    setFieldValue: TSetFieldValue;
  },
) => void;

export interface IOptionsRecord {
  setAt: number;
  options: IFieldOptions[];
}

export type ProviderValues = { files: FileRecord };

type FormProviderProps<Values> = {
  formId: string | undefined;
  children: JSX.Element;
  initialValues: Values;
  formDefinition?:
    | IFieldGroupBuilt
    | { sections: Record<string, IFieldGroupBuilt>; ordering: string[] };
  externalOptionsMap?: Record<string, IOptionsRecord>;
  setProviderValues?: (values: Partial<ProviderValues>) => void;
  onChanges?: Record<string, TFormOnChangeFunction<Values>>;
};

type SubEntryInstancesMap = Record<string, { key: string; config: unknown }[]>;

interface FormContextProps {
  setGroupInstance: (groupName: string, values: number[]) => void;
  createGroupInstance: (groupName: string, number?: number) => () => void;
  removeGroupInstance: (groupName: string, values: string) => () => void;
  addEntryInstance: (entryName: string, config: unknown) => void;
  removeEntryInstance: (entryName: string, key: string) => () => void;
  onFieldChange: (path: string, value: unknown) => void;
  setOptions: (fieldName: string, options: IFieldOptions[]) => void;
  setFiles: (path: string, value: FormFile[] | undefined) => void;
  entryInstances: SubEntryInstancesMap;
  groupInstances: { [path: string]: number[] };
  optionsMap: Record<string, IOptionsRecord>;
}

export const FormContext = createContext<FormContextProps>({
  setGroupInstance: () => null,
  createGroupInstance: function createGroupInstance() {
    return function curryInternal() {
      return null;
    };
  },
  removeGroupInstance: function removeGroupInstance() {
    return function curryInternal() {
      return null;
    };
  },
  addEntryInstance: () => null,
  removeEntryInstance: () => () => null,
  onFieldChange: () => null,
  setOptions: () => null,
  setFiles: () => null,
  entryInstances: {},
  groupInstances: {},
  optionsMap: {},
});

export const genFormFileObj = (file: File): FormFile => {
  return {
    file,
    fileUUID: fileUUID(),
    metadata: {},
  };
};

export function FormProvider<Values extends FormikValues = FormikValues>(
  props: FormProviderProps<Values>,
): JSX.Element {
  const {
    children,
    onChanges,
    formDefinition,
    externalOptionsMap,
    setProviderValues,
  } = props;
  const entryInstanceIdxMap = useRef<Record<string, number>>({});
  const prevIsSubmitting = useRef<boolean>(false);
  const {
    setFieldValue,
    values,
    touched,
    initialValues,
    submitCount,
    isSubmitting,
  } = useFormikContext();
  const [files, setFilesState] = useState<FileRecord>({});

  /**
   *  EntryInstance Tracking
   */
  const [entryInstances, setEntryInstances] = useState<SubEntryInstancesMap>(
    {},
  );

  const addEntryInstance = useCallback(
    (entryName: string, config: unknown) => {
      if (entryInstanceIdxMap.current[entryName] === undefined) {
        entryInstanceIdxMap.current[entryName] = 0;
      }

      setEntryInstances((instances) => ({
        ...instances,
        [entryName]: [
          ...(instances[entryName] || []),
          { key: entryInstanceIdxMap.current[entryName].toString(), config },
        ],
      }));
      entryInstanceIdxMap.current[entryName]++;
    },
    [setEntryInstances],
  );

  const removeEntryInstance = useCallback(
    function removeEntryInstanceHandler(
      entryName: string,
      instanceKey: string,
    ) {
      return function removeEntryInstanceInnerHandler() {
        setEntryInstances((instances) => {
          instances[entryName] = instances[entryName].filter(
            (inst) => inst.key !== instanceKey,
          );
          return instances;
        });
      };
    },
    [setEntryInstances],
  );

  /**
   * This utility functions is to enable the alteration of one field based on the value changes of another.
   * e.g., a change in fieldA => a transformed change in fieldB (as with the name to Key)
   *
   * I see two ways to do this:
   * A) diff the formik values here and find the changes, then check if that field has a change handler registered and call the handler
   * B) add another formContext onChange handler to each input to find the field changes without the diffing of all form values.
   *
   * The second has been chosen for performance reasons, below is the form context  onChange handler
   */
  const onFieldChange = useCallback(
    (path: string, value: unknown) => {
      // TODO: allow for path matching. i.e., register metadata.*.label
      if (onChanges && onChanges[path]) {
        onChanges[path](value, { touched, setFieldValue });
      }
    },
    [onChanges, touched, setFieldValue],
  );

  /**
   * keep track of the number of group instances within the form
   * e.g.,
   *
   * {
   *  groupA: [1,2,3]
   * }
   *
   * has groups with keys(numbers) 1,2,3. if the second group is removed the form would be updated to
   *
   * {
   *  groupA: [1,3]
   * }
   */
  const [groupInstances, setGroupInstances] = useState(
    {} as Record<string, number[]>,
  );
  const createGroupInstance = useCallback(
    (groupName: string, number = 1) =>
      () => {
        // make sure not to add a duplicate instance
        setGroupInstances((instances) => {
          const groupKeys = instances[groupName] || [];
          return {
            ...instances,
            [groupName]: [
              ...groupKeys,
              ...Array(number)
                .fill(Math.max(...groupKeys, -1) + 1)
                .map((val, idx) => val + idx),
            ],
          };
        });
      },
    [setGroupInstances],
  );

  const setGroupInstance = useCallback((groupName: string, keys: number[]) => {
    setGroupInstances((instances) => ({
      ...instances,
      [groupName]: keys,
    }));
  }, []);

  const removeGroupInstance = useCallback(
    function removeGroupInstanceHandler(
      groupName: string,
      instanceKey: string,
    ) {
      return function removeGroupInstanceInnerHandler() {
        setGroupInstances((instances) => {
          const start = `${groupName}.${instanceKey}`;
          const newInstances: Record<string, number[]> = {};

          // remove all values from the fields and groups within the removed target
          const groups = traverseObjectPath(`${groupName}`, values);

          if (Array.isArray(groups)) {
            setFieldValue(
              groupName,
              JSON.parse(
                JSON.stringify(
                  groups.filter((_g, key) => `${key}` !== instanceKey),
                ),
              ),
              false,
            );

            // arrays have a moving key (key 0 is always first one, so just pop the last entry out when removing key)
            return {
              ...instances,
              [groupName]: instances[groupName].slice(0, -1),
            };
          }

          // Filter out changed instances
          Object.keys(instances).forEach((key) => {
            if (!key.startsWith(start)) {
              newInstances[key] = instances[key];
            }
          });

          // Add group array back without the instance that was removed
          newInstances[groupName] = instances[groupName].filter(
            (key) => key.toString() !== instanceKey,
          );

          return newInstances;
        });
      };
    },
    [setFieldValue, values],
  );

  /**
   * Set FieldGroup instance count when initial values or provided / updated
   */
  useEffect(() => {
    const flatObject = flattenObject(initialValues);
    const instances: Record<string, number[]> = {};

    const flatFormDefinition = flattenFormDefinition(formDefinition);
    Object.entries(flatFormDefinition).forEach(([path, group]) => {
      let _diff = 0;
      if (group.min && !instances[path]) {
        _diff = group.min;
      } else if (group.min && instances[path]) {
        _diff = group.min - instances[path].length;
      }

      if (_diff > 0) {
        instances[path] = Array(group.min)
          .fill(0)
          .map((_val, idx) => idx);
      }
    });

    Object.keys(flatObject).forEach((path: string) => {
      // traverse the form path
      path.split(".").reduce((currentPath: string, key: string) => {
        // if path key is a number and its parent is a fieldGroup
        // and that fieldGroup does not have a field thats name key
        if (Number.isInteger(Number(key))) {
          const idx = Number(key);
          instances[currentPath]
            ? (instances[currentPath][idx] = idx)
            : (instances[currentPath] = [idx]);
        }
        return `${currentPath}${currentPath === "" ? "" : "."}${key}`;
      }, "");
    });

    setGroupInstances((currentGroupInstances) =>
      Object.assign({}, currentGroupInstances, instances),
    );
  }, [initialValues, formDefinition]);

  /**
   * Store select and autocomplete options
   * this is to handle async setting of select options
   *
   * e.g. {
   *  fieldA: [{value: "a", label:"a"}]
   * }
   */
  const [optionsMap, setOptionsMap] = useState<Record<string, IOptionsRecord>>({
    ...(externalOptionsMap ? externalOptionsMap : {}),
  });

  const setOptions = useCallback(
    (fieldName: string, options: IFieldOptions[]) => {
      setOptionsMap((currentOptionsMap) => {
        return {
          ...currentOptionsMap,
          [fieldName]: { setAt: new Date().getTime(), options },
        };
      });
    },
    [],
  );

  useEffect(() => {
    // form was successfully submitted if the is submitting goes from true => false and submitCount = 0 after.
    // this requires that the form be reset after submission is completed
    if (prevIsSubmitting.current !== isSubmitting) {
      prevIsSubmitting.current = isSubmitting;
      if (submitCount === 0 && !isSubmitting) {
        setEntryInstances({});
      }
    }
    // eslint-disable-next-line
    }, [isSubmitting]);

  useEffect(() => {
    setOptionsMap((currentOptionsMap) => {
      return {
        ...currentOptionsMap,
        ...externalOptionsMap,
      };
    });
  }, [externalOptionsMap]);

  /**
   * Store files selected within the form for file upload
   */
  const setFiles = useCallback(
    function setFiles(path: string, file: FormFile[] | undefined) {
      const newFiles = {
        ...files,
        [path]: file,
      };
      setFilesState(newFiles);
      setProviderValues && setProviderValues({ files: newFiles });
    },
    [files, setProviderValues],
  );

  /**
   * memoize context values to reduce likelyhood of unnecessary renders
   */
  const contextValues = useMemo(
    () => ({
      groupInstances,
      optionsMap,
      setGroupInstance,
      createGroupInstance,
      removeGroupInstance,
      onFieldChange,
      setOptions,
      setFiles,
      addEntryInstance,
      removeEntryInstance,
      entryInstances,
    }),
    [
      groupInstances,
      optionsMap,
      createGroupInstance,
      setGroupInstance,
      removeGroupInstance,
      addEntryInstance,
      removeEntryInstance,
      entryInstances,
      onFieldChange,
      setOptions,
      setFiles,
    ],
  );
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormContext.Provider value={contextValues}>
        {children}
      </FormContext.Provider>
    </LocalizationProvider>
  );
}

export const useFormContext = (): FormContextProps => useContext(FormContext);

export function objectPathMatch(test: string) {
  return function innerHandler(key: string) {
    const regex = key.replace("*", "([0-9]*)");
    // console.log(regex, new RegExp(regex, "g").test(test));
    return new RegExp(regex, "g").test(test);
  };
}

interface IUseFormField {
  onFieldChange: (value: unknown) => void;
  options?: IFieldOptions[];
}

export function useFormFieldContext(_name: string): IUseFormField {
  const { optionsMap, onFieldChange: onFieldChangeContext } = useContext(FormContext); // prettier-ignore

  const name = useMemo<string>(() => {
    if (optionsMap[_name]) {
      return _name;
    }
    const options = Object.keys(optionsMap).filter(objectPathMatch(_name));

    // NOTE: if 'fizzy' find has multiple hits, what should happen?
    return options[0] || _name;
  }, [_name, optionsMap]);

  /**
   * Derived Functions
   */
  const [optionsRecord, setOptionsRecord] = useState<IOptionsRecord | undefined>(optionsMap[name]); // prettier-ignore

  useEffect(() => {
    if (optionsMap[name] && optionsRecord?.setAt !== optionsMap[name].setAt) {
      setOptionsRecord(optionsMap[name]);
    }
  }, [name, optionsMap, optionsRecord, setOptionsRecord]);

  const onFieldChange = useCallback(
    (value: unknown) => {
      onFieldChangeContext(_name, value);
    },
    [onFieldChangeContext, _name],
  );

  /**
   * hook API
   */
  const api = useMemo(
    () => ({
      options: optionsRecord?.options || [],
      onFieldChange,
    }),
    [optionsRecord, onFieldChange],
  );
  return api;
}

function isSection(
  obj: unknown,
): obj is { sections: Record<string, IFieldGroupBuilt>; ordering: string[] } {
  return Boolean(isObject(obj) && obj.sections);
}

export function flattenFormDefinition(
  formDefinition?:
    | IFieldGroupBuilt
    | { sections: Record<string, IFieldGroupBuilt>; ordering: string[] },
): Record<string, IFieldGroupBuilt> {
  if (!formDefinition) {
    return {};
  }

  const record: Record<string, IFieldGroupBuilt> = {};

  const loopObj: [string | undefined, IFieldGroupBuilt][] = [];
  // parse formDefinition and add formDefinition
  if (isSection(formDefinition)) {
    Object.entries(formDefinition.sections).forEach(([, group]) => {
      if (group && group.groups) {
        group.groups.forEach((_g) => {
          loopObj.push([`${group.name}`, _g]);
        });
      }
    });
  } else {
    loopObj.push([undefined, JSON.parse(JSON.stringify(formDefinition))]);
  }

  while (loopObj.length) {
    const target = loopObj.pop();
    if (target) {
      const [path, group] = target;
      const _path = path ? `${path}.${group.name}` : group.name;
      record[_path] = { ...group };

      if (group.groups) {
        group.groups.forEach((_g) => {
          // push in new instances
          if (group.min && group.min > 0) {
            // create all minimum instances
            for (let i = 0; i < group.min; i++) {
              loopObj.push([`${_path}.${i}`, JSON.parse(JSON.stringify(_g))]);
            }
          }
        });
      }
    }
  }

  return record;
}
