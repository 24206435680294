import { DndProvider } from "react-dnd";
import { useState, useCallback } from "react";
import { TouchBackend } from "react-dnd-touch-backend";

import Add from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";

// services
import { cn } from "@/utilities";
import storageService from "../../services/storage.service";

// hooks
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useToastContext } from "@/contexts/toast.context";
import { useAuthenticationContext } from "../../contexts/authentication/authentication.context";

// components
import Card from "../Card";
import { ImageRef } from "../ImageRef";
import { DropTarget } from "./DropTarget";
import { DragWrapper } from "./DrapWrapper";
import { ImageSelectWrapper } from "../Form/Fields/File/ImageSelectWrapper";

// styles
import "./image-list.scss";

// types
import { IFileReference } from "@iluvatar/global/src/typings";
import { UploadTaskSnapshot } from "firebase/storage";

export function ProfileImageBanner({
  images,
  isAdmin,
  disabled,
  className,
  onOrderChange,
}: {
  disabled?: boolean;
  className?: string;
  isAdmin: boolean;
  images: IFileReference[];
  onOrderChange: (targetIdx: number, sourceIdx: number) => void;
}) {
  const { api } = useCurrentUser();
  const { uid } = useAuthenticationContext();
  const { addToast } = useToastContext();
  const [internalDisabled, setDisabled] = useState(false);
  const [loaderProgress, setLoaderProgress] = useState<number>();

  const _disabled = internalDisabled || disabled;

  const uploadImage = useCallback(
    async (
      name: string,
      blob: Blob | null,
    ): Promise<UploadTaskSnapshot | undefined> => {
      if (!blob || !uid) {
        return;
      }

      const ref = storageService.ref(
        storageService.storage(),
        `/users/${uid}/upload/${name}`,
      );
      const arrayBuffer = await blob.arrayBuffer();

      const snapshot = await storageService.putArrayBuffer(
        ref,
        { name: name, type: "image/png" },
        arrayBuffer,
        (ev) => {
          setLoaderProgress((ev.bytesTransferred / ev.totalBytes) * 100);
        },
      );
      setLoaderProgress(100);
      return snapshot;
    },
    [uid],
  );

  return (
    <>
      <div className={cn(className, "my-8 images-list")}>
        <DndProvider
          backend={TouchBackend}
          options={{ enableMouseEvents: true }}
        >
          {images.map((imageRef, idx) => (
            <DragWrapper
              className="relative"
              targetIdx={idx}
              key={imageRef.id + idx}
            >
              <ImageRef
                imgRef={imageRef}
                onRemove={
                  _disabled
                    ? undefined
                    : () => {
                        api
                          .removeImage(uid!, "default", imageRef.id)
                          .catch((err) => {
                            // eslint-disable-next-line no-console
                            console.error(err);
                            addToast("Woopsie, Can you just try that again.");
                          });
                      }
                }
              >
                <DropTarget
                  targetIdx={idx}
                  onDrop={(sourceId) => {
                    onOrderChange(idx, sourceId);
                  }}
                />
              </ImageRef>
              {idx === images.length - 1 && (
                <DropTarget
                  targetIdx={idx}
                  isEnd
                  onDrop={(sourceId) => {
                    onOrderChange(idx + 1, sourceId);
                  }}
                />
              )}
            </DragWrapper>
          ))}
        </DndProvider>

        {isAdmin && images.length <= 8 && (
          <ImageSelectWrapper
            disabled={_disabled}
            className="item image-thumb add-new"
            onComplete={async (name, blob) => {
              if (!blob) {
                return;
              }
              try {
                setDisabled(true);
                await uploadImage(name, blob);
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log(err);
              } finally {
                setDisabled(false);
              }
            }}
          >
            <Typography className="flex f-c-c">
              <Add className="mr-1" />
              Add
            </Typography>
          </ImageSelectWrapper>
        )}
      </div>

      {isAdmin && (
        <Card className="mt-[-16px]">
          <Typography variant="body2">
            Choose up to 8 photos. The more photos you add, the more likely
            others engage withy our account.
          </Typography>
        </Card>
      )}
    </>
  );
}
