import { QueryClient } from "@tanstack/react-query";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { Outlet, rootRouteWithContext, redirect } from "@tanstack/react-router";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { auth } from "../services/firebase.service";

async function authLoader() {
  // eslint-disable-next-line no-console
  console.time("auth-loading");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,
  while (!(auth as any)["_isInitialized"]) {
    await new Promise((res) => setTimeout(res, 10));
  }
  // eslint-disable-next-line no-console
  console.timeEnd("auth-loading");
}

function RootComponent() {
  return (
    <>
      <Outlet />
      {/* <ReactQueryDevtools buttonPosition="top-right" /> */}
      {import.meta.env.MODE == "development" && (
        <TanStackRouterDevtools position="bottom-right" />
      )}
    </>
  );
}

export const Route = rootRouteWithContext<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  auth: any;
  queryClient: QueryClient;
}>()({
  component: RootComponent,
  beforeLoad: async function authBeforeLoad({ context, location, ...rest }) {
    // If the user is logged out, redirect them to the login page
    if (
      location.href.includes("/login") ||
      location.href.includes("/logout") ||
      location.href.includes("/register") ||
      location.href.includes("/password-reset") ||
      location.href.includes("/password-reset-form")
    ) {
      // eslint-disable-next-line no-console
      console.log(context, location, rest);
      return {};
    }

    await authLoader();
    if (!auth.currentUser) {
      throw redirect({
        to: "/login",
        search: {
          redirectTo: location.href,
        },
      });
    }

    return {};
  },
});
