// constants && types
import config from "../config";
import { IPermission, featureIsEnabled } from "@iluvatar/global/src/constants";

export function useFeatureFlags(): [number, boolean];

export function useFeatureFlags(
  ...flagFilter: IPermission[]
): [boolean, boolean];

// return [flags, loading boolean]
export function useFeatureFlags(
  ...flagFilter: IPermission[]
): [number, boolean] | [boolean, boolean] {
  // This override should only allow true => false conversion. If global hardcoded flag is false then that toggle is disabled
  // BitFags logic is, G & O (org true flags) and G & T (tenant true flags)

  // override global with org, and org with tenant (only override true with false). I guess thats filtering.
  // const flags = ;
  // if (flagFilter.length) {
  //   flagFilter.every((flag) => featureIsEnabled(flags, flag.bitFlag));

  //   return [
  //     flagFilter.every((flag) => featureIsEnabled(flags, flag.bitFlag))
  //   ];
  // }

  return [config.globalAvailableFeatureFlags, false];
}

export function useGlobalFeatureFlags(...flagFilter: IPermission[]): boolean {
  return flagFilter.every((flag) =>
    featureIsEnabled(config.globalAvailableFeatureFlags, flag.bitFlag),
  );
}
