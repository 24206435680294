import { cn } from "@/utilities";
import { IFileReference } from "@iluvatar/global/src/typings";
import { useStorageUrl } from "../../hooks/useStorageUrl";
import { imgRefSize } from "../../services/file";

import { AspectRatio } from "@/components/ui/aspect-ratio";

import { Tooltip, Typography } from "@mui/material";
import { DeleteOutline, ErrorOutline } from "@mui/icons-material";
import { ProcessingImage } from "./processing-image";

import "./image-thumb.scss";

function TrashIcon(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...props}
      className={cn(
        props.className,
        "flex flex-col place-items-center justify-center rounded-[15px]",
        "absolute left-0 right-0 top-0 bottom-0 bg-black/[0.8] z-[100] text-white/[0.7]",
        "cursor-pointer opacity-0 hover:opacity-100",
      )}
    >
      <DeleteOutline />
      <Typography variant="caption">Remove</Typography>
    </div>
  );
}

export function ImageAvatar({
  imgRef,
}: {
  imgRef?: IFileReference;
} & React.HTMLAttributes<HTMLImageElement>) {
  const [fileUrl, loading, error] = useStorageUrl(imgRefSize(imgRef, 40));
  return <img className="w-8 h-8 rounded-full" src={fileUrl} />;
}

export function ImageRef({
  imgRef,
  file,
  children,
  onRemove,
  ...rest
}: {
  file?: File;
  imgRef?: IFileReference;
  children?: React.ReactNode;
  onRemove?: () => void;
} & React.HTMLAttributes<HTMLImageElement>) {
  const [fileUrl, loading, error] = useStorageUrl(imgRefSize(imgRef, 300));
  const imageUrl = file ? URL.createObjectURL(file) : fileUrl;

  if (imgRef?.metadata.isProcessing) {
    return (
      <ProcessingImage>
        {onRemove && <TrashIcon onClick={onRemove} />}
      </ProcessingImage>
    );
  }

  if (error) {
    return (
      <Tooltip title={error.code}>
        <ErrorOutline
          sx={{
            fontSize: "26px",
            color: "var(--color-chart--overdue--dark)",
          }}
        />
      </Tooltip>
    );
  }

  return (
    <>
      <AspectRatio ratio={1}>
        {onRemove && <TrashIcon onClick={onRemove} />}
        {loading ? (
          <span className="image-thumb loading max-w-[300px]" />
        ) : (
          <img className="image-thumb max-w-[300px]" src={imageUrl} {...rest} />
        )}
      </AspectRatio>
      {children}
    </>
  );
}

export function ImageFull({
  imgRef,
  ...rest
}: {
  imgRef?: IFileReference;
} & React.HTMLAttributes<HTMLImageElement>) {
  const [imageUrl, loading, error] = useStorageUrl(imgRefSize(imgRef, 600));

  if (imgRef?.metadata.isProcessing) {
    return <ProcessingImage />;
  }

  if (error) {
    return (
      <Tooltip title={error.code}>
        <ErrorOutline
          sx={{
            fontSize: "26px",
            color: "var(--color-chart--overdue--dark)",
          }}
        />
      </Tooltip>
    );
  }

  return (
    <AspectRatio ratio={1}>
      {loading ? (
        <span className="image-full loading" />
      ) : (
        <img className="image-full" src={imageUrl} {...rest} />
      )}
    </AspectRatio>
  );
}
