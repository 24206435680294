// libraries
import * as z from "zod";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { AuthError, AuthErrorCodes } from "firebase/auth";
import { FileRoute, Link, useNavigate, useMatch } from "@tanstack/react-router";

// MUI
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

// components
import UnAuthLayout from "../layout/unauthed-page";
import PasswordField from "../components/Form/Fields/PasswordTextField";

// hooks
import { useAuthenticationContext } from "../contexts/authentication/authentication.context";
import { useFeatureFlags } from "../hooks/useFeatureFlags";

// styles
import "../scss/auth.scss";

// types & constants
import {
  featureIsEnabled,
  PUBLIC_REGISTRATION,
} from "@iluvatar/global/src/constants/feature-flags.maps";

type LoginFormValue = {
  email: string;
  password: string;
  // for displaying general form errors
  authForm?: never;
};

const LOGIN_FORM_VALIDATOR = Yup.object().shape({
  email: Yup.string().required().email(),
  password: Yup.string().required(),
});

function LoginPage(): JSX.Element {
  const match = useMatch({ strict: false });
  const navigate = useNavigate();
  const { login } = useAuthenticationContext();

  const [featureFlags] = useFeatureFlags();
  const publicRegistrationEnabled = featureIsEnabled(
    featureFlags,
    PUBLIC_REGISTRATION.bitFlag,
  );

  const loginFormSubmission = async (
    { email, password }: LoginFormValue,
    helpers: FormikHelpers<LoginFormValue>,
  ) => {
    try {
      const res = await login(email, password);
      if (res) {
        // is consultant is not set until after login. meaning the user will always be redirected to the default tenant
        // TODO: let user set default home, and after login, redirect to the route first requested (pre unauthorized redirect)
        const redirectTo = match.search.redirectTo;

        if (redirectTo) {
          navigate({ to: redirectTo });
          return;
        }

        navigate({ to: "/" });
      }

      // eslint-disable-next-line
      console.warn("panic no response from login", res);

      navigate({ to: "/" });
    } catch (error) {
      // Error codes can be found here
      // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signinwithemailandpassword
      const err = error as AuthError;
      if (err.code === AuthErrorCodes.USER_DELETED) {
        helpers.setFieldError("email", err.message);
      } else if (err.code === AuthErrorCodes.INVALID_PASSWORD) {
        helpers.setFieldError("password", err.message);
      } else {
        helpers.setFieldError("authForm", err.message);
      }
    }
  };

  const { handleChange, handleSubmit, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: LOGIN_FORM_VALIDATOR,
      onSubmit: loginFormSubmission,
    });

  return (
    <UnAuthLayout>
      <div className="auth-page-container">
        <Typography variant="h5" color="text.secondary" sx={{ mt: 3 }}>
          Sign In
        </Typography>
        <form noValidate className="auth-form" onSubmit={handleSubmit}>
          {errors.authForm && (
            <FormHelperText error style={{ marginBottom: "16px" }}>
              {errors.authForm}
            </FormHelperText>
          )}
          <TextField
            fullWidth
            required
            className="auth-form-element"
            label="Email"
            name="email"
            variant="outlined"
            error={touched.email && Boolean(errors.email || errors.authForm)}
            value={values.email}
            helperText={touched.email ? errors.email : undefined}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <PasswordField
            fullWidth
            required
            className="auth-form-element"
            label="Password"
            name="password"
            error={
              touched.password && Boolean(errors.password || errors.authForm)
            }
            value={values.password}
            helperText={touched.password ? errors.password : undefined}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Button
            fullWidth
            id="login-submit"
            className="auth-form-element auth-button"
            color="primary"
            variant="outlined"
            type="submit"
          >
            Sign In
          </Button>
        </form>
        <Link
          to="/password-reset"
          style={{ color: "var(--color-text--secondary)" }}
        >
          Forgot password?
        </Link>
        {publicRegistrationEnabled && (
          <Link
            to="/register"
            style={{ color: "var(--color-text--secondary)", marginTop: "8px" }}
          >
            Register
          </Link>
        )}
      </div>
    </UnAuthLayout>
  );
}

export const Route = new FileRoute("/login")
  .createRoute({
    validateSearch: z.object({
      redirectTo: z.string().optional(),
    }),
  })
  .update({
    component: LoginPage,
  });
