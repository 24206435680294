export const pronounOptions = [
  { value: "He/Him", label: "He/Him" },
  { value: "They/Them", label: "They/Them" },
];

export const defaultDesireOptions = [
  { value: "Casual", label: "Casual" },
  { value: "Connection", label: "Connection" },
  { value: "Dates", label: "Dates" },
  { value: "CNM", label: "CNM" },
  { value: "Friendships", label: "Friendships" },
  { value: "Fun", label: "Fun" },
  { value: "FWB", label: "FWB" },
  { value: "Intimacy", label: "Intimacy" },
  { value: "Monogomy", label: "Monogomy" },
  { value: "Open Relationship", label: "Open Relationship" },
  { value: "Poly", label: "Poly" },
  { value: "relationship", label: "relationship" },

  { value: "Couples", label: "Couples" },
  { value: "Group", label: "Group" },
  { value: "Singles", label: "Singles" },
  { value: "Threeway", label: "Threeway" },
  { value: "FF", label: "FF" },
  { value: "MM", label: "MM" },
  { value: "MF", label: "MF" },
  { value: "FFM", label: "FFM" },
  { value: "MMF", label: "MMF" },
  { value: "FFF", label: "FFF" },
  { value: "MMM", label: "MMM" },
  { value: "FFFF", label: "FFFF" },
  { value: "MMMM", label: "MMMM" },
  { value: "MFMF", label: "MFMF" },
  { value: "Aftercare", label: "Aftercare" },

  { value: "BDSM", label: "BDSM" },
  { value: "Bondage", label: "Bondage" },
  { value: "Communication", label: "Communication" },
  { value: "Cuddling", label: "Cuddling" },
  { value: "Being Dominant", label: "Being Dominant" },
  { value: "Exploration", label: "Exploration" },
  { value: "Flirting", label: "Flirting" },
  { value: "Foreplay", label: "Foreplay" },
  { value: "GGG", label: "GGG" },
  { value: "Kink", label: "Kink" },
  { value: "Kissing", label: "Kissing" },
  { value: "Massage", label: "Massage" },
  { value: "Parties", label: "Parties" },
  { value: "Sensual", label: "Sensual" },
  { value: "Being Submissive", label: "Being Submissive" },
  { value: "Texting", label: "Texting" },
  { value: "Watching", label: "Watching" },
];

export const sexualityOptions = [
  { value: "Androgynosexual", label: "Androgynosexual" },
  { value: "Androsexual", label: "Androsexual" },
  { value: "Asexual", label: "Asexual" },
  { value: "Autosexual", label: "Autosexual" },
  { value: "Bisexual", label: "Bisexual" },
  { value: "Bi-curious", label: "Bi-curious" },
  { value: "Demisexual", label: "Demisexual" },
  { value: "Gay", label: "Gay" },
  { value: "GrayA", label: "GrayA" },
  { value: "Gynosexual", label: "Gynosexual" },
  { value: "Heteroflexible", label: "Heteroflexible" },
  { value: "Homoflexible", label: "Homoflexible" },
  { value: "Lesbian", label: "Lesbian" },
  { value: "Objectumsexual", label: "Objectumsexual" },
  { value: "Omnisexual", label: "Omnisexual" },
  { value: "Pansexual", label: "Pansexual" },
  { value: "Polysexual", label: "Polysexual" },
  { value: "Queer", label: "Queer" },
  { value: "Skoliosexual", label: "Skoliosexual" },
  { value: "Straight", label: "Straight" },
];

export const genderOptions = [
  { value: "Agender", label: "Agender" },
  { value: "Androgynous", label: "Androgynous" },
  { value: "Bigender", label: "Bigender" },
  { value: "Gender Fluid", label: "Gender Fluid" },
  { value: "Gender Nonconforming", label: "Gender Nonconforming" },
  { value: "Genderqueer", label: "Genderqueer" },
  { value: "Gender Questioning", label: "Gender Questioning" },
  { value: "Intersex", label: "Intersex" },
  { value: "Non-Binary", label: "Non-Binary" },
  { value: "Other", label: "Other" },
  { value: "Pangender", label: "Pangender" },
  { value: "Transfemnine", label: "Transfemnine" },
  { value: "Transmasculine", label: "Transmasculine" },
  { value: "Trans Person", label: "Trans Person" },
  { value: "Trans Man", label: "Trans Man" },
  { value: "Trans non-binary", label: "Trans non-binary" },
  { value: "Trans Woman", label: "Trans Woman" },
  { value: "Two-Spirit", label: "Two-Spirit" },
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];
export const locationOptions = [
  { value: "default", label: "Earth" },
  // { value: "default-", label: "Virtual Earth" },
];
