// libraries
import { useCallback, useEffect, useMemo } from "react";
import { useField } from "formik";
import { clsx } from "clsx";

// MUI
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel, { InputLabelProps } from "@mui/material/InputLabel";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import Select, {
  SelectChangeEvent,
  SelectProps as MUISelectProps,
} from "@mui/material/Select";

// context
import { useFormFieldContext } from "../../../../contexts/form.context";

// components
import { fieldValidator } from "..";

// types
import {
  IFieldBase,
  IFieldOptions,
  TFieldValue,
} from "@iluvatar/global/src/typings";

interface SelectProps extends Omit<IFieldBase, "defaultValue"> {
  options: IFieldOptions[];
  defaultValue?: TFieldValue;
  formControlProps?: Partial<FormControlProps>;
  inputLabelProps?: Partial<InputLabelProps>;
  selectProps?: Partial<MUISelectProps>;
  className?: string;
}
interface MultiSelectProps extends Omit<SelectProps, "defaultValue"> {
  defaultValue: TFieldValue[];
}

export const SelectField: React.FC<SelectProps> = ({
  className,
  name,
  label,
  labelPlacement = "inline",
  description,
  defaultValue,
  disabled,
  readonly,
  required,
  options = [],
  formControlProps = {},
  inputLabelProps = {},
  selectProps = {},
}) => {
  const { onFieldChange, options: optionsOverride } = useFormFieldContext(name);
  const [fieldProps, meta, helpers] = useField({
    name,
    validate: fieldValidator(required),
  });
  const { value } = fieldProps;
  const { touched, error } = meta;

  useEffect(() => {
    if (!value && defaultValue) {
      helpers.setValue(defaultValue);
      onFieldChange(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Derived Properties
   */
  const renderOptions =
    optionsOverride && optionsOverride.length ? optionsOverride : options;

  const changeHandler = (e: SelectChangeEvent) => {
    const _value = renderOptions.find((v) => v.value == e.target.value)?.value;
    helpers.setValue(_value);
    onFieldChange(_value);
  };

  // || "" so so this is a controlled field
  const fixedValue = value || "";
  const fixedLabel = required ? label + "*" : label;
  const labelAbove = labelPlacement === "above";
  const labelInline = labelPlacement === "inline";

  const hasError = Boolean(touched && error);
  const note = hasError ? error : description;

  return (
    <FormControl
      variant="outlined"
      className={clsx({ readonly }, className)}
      {...formControlProps}
      error={hasError}
      disabled={disabled || readonly}
      required={required}
    >
      {labelAbove && <div className="label-above">{fixedLabel}</div>}
      {labelInline && <InputLabel {...inputLabelProps}>{label}</InputLabel>}
      <Select
        {...selectProps}
        {...fieldProps}
        inputProps={{ ...(selectProps.inputProps || {}), enterKeyHint: "next" }}
        onChange={changeHandler}
        label={labelInline ? label : undefined}
        value={fixedValue}
      >
        {renderOptions.map(
          ({ key, label: optionLabel, value: optionsValue }) => (
            <MenuItem key={`${key || optionsValue}`} value={`${optionsValue}`}>
              {optionLabel}
            </MenuItem>
          ),
        )}
      </Select>
      {note && <FormHelperText error={hasError}>{note}</FormHelperText>}
    </FormControl>
  );
};

export const MultiSelectField: React.FC<MultiSelectProps> = ({
  className,
  name,
  label,
  labelPlacement = "inline",
  description,
  disabled,
  required,
  readonly,
  defaultValue,
  options,
  formControlProps = {},
  inputLabelProps = {},
  selectProps = {},
}) => {
  const { onFieldChange, options: optionsOverride } = useFormFieldContext(name);
  const [fieldProps, meta, helpers] = useField({
    name,
    validate: fieldValidator(required),
  });
  const { value } = fieldProps;
  const { touched, error } = meta;
  const { setValue } = helpers;

  useEffect(() => {
    if (!value && defaultValue) {
      setValue(defaultValue);
      onFieldChange(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Derived Properties
   */

  const renderOptions = useMemo(
    () =>
      optionsOverride && optionsOverride.length
        ? optionsOverride
        : options || [],
    [optionsOverride, options],
  );

  const changeHandler = useCallback(
    (e: SelectChangeEvent<TFieldValue[]>) => {
      const _value = renderOptions
        .filter((v) =>
          Array.isArray(e.target.value)
            ? e.target.value.includes(v.value)
            : false,
        )
        .map((v) => v.value);
      setValue(_value);
      onFieldChange(_value);
    },
    [renderOptions, setValue, onFieldChange],
  );

  const renderValue = useCallback(
    (selected: TFieldValue[]) => {
      return renderOptions
        .filter((option) => selected.includes(option.value))
        .map((option) => option.label)
        .join(", ");
    },
    [renderOptions],
  );

  // || "" so so this is a controlled field
  const fixedValue = value || [];
  const hasError = Boolean(touched && error);
  const note = hasError ? error : description;

  const fixedLabel = required ? label + "*" : label;
  const labelAbove = labelPlacement === "above";
  const labelInline = labelPlacement === "inline";

  return (
    <FormControl
      variant="outlined"
      {...formControlProps}
      error={hasError}
      disabled={disabled || readonly}
      required={required}
      className={className}
    >
      {labelAbove && <div className="label-above">{fixedLabel}</div>}
      {labelInline && <InputLabel {...inputLabelProps}>{label}</InputLabel>}
      <Select
        {...selectProps}
        {...fieldProps}
        multiple
        disabled={readonly || disabled}
        defaultValue={defaultValue}
        onChange={changeHandler}
        label={label}
        value={fixedValue}
        renderValue={renderValue}
      >
        {renderOptions.map(
          ({ key, label: optionLabel, value: optionsValue }) => (
            <MenuItem key={`${key || optionsValue}`} value={`${optionsValue}`}>
              {optionLabel}
            </MenuItem>
          ),
        )}
      </Select>
      {note && <FormHelperText error={hasError}>{note}</FormHelperText>}
    </FormControl>
  );
};
