// libraries
import { extend } from "dayjs";
import { FormikHelpers } from "formik";
import { useState, useCallback } from "react";
import { FileRoute, useParams } from "@tanstack/react-router";
import relativeTime from "dayjs/plugin/relativeTime";
// eslint-disable-next-line import/no-named-as-default-member
extend(relativeTime);

// mui
import { Button, Typography } from "@mui/material";

// services
import { getUserPath } from "@iluvatar/global/src/constants";

// hooks
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useFirestoreSubListener } from "@/hooks/useSubscription";

// components
import DefaultLayout from "@/layout/default-page";
import { FlexSpacer } from "@/components/FlexSpacer";
import { Form, FormState } from "@/components/Form";

// constants
import { formErrorHandler } from "@/components/Form/helpers";
import { flattenObject, unFlattenObject } from "@iluvatar/global/src/utilities";

// types
import {
  IFieldGroupBuilt,
  IFieldTypes,
  UserEntity,
} from "@iluvatar/global/src/typings";
import { DeepPartial } from "@iluvatar/global/src/typings/ts-utils";

const UserAccountSettings: React.FC = () => {
  const params = useParams({ strict: false });
  const [accountForm, setAccount] = useState<FormState>({
    hasChanges: false,
  });

  const { api } = useCurrentUser();
  const [user] = useFirestoreSubListener<UserEntity>(
    getUserPath(params.userId),
  );

  const onUserUpdate = useCallback(
    async function innerUserHandler(
      userData: DeepPartial<UserEntity>,
      helpers: FormikHelpers<DeepPartial<UserEntity>>,
    ) {
      try {
        await api.updateUser(params.userId, userData);
        helpers.resetForm();
      } catch (error) {
        formErrorHandler(error, helpers);
      }
    },
    [api, params],
  );

  return (
    <DefaultLayout className="profile-container flex start-start pb-12">
      {user && (
        <Typography variant="h2" className="flex mt-12 mb-8">
          Account Settings
          <FlexSpacer />
          {accountForm.hasChanges && (
            <Button type="submit" form="account-settings">
              Save
            </Button>
          )}
        </Typography>
      )}

      {user && (
        <Form<DeepPartial<UserEntity>>
          id="account-settings"
          enableReinitialize
          initialValues={pickOutFormData(user, accountFormDefinition)}
          formDefinition={accountFormDefinition}
          setFormMetadata={setAccount}
          onSubmit={onUserUpdate}
        />
      )}
    </DefaultLayout>
  );
};

const accountFormDefinition: IFieldGroupBuilt = {
  name: "",
  label: "",
  fields: [
    {
      name: "active",
      label: "Active",
      description: "Turn off to pauses your account.",
      span: 1,
      fieldType: IFieldTypes.toggle,
    },
    {
      name: "incognito",
      label: "Incognito",
      description:
        "Go incognito across all you your profiles and communities. You will be able to see and like profiles, but not be seen by others.",
      span: 1,
      disabled: true,
      fieldType: IFieldTypes.toggle,
    },
    {
      name: "email",
      label: "Email",
      span: 3,
      fieldType: IFieldTypes.emailField,
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      span: 3,
      disabled: true,
      fieldType: IFieldTypes.phoneField,
    },
    {
      name: "about.displayName",
      label: "Default Display Name",
      span: 2,
      fieldType: IFieldTypes.textField,
    },
    {
      name: "about.birthDate",
      label: "Birthday",
      span: 1,
      fieldType: IFieldTypes.dateField,
      outputNumber: true,
    },
  ],
};

function pickOutFormData(
  data: UserEntity,
  form: IFieldGroupBuilt,
): DeepPartial<UserEntity> {
  const flat = flattenObject(data, undefined, { preserveArray: true });
  return unFlattenObject(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.keys(flat).reduce<any>((acc, key) => {
      if (form.fields!.some((f) => f.name === key)) {
        acc[key] = flat[key];
      }
      return acc;
    }, {}),
  ) as DeepPartial<UserEntity>;
}

export const Route = new FileRoute("/user/$userId/account-settings").createRoute({
  component: UserAccountSettings,
});
