// libraries
import {
  UploadTaskSnapshot,
  StorageReference,
  ref as stgRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

// services
import { storage } from "./firebase.service";

class FirebaseStorageUtil {
  /**
   * cache what files have been successfully uploaded (file names)
   */
  uploadCache: Record<string, UploadTaskSnapshot> = {};

  /**
   * firestore storage utilities
   */
  ref = stgRef;
  getDownloadURL = getDownloadURL;

  storage() {
    const path = "/";
    return stgRef(storage, path);
  }

  /**
   * generate storage paths from key parameters
   */
  put(
    ref: StorageReference,
    file: File,
    onNext: (ev: UploadTaskSnapshot) => void,
  ): Promise<UploadTaskSnapshot> {
    return file
      .arrayBuffer()
      .then((arrayBuffer) => {
        return this.putArrayBuffer(
          ref,
          { name: file.name, type: file.type },
          arrayBuffer,
          onNext,
        );
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        throw err;
      });
  }

  putArrayBuffer(
    ref: StorageReference,
    meta: {
      name: string;
      type: string;
    },
    arrayBuffer: ArrayBuffer,
    onNext: (ev: UploadTaskSnapshot) => void,
  ): Promise<UploadTaskSnapshot> {
    if (this.uploadCache[meta.name]) {
      return Promise.resolve(this.uploadCache[meta.name]);
    }

    return new Promise((res, rej) => {
      const task = uploadBytesResumable(ref, arrayBuffer, {
        contentType: meta.type,
        customMetadata: {
          name: meta.name,
        },
      });
      task.on("state_changed", (snapshot) => {
        this.uploadCache[meta.name] = snapshot;
        onNext(snapshot);
      });
      task.catch(rej);
      task.then((snapshot) => {
        this.uploadCache[meta.name] = snapshot;
        res(this.uploadCache[meta.name]);
      });
    });
  }

  clearCache() {
    this.uploadCache = {};
  }
}

export default new FirebaseStorageUtil();
export { storage } from "./firebase.service";
