import { useEffect } from "react";

// contexts
import { AuthenticationProvider } from "./authentication/authentication.context";
import { DataStoreProvider, emptyStoreState } from "./dataStore.context";
import { ConnectivityStatusProvider } from "./connectivity.context";
import { SubscriptionsProvider } from "./subscription/subscription.provider";
import { ToastProvider } from "./toast.context";
import { ThemeProvider } from "./theme.provider";

/*
 * HOC for cleaning up Context Provider imports
 */
const AllContexts: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isDark = localStorage.getItem("theme") === "dark";
  const theme = isDark ? "dark" : "light";

  useEffect(() => {
    if (!isDark) {
      document.querySelector("html")?.classList.remove("dark-theme");
    } else {
      document.querySelector("html")?.classList.add("dark-theme");
    }
  }, [isDark]);

  return (
    <ConnectivityStatusProvider>
      <DataStoreProvider
        initialData={JSON.parse(JSON.stringify(emptyStoreState))}
      >
        <ThemeProvider
          initialValues={{
            theme: theme,
          }}
        >
          <SubscriptionsProvider>
            <ToastProvider>
              <AuthenticationProvider>{children}</AuthenticationProvider>
            </ToastProvider>
          </SubscriptionsProvider>
        </ThemeProvider>
      </DataStoreProvider>
    </ConnectivityStatusProvider>
  );
};

export default AllContexts;
