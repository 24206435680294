import { useState, useCallback } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

// utilities
import requestService from "@/services/request.service";
import { unFlattenObject } from "@iluvatar/global/src/utilities";

// constants && types
import { ISuccessResponse, UserEntity } from "@iluvatar/global/src/typings";

type RedisResponse = {
  total: number;
  documents: { id: string; value: Record<string, unknown> }[];
};

function parse(
  x: UserEntity[],
  communityId: string,
  data: RedisResponse,
): UserEntity[] {
  return x.concat(
    data.documents
      .filter((newU) => !x.some((user) => user.uid === newU.value["$.uid"]))
      .map((_x) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const d = unFlattenObject<any>(_x.value);
        if (d["$"]?.about?.birthDate) {
          d["$"].about.birthDate = Number(d["$"].about.birthDate);
        }
        try {
          return {
            ...d["$"],
            profiles: {
              [communityId]: JSON.parse(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                _x.value[`$.profiles.${communityId}`] as any,
              ),
            },
          };
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error("Failed to parse profile", d);
          return d;
        }
      }),
  );
}

const queryLimit = 5;
export function useSearchQuery({
  communityId = "default",
  age,
  gender,
  sexuality,
  locationId,
  ignoreUserIds = [],
  geo,
}: {
  communityId?: string;
  age: [number, number];
  gender: string[];
  sexuality: string[];
  locationId?: string;
  geo?: { dist: number; units: string };
  ignoreUserIds?: string[];
}): [UserEntity[], unknown, (uids: string[]) => void, (uid: string) => void] {
  const [postFilter, setPostFilter] = useState<string[]>([]);
  const { data, isLoading, error, ...rest } = useInfiniteQuery({
    cacheTime: 0,
    queryKey: [communityId, age, gender, sexuality, geo, locationId],
    queryFn({ pageParam = { from: 0, lastDate: 0 } }) {
      return requestService
        .request<ISuccessResponse<RedisResponse>>("/api/search", {
          method: "post",
          body: JSON.stringify({
            communityId,
            exclude: ignoreUserIds,
            age,
            sexuality,
            gender,
            locationId,
            geo,
            from: pageParam.from,
            size: queryLimit,
            lastDate: pageParam.lastDate,
          }),
        })
        .then(([_r, res]) => {
          return parse([], communityId, res.data);
        });
    },
    getNextPageParam(lastPage, allPages) {
      if (lastPage.length === queryLimit) {
        return { from: allPages.length * queryLimit, lastDate: Date.now() };
      }
      return;
    },
  });

  const hideUser = useCallback((uid: string) => {
    setPostFilter((p) => p.concat(uid));
    // mutate search results
  }, []);

  const loadMore = () => rest.fetchNextPage();

  return [
    data?.pages.reduce<UserEntity[]>(
      (all, p) => all.concat(p.filter((u) => !postFilter.includes(u.uid))),
      [],
    ) || [],
    error,
    loadMore,
    hideUser,
  ];
}
