// libraries
import { useContext, useEffect, useState, useMemo, useCallback } from "react";

// contexts
import { SubscriptionContext } from "../contexts/subscription/subscriptions.context";

// types
import {
  DocumentData,
  DocumentSnapshot,
  QuerySnapshot,
  Query,
} from "firebase/firestore";
import {
  CollectionSubscriptionState,
  FirestoreCollectionSnapshotProps,
  SubscriptionRecordState,
  SubscriptionStateListener,
  SubscriptionStates,
} from "../contexts/subscription/subscription.types";
import { defaultLimit } from "../contexts/subscription/subscription.provider";

type HookListenerState<Value, Error> = {
  updatedAt?: number;
  res?: DocumentSnapshot<DocumentData> | QuerySnapshot<DocumentData>;
  data: SubscriptionStateListener<Value, Error>;
};

/**
 * useFirestoreSubListener
 *
 * this hook adds a Subscription listener for a document, dispatching prop updates into react land when the document is updated
 */
export function useFirestoreSubListener<Value = unknown, Error = unknown>(
  path: string,
): SubscriptionStateListener<Value, Error> {
  const { addFirestoreSnapshot, removeFirestoreSub, state } =
    useContext(SubscriptionContext);

  const unSubFcn = useCallback(
    () => removeFirestoreSub(path),
    [path, removeFirestoreSub],
  );

  const [listener, setListener] = useState<HookListenerState<Value, Error>>({
    updatedAt: undefined,
    data: [undefined, undefined, undefined, undefined],
  });

  const targetState = state[path] as SubscriptionRecordState<Value, Error>;

  /**
   * If target state is or becomes undefined and/or the path changes
   * - set initial undefined listened state
   * - add firestore document subscription to that path
   */
  useEffect(() => {
    if (!targetState && path) {
      // reset listener if path changes and there is no targetState
      setListener({
        updatedAt: undefined,
        data: [undefined, undefined, undefined, undefined],
      });
      addFirestoreSnapshot(path);
    }
  }, [addFirestoreSnapshot, path, targetState]);

  /**
   * Propagate target state to listener
   */
  useEffect(() => {
    if (targetState && targetState?.updatedAt !== listener.updatedAt) {
      if (targetState.error) {
        // eslint-disable-next-line
        console.warn(targetState);
      }

      setListener({
        updatedAt: targetState?.updatedAt,
        data: [
          targetState?.value,
          targetState?.state,
          targetState?.error,
          unSubFcn,
        ],
      });
    }
  }, [targetState, listener, unSubFcn]);

  return listener.data;
}

/**
 * useFirestoreDocsListener
 *
 * this hook adds a Subscription listener for a document, dispatching prop updates into react land when the document is updated
 */
export function useListenDocs<Value = unknown, Error = unknown>(
  paths: string[],
): [Record<string, SubscriptionRecordState<Value, Error>>, () => void] {
  type DocsListeners = Record<string, SubscriptionRecordState<Value, Error>>;
  const { addFirestoreSnapshot, removeFirestoreSub, state } =
    useContext(SubscriptionContext);

  const unSubFcn = useCallback(() => {
    paths.forEach((path) => removeFirestoreSub(path));
  }, [paths, removeFirestoreSub]);

  if (paths.length > 25) {
    // eslint-disable-next-line
    console.warn("Listening to more than 25 docs");
  }

  const [listener, setListener] = useState<DocsListeners>({});

  const targetState = paths.reduce<DocsListeners>((acc, path) => {
    acc[path] = state[path] as SubscriptionRecordState<Value, Error>;
    return acc;
  }, {});

  /**
   * If target state is or becomes undefined and/or the path changes
   * - set initial undefined listened state
   * - add firestore document subscription to that path
   */
  useEffect(() => {
    paths.forEach((path) => {
      if (!targetState[path] && path) {
        // reset listener if path changes and there is no targetState
        setListener((_state) => ({
          ..._state,
          [path]: {
            updatedAt: undefined,
            state: undefined,
          },
        }));
        addFirestoreSnapshot(path);
      }
    });
  }, [addFirestoreSnapshot, paths, targetState]);

  /**
   * Propagate target state to listener
   */
  useEffect(() => {
    paths.forEach((path) => {
      if (
        targetState[path] &&
        targetState[path]?.updatedAt !== listener[path]?.updatedAt
      ) {
        if (targetState[path].error) {
          // eslint-disable-next-line
          console.warn(listener[path]);
        }

        setListener((_state) => ({
          ..._state,
          [path]: { ...targetState[path] },
        }));
      }
    });
  }, [targetState, listener, paths]);

  return [listener, unSubFcn];
}

/**
 * useFirestoreCollectionSubListener
 *
 * this hook adds a Subscription listener for a collection query. Dispatching prop updates when the corresponding documents / collection is changes
 */

type ListenerMapType<Value, Error> = Record<
  string,
  HookListenerState<Value, Error>
>;

const emptyListener = JSON.stringify({
  updatedAt: undefined,
  data: [undefined, undefined, undefined],
});

function getKey(
  path:
    | string
    | {
        key: string;
        ref: Query<DocumentData>;
      },
): string {
  if (typeof path === "string") {
    return path;
  } else {
    return path.key;
  }
}

// prettier-ignore
export function useFirestoreCollectionSubListener<
  Value = unknown[],
  Error = unknown,
>({
  path,
  orderBy = [{ fieldPath: "createdAt", directionStr: "asc" }],
  where,
}: FirestoreCollectionSnapshotProps): CollectionSubscriptionState<
  Value,
  Error
> {
  const {
    addFirestoreCollectionSnapshot,
    state: subState,
    encodeKey,
  } = useContext(SubscriptionContext);

  /**
   *
   * hook state system
   *
   * {
   *  subs: [ sub1, sub2, sub3],
   *  listenerMap: {sub1: [ values, errors, states], sub2: [ values, errors, states], ... }
   *  lastLoadedId
   * }
   */
  const [listenerMap, setListenerMap] = useState<ListenerMapType<Value, Error>>(
    {},
  );

  // use btoa to base64 encode the path with orderBy data (orderBy should be a new query)
  const [subKeys, setSubKeys] = useState<string[]>([]);

  /**
   * Functions
   */
  const loadMore = useMemo<(() => void) | undefined>(() => {
    // did the last sub listener return all the results
    const lastDoc = [subKeys[subKeys.length - 1]]
      .map((key) => listenerMap[key])
      .map((listener) => listener?.res as QuerySnapshot<DocumentData>)
      .map((data) => {
        if (data) {
          if (data.size < defaultLimit) {
            return undefined;
          } else {
            return data.docs[data.size - 1];
          }
        }
        return undefined;
      })[0];

    if (lastDoc) {
      return () => {
        const newKey = encodeKey(getKey(path), orderBy, where, lastDoc.id);
        setSubKeys((keys) => [...keys, newKey]);
        setListenerMap((state) => ({
          ...state,
          [newKey]: JSON.parse(emptyListener),
        }));
        addFirestoreCollectionSnapshot({
          path,
          orderBy,
          where,
          startAfter: lastDoc,
        });
      };
    } else {
      return undefined;
    }
  }, [
    addFirestoreCollectionSnapshot,
    encodeKey,
    listenerMap,
    orderBy,
    path,
    subKeys,
    where,
  ]);

  /**
   * Effects
   */

  // init
  useEffect(() => {
    setSubKeys([encodeKey(getKey(path), orderBy, where)]);
    setListenerMap({
      [encodeKey(getKey(path), orderBy, where)]: JSON.parse(emptyListener),
    });
    addFirestoreCollectionSnapshot({ path, orderBy, where });

    return () => {
      setSubKeys([]);
      setListenerMap({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addFirestoreCollectionSnapshot]);

  /**
   * If target state is or becomes undefined, the path changes, and/or the orderBy prop changes
   * - set initial undefined listened state
   * - add firestore collection subscription to that path with the orderBy prop
   */
  useEffect(() => {
    // if path / orderby change - reset pagination data
    if (encodeKey(getKey(path), orderBy, where) !== subKeys[0]) {
      setSubKeys([encodeKey(getKey(path), orderBy, where)]);
      // reset listener if path changes and there is no targetState
      setListenerMap({
        [encodeKey(getKey(path), orderBy, where)]: JSON.parse(emptyListener),
      });
      addFirestoreCollectionSnapshot({ path, orderBy, where });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addFirestoreCollectionSnapshot, orderBy, path, where]);

  /**
   * update local state with subscription state changes
   */
  useEffect(() => {
    subKeys.map((key) => {
      const targetState = subState[key];
      if (
        targetState &&
        targetState?.updatedAt !== listenerMap[key]?.updatedAt
      ) {
        setListenerMap((state) => ({
          ...state,
          [key]: {
            updatedAt: targetState?.updatedAt,
            res: targetState?.res,
            data: [targetState?.value, targetState?.state, targetState?.error, undefined],
          } as HookListenerState<Value, Error>,
        }));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subKeys, subState]);

  // TODO: refactor and simplify merging of data
  const api = useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: Object.values(listenerMap).reduce<any>((acc, cur) => {
        if (cur.data[0] && Array.isArray(cur.data[0])) {
          acc.push(...cur.data[0]);
        }
        return acc;
      }, []) as Value,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errors: Object.values(listenerMap).reduce<any>((currentState, state) => {
        const stateError = state.data[2];

        // eslint-disable-next-line
        console.warn(stateError);

        if (!currentState && stateError) {
          return [stateError];
        }
        if (stateError && Array.isArray(currentState)) {
          return currentState.push(stateError);
        }
        return currentState;
      }, undefined) as Error,
      state: Object.values(listenerMap).reduce<SubscriptionStates>(
        (currentState, state) => {
          if (!currentState) {
            return state.data[1];
          }
          if (currentState === "initialized" || currentState === "errored") {
            return currentState;
          }
          if (state.data[1] === "errored" || state.data[1] === "initialized") {
            return state.data[1];
          }
          return currentState;
        },
        undefined,
      ),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      loadMore,
    }),
    [listenerMap, loadMore],
  );

  return api;
}

export const useFCSL = useFirestoreCollectionSubListener;
