// libraries
import {
  createContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
  useContext,
} from "react";
import mergeWith from "lodash.mergewith";
import set from "lodash.set";

// utilities

// Types
import { ConfirmationModalType } from "../components/ConfirmationModal";
import { deepEqual, traverseObjectPath } from "@iluvatar/global/src/utilities";

// TODO: push feature toggles here
type DataStoreValues = {
  globalLoader: Record<string, boolean>;
  drawerState: boolean;
  confirmationModal: ConfirmationModalType;
};

interface SetDataOptions {
  merge?: boolean;
  concatArrays?: boolean;
}

interface IDataStoreContext {
  data: DataStoreValues;
  setStoreData: (
    path: string,
    data: DataStoreValues,
    options?: SetDataOptions,
  ) => void;
  cleanStoreData: () => void;
}

export const emptyStoreState: DataStoreValues = {
  globalLoader: {},
  drawerState: true,
  confirmationModal: {
    open: false,
    actions: [],
  },
};

// Context
const DataStoreContext = createContext<IDataStoreContext>({
  data: JSON.parse(JSON.stringify(emptyStoreState)),
  setStoreData: () => null,
  cleanStoreData: () => null,
});

/**
 * DataStore Provider - Single global state management for the app (redux alternative)
 */
export const DataStoreProvider: React.FC<
  React.PropsWithChildren<{ initialData: DataStoreValues }>
> = ({ children, initialData }) => {
  const [data, setData] = useState(initialData);

  // eslint-disable-next-line no-console
  // console.log("DATASTORE state >> ", data);

  /**
   * BUG: setting newData thats an instantiated class. destroys that class. i.e., Timestamp => {_seconds:, _nanoseconds:}
   */
  const setStoreData = useCallback(
    (path: string, newData: unknown, options: SetDataOptions = {}) => {
      setData((currentState) => {
        if (!options.merge) {
          /**
           * override any value set within the path.
           *
           * eg,. setting { prop2:"a"} with original data { prop: "test" } would result in { prop2: "a" }
           * eg,. setting [4,5,6] with original data [1,2,3] would result in [4,5,6]
           *
           * lodash 'set' set will create the path if id does not already exist (handling both array and object creation)
           */
          const currentTarget = traverseObjectPath(path, { ...currentState });
          if (
            Boolean(currentTarget) &&
            (typeof currentTarget !== typeof newData ||
              Array.isArray(currentTarget) !== Array.isArray(newData))
          ) {
            // eslint-disable-next-line no-console
            console.warn(
              `DATASTORE WARN - Set >> path: '${path}' >> data type miss-match`,
              currentTarget,
              newData,
            );
          }
          return set({ ...currentState }, path, newData);
        } else {
          /**
           * When merge is specified we want the desired behavior to be as follows
           *
           * Only merge for arrays or objects.
           *
           * When merging { prop2:"a"} with the original data { prop: "test" } the result would be { prop; "test", prop2: "a" }
           * When merging [4,5,6] with the original data [1,2,3] the result would be [1,2,3,4,5,6]
           */
          if (
            typeof newData === "string" ||
            typeof newData === "number" ||
            typeof newData === "boolean" ||
            typeof newData === "bigint"
          ) {
            // eslint-disable-next-line no-console
            console.warn(
              `DATASTORE WARN - Merge >> path: '${path}' >> merging unsupported data type '${typeof newData}`,
            );
          }

          const emptyType = Array.isArray(newData) ? [] : {};
          /**
           * create an object with to proper hierarchy to merge data in the right place
           * e.g., if newDate is { propX: "test" } and path is "tenant.metadata" we want to merge
           * ```{ tenant: { metadata: { propX: "test" } } }``` with whatever data is in the store
           *
           * similarly, if newDate is ["testing"] and path is "tenant.metadataKeys" we want to merge
           * ```{ tenant: { metadataKeys: ["testing"] } }``` with whatever data is in the store
           *
           * If the type of data in the store is SET and different than the type of data passed in, we should throw a dev warning
           *
           * 27.11.2021 add concatArrays option so we can merge with without duplicating array entries
           *
           */
          const newState = set(emptyType, path, newData);
          return mergeWith(
            { ...currentState },
            newState,
            (objValue, srcValue) => {
              if (
                Boolean(objValue) &&
                (typeof objValue !== typeof srcValue ||
                  Array.isArray(objValue) !== Array.isArray(srcValue))
              ) {
                // eslint-disable-next-line no-console
                console.warn(
                  `DATASTORE WARN - Merge >> path: '${path}' >> data type miss-match`,
                  objValue,
                  srcValue,
                );
              }
              if (options.concatArrays && Array.isArray(objValue)) {
                return objValue.concat(srcValue);
              }
            },
          );
        }
      });
    },
    [setData],
  );

  /**
   * cleanStoreData - after successful login (or after logout) clean the data store.
   */
  const cleanStoreData = useCallback(() => { 
    setData(JSON.parse(JSON.stringify(emptyStoreState))) 
  }, []); /* prettier-ignore */

  const providerState = useMemo(
    () => ({
      data,
      setStoreData,
      cleanStoreData,
    }),
    [data, setStoreData, cleanStoreData],
  );

  return (
    <DataStoreContext.Provider value={providerState}>
      {children}
    </DataStoreContext.Provider>
  );
};

/**
 * Hook for watching the WHOLE data store
 */
export const useDataStore = (): IDataStoreContext => useContext(DataStoreContext); /* prettier-ignore */

type IDataStoreSliceHook<Value = unknown> = [
  Value,
  (data: Partial<Value>, options?: SetDataOptions) => void,
];

/**
 * Hook for watching a 'slice' of the data store.
 *
 * This is a utility that returns a watcher for the property at the end of the path
 * and a setter for updating that property
 */
export function useStore<SlicePropertyType>(
  path: string,
): IDataStoreSliceHook<SlicePropertyType> {
  const { data, setStoreData } = useDataStore();
  const [sliceData, setSliceData] = useState(() =>
    traverseObjectPath<SlicePropertyType>(path, data),
  );

  const setDataStoreSliceData = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (WatchData: any, options?: SetDataOptions) => {
      /**
       * NOTE: using setSliceData will cause an extra look in this hook (sliceData updates).
       * whereas adding sliceData into the dependency list here will cause an extra re-render in the component implementing it.
       *
       * NOTE: it may be better to push this into the setStoreData function
       *
       * NOTE: not filtering updates can cause infinite sets with the useOnPageLoad hook
       */

      // I WOULD LIKE TO USE THIS BUT ITS BUGGY
      // setSliceData((currentValue: SlicePropertyType) => {
      //   if (!deepEqual(currentValue, WatchData)) {
      //     setStoreData(path, WatchData, options);
      //   }
      //   return WatchData;
      // });

      if (!deepEqual(sliceData, WatchData)) {
        setStoreData(path, WatchData, options);
      }
    },
    [setStoreData, path, sliceData],
  );

  useEffect(() => {
    const target = traverseObjectPath<SlicePropertyType>(path, data);
    // NOTE: this deep equal is a JSON.stringify based check. it wont catch reordering of props (not an issue for this)
    if (!deepEqual(target, sliceData)) {
      // eslint-disable-next-line no-console
      // console.log(`DATASTORE slice update - ${path} >>`, target);
      setSliceData(target);
    }
  }, [path, data, sliceData, setSliceData]);

  const hookApi = useMemo<IDataStoreSliceHook<SlicePropertyType>>(
    () => [sliceData, setDataStoreSliceData],
    [sliceData, setDataStoreSliceData],
  );

  return hookApi;
}
