// libraries
import { clsx } from "clsx";
import tinycolors from "tinycolor2";

// styles
import "./inspection-question.scss";

export function InspectionQuestionChip({
  children,
  color,
  selectedValue,
  optionValue,
  readonly,
  disabled,
  style,
  onClick,
}: {
  children: React.ReactNode;
  color?: string;
  active?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  selectedValue?: unknown;
  optionValue?: unknown;
  onClick?: () => void;
}): JSX.Element {
  const active = selectedValue === optionValue;
  const backgroundColor = !active ? "var(--color-background)" : color;
  const isLight = color && tinycolors(backgroundColor).isLight();
  const textColor = !active
    ? "var(--color-text--secondary)"
    : isLight
      ? "var(--color-text--light)"
      : "var(--color-text--dark)";

  return (
    <div
      className={clsx("inspection-question-chip", {
        active,
        readonly,
        disabled,
      })}
      style={{
        ...style,
        opacity: !active && (disabled || readonly) ? 0.4 : undefined,
        WebkitTextFillColor: textColor,
        color: textColor,
        backgroundColor,
        borderColor:
          disabled || readonly ? "var(--color-text--readonly)" : color,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
