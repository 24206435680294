// libraries
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";

// utilities
import { flattenObject, isObject } from "@iluvatar/global/src/utilities/object";

// types
import {
  ICondition,
  IConditionActions,
  TFieldValue,
} from "@iluvatar/global/src/typings";

function isEmpty(value: unknown): boolean {
  if (typeof value === "string") {
    return value === "";
  }
  // if (typeof value === "number") {
  //   return value === 0;
  // }
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  if (typeof value === "object" && value !== null && isObject(value)) {
    return Object.keys(value).length === 0;
  }
  if (value === undefined) {
    return true;
  }
  // 0, false, null are returned as nonEmpty (as desired);
  return false;
}

function isFieldValueArray(
  val: TFieldValue | TFieldValue[],
): val is TFieldValue[] {
  return Array.isArray(val);
}

/**
 * TODO: we need to add unit tess for this
 * we are using == so that 1 is equal to true and 0 is equal to false. (this is for the checkbox conditional check)
 */
function testCondition(condition: ICondition) {
  return function conditionCheck(
    formValue: TFieldValue | TFieldValue[] | unknown,
  ): boolean {
    switch (condition.operation) {
      case "intersects": {
        if (isEmpty(condition.value) || isEmpty(formValue)) {
          return false;
        }
        // intersection of the two sets is not empty
        return Boolean(
          Array.isArray(formValue) &&
            Array.isArray(condition.value) &&
            formValue.some((_formVal) =>
              (condition.value as unknown[]).includes(_formVal),
            ),
        );
      }
      case "includes": {
        if (isEmpty(condition.value)) {
          return false;
        }
        return Boolean(
          formValue &&
            Array.isArray(formValue) &&
            formValue.includes(condition.value),
        );
      }
      case "!=": {
        if (isEmpty(condition.value)) {
          return !isEmpty(formValue);
        }
        return condition.value != formValue;
      }
      case "in": {
        return (
          isFieldValueArray(condition.value) &&
          condition.value.some((data) => {
            if (isEmpty(data)) {
              // THINK; should we do a type check before return
              // typeof data === typeof formValue && isEmpty(formValue)
              return isEmpty(formValue);
            }
            return data == formValue;
          })
        );
      }
      case "notin": {
        return (
          isFieldValueArray(condition.value) &&
          condition.value.every((data) => {
            if (isEmpty(data)) {
              return !isEmpty(formValue);
            }
            return data != formValue;
          })
        );
      }
      case "==":
      default: {
        if (isEmpty(condition.value)) {
          return isEmpty(formValue);
        }
        return condition.value == formValue;
      }
    }
  };
}

export function isParameterCondition(test: string): boolean {
  return /^\[\[([a-zA-Z0-9._*]+)\]\]$/g.test(test);
}

export function parseParameterCondition(val: string) {
  return val.replace("[[", "").replace("]]", "");
}

export function useFormConditionals(
  path: string,
  conditions?: ICondition[],
): string[] {
  const formik = useFormikContext<Record<string, string | unknown>>();

  const [renderField, setRenderField] = useState<IConditionActions[]>([]);

  useEffect(() => {
    if (conditions) {
      const flat = flattenObject(formik.values, undefined, {
        preserveArray: true,
      });
      const actions = new Set<IConditionActions>([]);

      conditions?.forEach((condition) => {
        const _params = [];

        if (condition.parameter.at(0) === ".") {
          /**
           * add `.` to beginning of path (.fieldA.fieldB / .fieldA)
           * replace last field           (.fieldA.fieldB => .fieldA.newFieldC)
           * remove first `.`             (.fieldA.fieldB => fieldA.fieldB)
           */
          _params.push(
            `.${path}`
              // eslint-disable-next-line no-useless-escape
              .replace(/\.([^\s.]+)$/g, condition.parameter)
              .replace(/^\./g, ""),
          );
        } else if (condition.parameter) {
          _params.push(condition.parameter);
        }

        _params
          .map((trg) => {
            if (isParameterCondition(condition.parameter)) {
              // empty
            }
            /**
             * some form widgets will store objects not values.
             * The primary value used for comparisons will always be stored at  ${fieldPath}._value
             */
            return flat[trg] || flat[`${trg}._value`];
          })
          .filter(testCondition(condition))
          .map(() => {
            actions.add(condition?.action || "hidden");
          });
      });

      setRenderField(actions.size ? [...actions.values()] : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Boolean(conditions) && path,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Boolean(conditions) && conditions,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Boolean(conditions) && formik.values,
  ]);

  if (!conditions) {
    return [];
  } else {
    return renderField;
  }
}
