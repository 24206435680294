import { cn } from "@/utilities";
import { useDrag } from "react-dnd";

export function DragWrapper({
  targetIdx,
  className,
  isActive,
  ...rest
}: {
  targetIdx: number;
  isActive?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) {
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: "CARD",
    item: { id: targetIdx },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      {...rest}
      id={`drag-zone-${targetIdx}`}
      className={cn(className, {
        isDragging,
      })}
      ref={dragRef}
    >
      {rest.children}
    </div>
  );
}
