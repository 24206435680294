// libraries
import { Link, FileRoute } from "@tanstack/react-router";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";

// MUI
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import ArrowBack from "@mui/icons-material/ArrowBack";

// services
import requestService from "../services/request.service";

import { useToggle } from "../hooks/useToggle";
import { useToastContext } from "../contexts/toast.context";

// components
import UnAuthLayout from "../layout/unauthed-page";

// styles
import "../scss/auth.scss";
import { FORM_GLOBAL_ERROR_KEY } from "@iluvatar/global/src/constants";

// types
type PasswordResetFormValue = {
  email: string;
  // for displaying general form errors
  authForm?: never;
};

const AUTH_FORM_VALIDATOR = Yup.object().shape({
  email: Yup.string().required().email(),
});

function PasswordResetPage() {
  const { addToast } = useToastContext();
  const [showInstructions, setShowInstructions] = useToggle(false);

  const authFormSubmission = async (
    { email }: PasswordResetFormValue,
    helpers: FormikHelpers<PasswordResetFormValue>,
  ) => {
    try {
      await requestService.request("/api/password-reset", {
        method: "post",
        body: JSON.stringify({ email }),
      });
      addToast("Password reset email sent");
      setShowInstructions(true)();
    } catch (error) {
      // eslint-disable-next-line
      const err = error as any;
      if (err.message && err.message === "Email address not found") {
        helpers.setFieldError(
          "email",
          "We can not find an account that matches that email address.  Please contact your system administrator for further assistance.",
        );
      } else if (err?.message) {
        helpers.setFieldError(FORM_GLOBAL_ERROR_KEY, err.message);
      } else {
        helpers.setFieldError(FORM_GLOBAL_ERROR_KEY, err);
      }
    }
  };

  const { handleChange, handleSubmit, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: AUTH_FORM_VALIDATOR,
      onSubmit: authFormSubmission,
    });

  return (
    <UnAuthLayout>
      <div
        className="auth-page-container"
        style={{ maxWidth: "450px", margin: "auto" }}
      >
        {!showInstructions && (
          <>
            <Typography variant="h5" color="text.secondary" sx={{ mt: 3 }}>
              Reset Password
            </Typography>
            <form noValidate className="auth-form" onSubmit={handleSubmit}>
              <TextField
                fullWidth
                required
                className="auth-form-element"
                label="Email"
                name="email"
                variant="outlined"
                error={touched.email && Boolean(errors.email)}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                helperText={touched.email ? errors.email : undefined}
              />
              <Button
                fullWidth
                className="auth-form-element auth-button"
                color="primary"
                variant="outlined"
                type="submit"
              >
                Send Instructions
              </Button>
            </form>
          </>
        )}
        {showInstructions && (
          <>
            <Typography variant="h5" color="text.secondary" sx={{ my: 3 }}>
              Instructions Sent!
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, maxWidth: "400px" }}>
              We found an account that matched your email address and have sent
              you instructions on how to reset your password.
            </Typography>
          </>
        )}
        <Link
          to="/login"
          className="flex f-c-c"
          style={{ color: "var(--color-text--secondary)" }}
        >
          <ArrowBack sx={{ mr: 1 }} />
          back to Login
        </Link>
      </div>
    </UnAuthLayout>
  );
}

export const Route = new FileRoute("/password-reset").createRoute({
  component: PasswordResetPage,
});
