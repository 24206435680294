// libraries
import { clsx } from "clsx";
import { useField } from "formik";
import { useCallback, useEffect } from "react";

// context
import { useFormContext } from "../../../../contexts/form.context";

// MUI
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";

// utilities
import { fieldValidator } from "..";

// components
import { InspectionQuestionChip } from "./UI/InspectionQuestionChip";

// types
import {
  InspectionQuestionField,
  IFieldOptions,
} from "@iluvatar/global/src/typings";

// styles
import "./inspection-question.scss";

interface InspectionQuestionFieldInterface
  extends Omit<InspectionQuestionField, "fieldType"> {
  className?: string;
  options: IFieldOptions[];
  formControlProps?: Partial<FormControlProps>;
  formControlLabelProps?: Omit<Partial<FormControlLabelProps>, "control">;
}

export function InspectionQuestion({
  className,
  name: _name,
  label,
  disabled,
  required,
  readonly,
  defaultValue,
  description,
  options = [],
  formControlLabelProps = {},
  formControlProps = {},
}: InspectionQuestionFieldInterface): JSX.Element {
  const name = `${_name}._value`;

  const { onFieldChange } = useFormContext();

  const [field, { touched, error }, { setValue }] = useField({
    name,
    validate: fieldValidator(required),
  });

  useEffect(() => {
    if (field.value === undefined && defaultValue !== undefined) {
      setValue(defaultValue);
      onFieldChange(name, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Derived Properties */
  const changeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!readonly) {
        setValue(e.currentTarget.value);
        onFieldChange(name, e.currentTarget.value);
      }
    },
    [readonly, setValue, onFieldChange, name],
  );

  const unSelectHandler = useCallback(
    (ev: React.MouseEvent<HTMLInputElement>) => {
      if (ev.currentTarget.value === field.value) {
        setValue(undefined);
      }
    },
    [field.value, setValue],
  );

  const hasError = Boolean(touched && error);
  const note = hasError ? error : description;

  return (
    <>
      <FormControl
        {...formControlProps}
        className={clsx({ readonly }, className)}
        error={hasError}
        disabled={disabled || readonly}
        required={required}
        sx={{ whiteSpace: "pre-line" }}
      >
        <div className="flex f-c inspection-question">
          <FormLabel id={`${name}-radio-buttons-group-label`}>
            {label}
          </FormLabel>
          <RadioGroup
            row
            name={name}
            defaultValue={defaultValue}
            aria-labelledby={`${name}-radio-buttons-group-label`}
            sx={{
              mt: 1,
              px: 1,
              flexGrow: 1,
              justifyContent: "flex-end",
              gap: "8px",
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={`${option.label}-${option.value}`}
                {...formControlLabelProps}
                className={clsx(
                  formControlLabelProps.className,
                  "inspection-question-chip-label",
                )}
                value={option.value}
                labelPlacement="start"
                label={""}
                control={
                  <InspectionQuestionChip
                    readonly={readonly}
                    disabled={disabled}
                    selectedValue={field.value}
                    optionValue={option.value}
                    color={option?.meta?.color as string}
                  >
                    {option.value}
                    <input
                      hidden
                      type="radio"
                      {...field}
                      onClick={
                        disabled || readonly ? undefined : unSelectHandler
                      }
                      enterKeyHint="next"
                      onChange={changeHandler}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      value={option.value as any}
                      checked={option.value === field.value}
                    />
                  </InspectionQuestionChip>
                }
              />
            ))}
          </RadioGroup>
        </div>
        {Boolean(note) && (
          <FormHelperText sx={{ m: 0 }} error={hasError}>
            {note}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
}
