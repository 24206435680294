import { Route as rootRoute } from "./pages/__root"
import { Route as RegisterImport } from "./pages/register"
import { Route as PasswordResetFormImport } from "./pages/password-reset-form"
import { Route as PasswordResetImport } from "./pages/password-reset"
import { Route as MatchesImport } from "./pages/matches"
import { Route as LogoutImport } from "./pages/logout"
import { Route as LoginImport } from "./pages/login"
import { Route as LikesImport } from "./pages/likes"
import { Route as IndexImport } from "./pages/index"
import { Route as ChatChatIdImport } from "./pages/chat.$chatId"
import { Route as UserUserIdIndexImport } from "./pages/user.$userId.index"
import { Route as UserUserIdEditImport } from "./pages/user.$userId.edit"
import { Route as UserUserIdAccountSettingsImport } from "./pages/user.$userId.account-settings"

const RegisterRoute = RegisterImport.update({
  path: "/register",
  getParentRoute: () => rootRoute,
} as any)

const PasswordResetFormRoute = PasswordResetFormImport.update({
  path: "/password-reset-form",
  getParentRoute: () => rootRoute,
} as any)

const PasswordResetRoute = PasswordResetImport.update({
  path: "/password-reset",
  getParentRoute: () => rootRoute,
} as any)

const MatchesRoute = MatchesImport.update({
  path: "/matches",
  getParentRoute: () => rootRoute,
} as any)

const LogoutRoute = LogoutImport.update({
  path: "/logout",
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  path: "/login",
  getParentRoute: () => rootRoute,
} as any)

const LikesRoute = LikesImport.update({
  path: "/likes",
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  path: "/",
  getParentRoute: () => rootRoute,
} as any)

const ChatChatIdRoute = ChatChatIdImport.update({
  path: "/chat/$chatId",
  getParentRoute: () => rootRoute,
} as any)

const UserUserIdIndexRoute = UserUserIdIndexImport.update({
  path: "/user/$userId/",
  getParentRoute: () => rootRoute,
} as any)

const UserUserIdEditRoute = UserUserIdEditImport.update({
  path: "/user/$userId/edit",
  getParentRoute: () => rootRoute,
} as any)

const UserUserIdAccountSettingsRoute = UserUserIdAccountSettingsImport.update({
  path: "/user/$userId/account-settings",
  getParentRoute: () => rootRoute,
} as any)
declare module "@tanstack/react-router" {
  interface FileRoutesByPath {
    "/": {
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    "/likes": {
      preLoaderRoute: typeof LikesImport
      parentRoute: typeof rootRoute
    }
    "/login": {
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    "/logout": {
      preLoaderRoute: typeof LogoutImport
      parentRoute: typeof rootRoute
    }
    "/matches": {
      preLoaderRoute: typeof MatchesImport
      parentRoute: typeof rootRoute
    }
    "/password-reset": {
      preLoaderRoute: typeof PasswordResetImport
      parentRoute: typeof rootRoute
    }
    "/password-reset-form": {
      preLoaderRoute: typeof PasswordResetFormImport
      parentRoute: typeof rootRoute
    }
    "/register": {
      preLoaderRoute: typeof RegisterImport
      parentRoute: typeof rootRoute
    }
    "/chat/$chatId": {
      preLoaderRoute: typeof ChatChatIdImport
      parentRoute: typeof rootRoute
    }
    "/user/$userId/account-settings": {
      preLoaderRoute: typeof UserUserIdAccountSettingsImport
      parentRoute: typeof rootRoute
    }
    "/user/$userId/edit": {
      preLoaderRoute: typeof UserUserIdEditImport
      parentRoute: typeof rootRoute
    }
    "/user/$userId/": {
      preLoaderRoute: typeof UserUserIdIndexImport
      parentRoute: typeof rootRoute
    }
  }
}
export const routeTree = rootRoute.addChildren([
  IndexRoute,
  LikesRoute,
  LoginRoute,
  LogoutRoute,
  MatchesRoute,
  PasswordResetRoute,
  PasswordResetFormRoute,
  RegisterRoute,
  ChatChatIdRoute,
  UserUserIdAccountSettingsRoute,
  UserUserIdEditRoute,
  UserUserIdIndexRoute,
])
