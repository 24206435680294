// libraries
import { useState } from "react";
import {
  collection,
  or,
  limit,
  query,
  where,
  orderBy,
  getDocs,
  and,
  startAfter,
} from "firebase/firestore";
import { useInfiniteQuery } from "@tanstack/react-query";
import { FileRoute, Link } from "@tanstack/react-router";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// services
import { cn } from "@/utilities";
import { firestore } from "@/services/firebase.init";

// hooks
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useNotificationDismissal } from "@/hooks/notificationDismissal";

// components
import DefaultLayout from "@/layout/default-page";
import { QueryMatchUser } from "@/components/MatchUserPreview";

// types
import { MatchRecord, PageTimings, User } from "@iluvatar/global/src/typings";
import { MATCH_COLLECTION } from "@iluvatar/global/src/constants";
const queryLimit = 25;

function hasChatNotification(user: User, chatId: string): boolean {
  const key: keyof PageTimings = `matches.${chatId}`;
  return Boolean(
    user.pageUpdateTimes &&
      user.pageViewsTimes &&
      (user.pageUpdateTimes[key] || 0) > (user.pageViewsTimes[key] || 0),
  );
}

const MatchesPage: React.FC = () => {
  useNotificationDismissal("matches");
  const { user } = useCurrentUser();
  const [communityId, setCommunityId] = useState("default");
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [
      "match-query",
      user?.uid,
      user?.matchMap[communityId],
      communityId,
    ],
    queryFn: ({ pageParam }) =>
      getDocs(
        query(
          collection(firestore, MATCH_COLLECTION),
          and(
            or(
              where("sourceUID", "==", user?.uid || ""),
              where("targetUID", "==", user?.uid || ""),
            ),
            where("communityId", "==", communityId),
          ),
          ...(pageParam?.startAfterDoc
            ? [startAfter(pageParam.startAfterDoc)]
            : []),
          orderBy("updatedAt", "asc"),
          limit(queryLimit),
        ),
      ),
    getNextPageParam: (lastPage) => {
      if (lastPage.size == queryLimit) {
        return { startAfterRef: lastPage.docs[lastPage.docs.length - 1] };
      }
      return;
    },
  });

  const matches = data?.pages.reduce<(MatchRecord & { _id: string })[]>(
    (_matches, p) => {
      return _matches.concat(
        p.docs.map(
          (d) => ({ ...d.data(), _id: d.id }) as MatchRecord & { _id: string },
        ),
      );
    },
    [],
  );

  const _total = user?.matchMap[communityId].length;

  return (
    <DefaultLayout className="profile-container flex start-start pb-12">
      <Typography variant="h2" className="mt-8">
        {/* <span className="text-[var(--color-primary)] mr-2">{_total}</span> */}
        {/* Matches{_total !== 1 ? "s" : ""} */}
        Messages
      </Typography>

      {user?.matchMap[communityId] && !user.matchMap[communityId].length && (
        <div>No Matches yet</div>
      )}

      {matches?.map((match) => {
        return (
          <Link
            key={match._id}
            to="/chat/$chatId"
            params={{ chatId: match._id }}
          >
            <QueryMatchUser
              className={cn(
                "border border-[var(--color-primary--active)] px-4 py-1 rounded-[16px] hover:border-[var(--color-primary)] clickable",
                {
                  "border-amber-500/50 hover:border-amber-500":
                    user && hasChatNotification(user, match._id),
                },
              )}
              communityId={match.communityId}
              uid={
                match.sourceUID == user?.uid ? match.targetUID : match.sourceUID
              }
            />
          </Link>
        );
      })}

      {hasNextPage && (
        <Button className="mt-5" onClick={() => fetchNextPage()}>
          Load More
        </Button>
      )}
    </DefaultLayout>
  );
};

export const Route = new FileRoute("/matches").createRoute({
  component: MatchesPage,
});
