// layouts
import TopNav from "./PageTopNav";

const UnAuthLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <>
      <TopNav />
      {children}
    </>
  );
};

export default UnAuthLayout;
