// libraries
import { useCallback, useEffect, useState } from "react";
import { useField } from "formik";
import { clsx } from "clsx";

// MUI
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";

// components
import { fieldValidator } from "..";

// context
import { useFormContext } from "../../../../contexts/form.context";

// types
import { IFieldBase } from "@iluvatar/global/src/typings";
import { CircularProgress } from "@mui/material";

interface CheckboxInterface extends IFieldBase {
  formControlProps?: Partial<FormControlProps>;
  formControlLabelProps?: Omit<Partial<FormControlLabelProps>, "control">;
  checkboxProps?: Partial<CheckboxProps>;
  className?: string;
}

export const GeolocationCheckboxField: React.FC<CheckboxInterface> = ({
  className,
  name,
  label,
  description,
  required,
  readonly,
  disabled,
  defaultValue,
  formControlProps = {},
  formControlLabelProps = {},
  checkboxProps = {},
}) => {
  const [loading, setLoading] = useState(false);
  const { onFieldChange } = useFormContext();
  const [fieldProps, meta, helpers] = useField({
    name,
    validate: fieldValidator(required),
  });

  const { touched, error } = meta;
  const { value } = fieldProps;
  const { setValue } = helpers;

  useEffect(() => {
    if (value === undefined && defaultValue !== undefined) {
      setValue(defaultValue);
      onFieldChange(name, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Derived Properties
   */
  const changeHandler = useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>, newValue: boolean) => {
      if (!("geolocation" in navigator)) {
        // eslint-disable-next-line no-console
        console.warn("geolocation is not available");
      }

      if (newValue && "geolocation" in navigator) {
        setLoading(true);
        navigator.geolocation.getCurrentPosition(
          (s) => {
            setValue({ lat: s.coords.latitude, long: s.coords.longitude });
            onFieldChange(name, {
              lat: s.coords.latitude,
              long: s.coords.longitude,
            });
            setLoading(false);
          },
          (err) => {
            // eslint-disable-next-line no-console
            setLoading(false);
            console.error(err);
            helpers.setError("Geolocation data failed");
          },
          {
            enableHighAccuracy: false,
            timeout: 30000,
            maximumAge: 10000,
          },
        );
      } else {
        setValue(null);
        onFieldChange(name, null);
      }
    },
    [setValue, onFieldChange, name, helpers],
  );

  const fixedLabel = required ? label + "*" : label;
  const hasError = Boolean(touched && error);
  const note = hasError ? error : description;

  return (
    <FormControl
      {...formControlProps}
      error={hasError}
      className={clsx({ readonly }, className)}
      disabled={disabled || readonly || loading}
      required={required}
    >
      <FormControlLabel
        labelPlacement="end"
        disabled={disabled || readonly || loading}
        {...formControlLabelProps}
        sx={{ ml: 0.25 }}
        label={
          <>
            {loading && (
              <CircularProgress size={18} sx={{ mb: "-4px", mr: 1 }} />
            )}
            {fixedLabel}
          </>
        }
        control={
          <Checkbox
            color={hasError ? "error" : "primary"}
            {...checkboxProps}
            required={required}
            {...fieldProps}
            disabled={disabled || readonly || loading}
            onChange={changeHandler}
            value={Boolean(value)}
            checked={Boolean(value) || false}
          />
        }
      />
      {Boolean(note) && (
        <FormHelperText error={hasError}>{note}</FormHelperText>
      )}
    </FormControl>
  );
};
