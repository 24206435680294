// libraries
import { clsx } from "clsx";
import { useState } from "react";

// MUI
import { SxProps } from "@mui/system";
import Article from "@mui/icons-material/Article";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";

// hooks
import { useToggle } from "../../hooks/useToggle";

// utilities
import { isFormFile } from "../../services/file";
import { isImage } from "@iluvatar/global/src/utilities";
import { downloadFirebaseFileRef } from "./download-utilities";

// components
import { FilePreviewContainer } from "./PreviewContainer";
import { DownloadFileButton } from "./DownloadFileButton";
import { ImagePreviewPopup } from "./ImagePreviewPopup";
import { RemoveFileButton } from "./RemoveFileButton";
import { ImageThumbnail } from "./ImageThumbnail";

// types
import { IFileReference } from "@iluvatar/global/src/typings";
import type { FormFile } from "../../services/file";

export function FileThumbnail<
  T extends FormFile | IFileReference = FormFile | IFileReference,
>({
  file,
  sx = {},
  disabled,
  removeFile,
  canDownload,
}: {
  file: T;
  sx?: SxProps;
  disabled?: boolean;
  canDownload?: boolean;
  removeFile?: (_file: T) => (ev: React.MouseEvent<HTMLElement>) => void;
}): JSX.Element {
  const [show, setShow] = useToggle(false);
  const [disableRemove, setDisableRemove] = useState(false);

  const downloadOriginal = (_file: IFileReference) => {
    return function clickEventHandler(ev: React.MouseEvent<HTMLElement>) {
      ev.stopPropagation();
      ev.preventDefault();
      downloadFirebaseFileRef(_file.ref);
    };
  };

  const removeHandler = async (ev: React.MouseEvent<HTMLElement>) => {
    if (!removeFile) {
      return;
    }
    try {
      setDisableRemove(true);
      await removeFile(file)(ev);
    } finally {
      setDisableRemove(false);
    }
  };

  const contentType = isFormFile(file)
    ? file?.file.type
    : file.metadata?.contentType;

  const _isImage = isImage(contentType);

  return (
    <>
      <FilePreviewContainer
        className={clsx({ clickable: _isImage })}
        onClick={_isImage ? setShow(true) : undefined}
        disabled={disabled}
        sx={sx}
      >
        {canDownload && !isFormFile(file) && (
          <DownloadFileButton onClick={downloadOriginal(file)} />
        )}
        {removeFile && (
          <RemoveFileButton disabled={disableRemove} onClick={removeHandler} />
        )}
        {_isImage && <ImageThumbnail file={file} />}

        {!_isImage && <Article color="info" fontSize="large" />}
      </FilePreviewContainer>
      {_isImage && (
        <ImagePreviewPopup
          show={show}
          closeHandler={setShow(false)}
          file={file}
        />
      )}
    </>
  );
}
