import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

// routes
import { RouterProvider, Router } from "@tanstack/react-router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Route as NotFoundRoute } from "./pages/_notFound";
import { routeTree } from "./routeTree.gen";

// contexts
import AllContexts from "./contexts/root";

// components
import ServiceWorkerPrompt from "./components/ServiceWorkerPrompt";
import { ConfirmationModal } from "./components/ConfirmationModal";

import "./services/firebase.service";
import "./scss/index.scss";
import "./main.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: true,
      retryDelay: 1000,
      retry(failureCount, error) {
        if (failureCount > 2) {
          // eslint-disable-next-line no-console
          console.error(error);
          return false;
        }
        return true;
      },
      retryOnMount: true,
    },
  },
});

// Set up a Router instance
const router = new Router({
  routeTree,
  context: {
    auth: undefined!, // We'll inject this when we render
    queryClient,
  },
  defaultPreload: "intent",
  defaultPendingMs: 400,
  defaultPendingMinMs: 300,
  defaultPreloadStaleTime: 0,
  notFoundRoute: NotFoundRoute,
  // defaultPendingComponent: () => (
  //   <div className={"p-2 text-2xl"}>
  //     <CircularProgress />
  //   </div>
  // ),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // defaultErrorComponent: ({ error }: any) => <ErrorComponent error={error} />,
});

// Register things for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const currentTheme = localStorage.getItem("theme");
if (currentTheme === "light") {
  document.querySelector("html")?.classList.remove("dark-theme");
} else {
  document.querySelector("html")?.classList.add("dark-theme");
}

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <AllContexts>
        <RouterProvider router={router} />
        <ConfirmationModal />
        <ServiceWorkerPrompt />
      </AllContexts>
    </QueryClientProvider>
  </StrictMode>,
);
