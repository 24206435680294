// libraries
import set from "lodash.set";
import { clsx } from "clsx";
import { useCallback, useState } from "react";
import {
  Formik,
  Form as FormikForm,
  FormikValues,
  FormikConfig,
  FormikHelpers,
} from "formik";

// MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// hooks
import { useToggle } from "../../hooks/useToggle";

// utilities
import {
  FormFile,
  generateProcessObject,
  ProgressTracker,
  uploadFiles,
} from "../../services/file";
import storageService from "../../services/storage.service";

// components
import { UploadTracker } from "../../services/file/TrackerComponent";
import FormikBlockRouting from "./FormikBlockRouting";
import { RenderFormDefinition } from "./FormDefinitionRender";
import {
  BubbleUpFormMetadata,
  BubbleUpFormValues,
  FormikOnChange,
  FormikPatchTouched,
  RootFormErrors,
} from "./utilities";

// context
import {
  FormProvider,
  ProviderValues,
  TFormOnChangeFunction,
} from "../../contexts/form.context";
import { FormDefinitionSlots } from "./types";
import { IFieldGroupBuilt } from "@iluvatar/global/src/typings";

// types
export type FormBubbleState<T = unknown> = { values: T };

export interface IFormInterface<Values> {
  onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void;
  id?: string;
  formPath?: string;
  children?: React.ReactNode;
  renderAfter?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  onChange?: (values: Values) => void;
  onChanges?: Record<string, TFormOnChangeFunction<Values>>;
  blockRouting?: boolean;
  setState?: (state: FormBubbleState) => void;
  storagePath?: string;
  setFormMetadata?: (state: FormState) => void;
  formDefinition?:
    | IFieldGroupBuilt
    | { sections: Record<string, IFieldGroupBuilt>; ordering: string[] };
  submitButtonText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
  slots?: FormDefinitionSlots;
}

export type FormPropType<Values extends FormikValues = FormikValues> =
  FormikConfig<Values> & IFormInterface<Values>;

export type FormState = { hasChanges: boolean };

// this creates the form
export function Form<Values extends FormikValues = FormikValues>(
  props: FormikConfig<Values> & IFormInterface<Values>,
): JSX.Element {
  const {
    id,
    onSubmit,
    readonly,
    setFormMetadata,
    disabled,
    setState,
    children,
    onChange,
    onChanges,
    className,
    storagePath,
    initialValues,
    formDefinition,
    submitButtonText,
    validationSchema,
    blockRouting = false,
    style,
    formPath,
    slots,
    ...rest
  } = props;

  const [blocking, toggleBlocking] = useState<boolean>(true);
  const [fileUploadInProgress, toggleUploadProgress] = useToggle(false);
  const [uploadProgress, setUploadProgress] = useState<ProgressTracker | undefined>(); // prettier-ignore
  const [formProviderData, setFormProviderData] = useState<ProviderValues>({ files: {} }); // prettier-ignore

  const setProviderValues = useCallback(
    (v: Partial<ProviderValues>) => {
      setFormProviderData((s) => ({ ...s, ...v }));
    },
    [setFormProviderData],
  );

  /**
   * There are a few internal processes that occur when a form is submitted.
   *  1. The form uploads any files that have been added to the form.
   *  2. The form parses any sub-entries that have been added and returns them as a private property _subEntries.
   *     TODO: Ideally this would be a different return object (val, subVales, helpers)
   *
   */
  const onSubmitHandler = useCallback(
    async (_values: Values, formikHelpers: FormikHelpers<Values>) => {
      try {
        if (!storagePath && Object.keys(formProviderData.files).length > 0) {
          // eslint-disable-next-line no-console
          console.warn(
            "Form used with file upload but form storage path was not provided.",
          );
        }

        toggleBlocking(false);

        if (storagePath && Object.keys(formProviderData.files).length > 0) {
          toggleUploadProgress(true)();

          setUploadProgress(generateProcessObject(formProviderData.files));

          await uploadFiles(
            formProviderData.files,
            storageService.ref(storageService.storage(), storagePath),
            function processHandler(process, _snapshot, path, _file) {
              setUploadProgress((s) => ({
                ...(s || {}),
                [path]: { process, path },
              }));
            },
            function completeHandler(snapshot, path, file) {
              setUploadProgress((s) => ({
                ...(s || {}),
                [path]: { process: 100, path },
              }));
              set<FormFile>(_values, path.split("."), {
                metadata: {
                  ...(snapshot.metadata || {}),
                  ...(snapshot.task.snapshot.metadata || {}),
                  contentType: snapshot.metadata.contentType,
                },
                fileUUID: file.fileUUID,
                ref: snapshot.ref.toString(),
              });
            },
          );

          setUploadProgress(undefined);
        }

        // this sequence is important. THe above storagePath block mutates the _values data with the uploaded file reference
        // letting that happen before pulling out the subEntry data means the file references are saved correctly
        const { __forms, __formPaths, ...values } = _values;

        await onSubmit(values as Values, formikHelpers);
        formikHelpers.setFieldValue("__forms", undefined, false);
        formikHelpers.setFieldValue("__formPaths", undefined, false);
      } finally {
        toggleUploadProgress(false)();
        toggleBlocking(true);
      }
    },
    [
      formProviderData.files,
      onSubmit,
      storagePath,
      toggleBlocking,
      toggleUploadProgress,
    ],
  );

  const internalDisabled = Boolean(disabled || fileUploadInProgress);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: "300px",
        flexWrap: "wrap",
      }}
    >
      <Formik
        onSubmit={onSubmitHandler}
        initialValues={initialValues}
        validationSchema={validationSchema}
        {...rest}
      >
        {({ handleSubmit, setFieldValue }) => (
          <FormProvider
            formId={id}
            onChanges={onChanges}
            initialValues={initialValues}
            formDefinition={formDefinition}
            setProviderValues={setProviderValues}
          >
            <FormikForm
              id={id}
              noValidate
              autoComplete="off"
              style={style}
              onSubmit={(ev) => {
                /* eslint-disable-next-line */
                  const submitter = (ev.nativeEvent as any)?.submitter;
                if (submitter && submitter.name && submitter.value) {
                  setFieldValue(`_${submitter.name}`, submitter.value, false);
                }

                handleSubmit(ev);
              }}
              aria-disabled={internalDisabled}
              className={clsx("formik-form", className)}
            >
              <UploadTracker uploadProgress={uploadProgress} />
              <FormikPatchTouched showErrors={rest.validateOnMount} />
              {onChange && <FormikOnChange<Values> onChange={onChange} />}
              {setFormMetadata && (
                <BubbleUpFormMetadata setFormMetadata={setFormMetadata} />
              )}
              {setState && <BubbleUpFormValues setState={setState} />}
              {blocking && blockRouting && <FormikBlockRouting />}
              <RootFormErrors />

              {!props.renderAfter && children && children}

              <RenderFormDefinition
                path={formPath!}
                slots={slots}
                readonly={readonly}
                disabled={internalDisabled}
                formDefinition={formDefinition}
              />

              {props.renderAfter && children && children}

              {submitButtonText && (
                <Button
                  type="submit"
                  variant="outlined"
                  disabled={internalDisabled}
                >
                  {submitButtonText}
                </Button>
              )}
            </FormikForm>
          </FormProvider>
        )}
      </Formik>
    </Box>
  );
}
