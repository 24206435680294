// libraries
import { getDownloadURL, ref as stgRef, StorageError } from "firebase/storage";

// service
import { storage } from "../services/firebase.service";

import { useQuery } from "@tanstack/react-query";

// constants

export function useUserIconUrl(
  userId?: string,
  size: "40" | "200" = "40",
): string | undefined {
  const {
    data: url,
    isLoading,
    error,
    ...rest
  } = useQuery<string, StorageError | undefined | null>({
    queryKey: [`/users/${userId}/${size}_${size}_${userId}_${1}`],
    queryFn() {
      return getDownloadURL(
        stgRef(storage, `/users/${userId}/${size}_${size}_${userId}_${1}`),
      );
    },
  });

  return url;
}

export function useStorageUrl(
  ref?: string,
): [string | undefined, boolean, StorageError | undefined | null] {
  const {
    data: url,
    isLoading,
    error,
    ...rest
  } = useQuery<string, StorageError | undefined | null>({
    queryKey: [ref],
    queryFn() {
      return getDownloadURL(stgRef(storage, ref));
    },
  });

  return [url, isLoading, error];
}
