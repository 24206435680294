// MUI
import { CircularProgress, Tooltip } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";

// utilities
import { useStorageUrl } from "../../hooks/useStorageUrl";
import { imgRefSize, isFormFile } from "../../services/file";

// types
import { IFileReference } from "@iluvatar/global/src/typings";
import type { FormFile } from "../../services/file";

export function ImageThumbnail({ file }: { file: FormFile | IFileReference }) {
  const [fileUrl, loading, error] = useStorageUrl(imgRefSize(file, 300));
  const imageUrl = isFormFile(file) ? URL.createObjectURL(file?.file) : fileUrl;

  if (loading) {
    return <CircularProgress size={24} />;
  }
  if (error) {
    return (
      <Tooltip title={error.code}>
        <ErrorOutline
          sx={{
            fontSize: "26px",
            color: "var(--color-chart--overdue--dark)",
          }}
        />
      </Tooltip>
    );
  }

  return <img className="image-thumb" src={imageUrl} />;
}

export function PrintImageThumbnail({
  file,
}: {
  file: FormFile | IFileReference;
}) {
  const [fileUrl, loading, error] = useStorageUrl(imgRefSize(file, 300));
  const imageUrl = isFormFile(file) ? URL.createObjectURL(file?.file) : fileUrl;

  if (loading) {
    return <CircularProgress size={24} />;
  }
  if (error) {
    return (
      <Tooltip title={error.code}>
        <ErrorOutline
          sx={{
            fontSize: "26px",
            color: "var(--color-chart--overdue--dark)",
          }}
        />
      </Tooltip>
    );
  }

  return <img className="image-thumb print" src={imageUrl} />;
}
