// libraries
import { clsx } from "clsx";
import { useField } from "formik";
import { useState, useCallback, useMemo, useEffect, memo } from "react";

// MUI
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import InputLabel, { InputLabelProps } from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// context
import { useFormContext } from "../../../../contexts/form.context";

// utilities
import { isString } from "@iluvatar/global/src/utilities";

// components
import { fieldValidator } from "..";

// types
import { IFieldBase } from "@iluvatar/global/src/typings";

interface TextFieldInterface extends IFieldBase {
  className?: string;
  showMaxLength?: number;
  outlinedInputProps?: Partial<OutlinedInputProps>;
  formControlProps?: FormControlProps;
  inputLabelProps?: InputLabelProps;
}

export const TextField: React.FC<TextFieldInterface> = ({
  className,
  name,
  label,
  disabled,
  readonly,
  description,
  defaultValue,
  required,
  autoFocus,
  showMaxLength,
  hidden,
  max,
  labelPlacement = "inline",
  inputLabelProps,
  formControlProps,
  outlinedInputProps,
}) => {
  const { onFieldChange } = useFormContext();

  const [fieldProps, meta, helpers] = useField({
    name,
    validate: fieldValidator(required),
  });
  const { value, onChange, onBlur } = fieldProps;
  const { touched, error } = meta;

  useEffect(() => {
    if (!value && defaultValue) {
      helpers.setValue(defaultValue);
      onFieldChange(name, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Derived Properties
   */
  const changeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e);
      onFieldChange(name, e.currentTarget.value);
    },
    [onChange, onFieldChange, name],
  );

  const labelAbove = labelPlacement === "above";
  const labelInline = labelPlacement === "inline";

  // fixes display bug with required star overlapping border
  const fixedLabel = required ? label + "*" : label;

  // || "" so so this is a controlled field
  const fixedValue = value || "";

  /*
   * this is a naive string length approach, emoji and unicode characters may be
   * considered a single character but count for multiple length values.
   */
  const charCount =
    max && value && showMaxLength !== undefined && value.length >= showMaxLength
      ? `${value.length}/${max}`
      : null;

  const hasError = Boolean(touched && error);
  const note = hasError ? error : description;

  if (hidden) {
    return <></>;
  }

  return (
    <FormControl
      variant="outlined"
      className={clsx({ readonly }, className)}
      {...formControlProps}
      hiddenLabel={labelAbove}
      disabled={disabled || readonly}
      required={required}
      error={hasError}
    >
      {labelAbove && <div className="label-above">{fixedLabel}</div>}
      {labelInline && <InputLabel {...inputLabelProps}>{label}</InputLabel>}
      <OutlinedInput
        {...outlinedInputProps}
        name={name}
        id={name}
        onChange={changeHandler}
        autoFocus={autoFocus}
        onBlur={onBlur}
        label={labelAbove ? undefined : fixedLabel}
        value={fixedValue}
        inputProps={{
          ...(outlinedInputProps?.inputProps || { enterKeyHint: "next" }),
          maxLength: max,
        }}
      />
      {Boolean(charCount || note) && (
        <FormHelperText
          id={`${name}-help-text`}
          className="flex"
          data-error={error}
          error={hasError}
        >
          {note}
          {charCount && <span className="flex-spacer" />}
          {charCount}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export const MemoTextField = memo(TextField);
export const TextArea: React.FC<TextFieldInterface> = (props) => {
  // memoize properties that require objects so we don't create unnecessary renders
  const outlinedInputProps = useMemo(
    () => ({
      multiline: true,
      rows: 4,
      inputProps: { enterKeyHint: "enter" },
    }),
    [],
  );
  return <MemoTextField {...props} outlinedInputProps={outlinedInputProps} />;
};

const handleMouseDownPassword = (
  event: React.MouseEvent<HTMLButtonElement>,
) => {
  event.preventDefault();
};

export const PasswordField: React.FC<TextFieldInterface> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword, setShowPassword]);

  // memoize properties that require objects so we don't create unnecessary renders
  const outlinedInputProps = useMemo(
    () => ({
      type: showPassword ? "text" : "password",
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      ),
    }),
    [showPassword, handleClickShowPassword],
  );

  return <MemoTextField {...props} outlinedInputProps={outlinedInputProps} />;
};

/*
 * Phone field is used to capture numbers in the following form; eg., +1234567890, +1234567890x123.
 * This is a string, with the first character being a `+`, the next 10 being numerical,
 * then an optional extension (eg., x123) thats an `x` followed by numerical values
 */
// TODO: extend this to work with non +1 country codes
export const PhoneField: React.FC<TextFieldInterface> = ({
  className,
  name,
  label,
  disabled,
  readonly,
  description,
  defaultValue,
  required,
  showMaxLength,
  max,
  labelPlacement = "inline",
  inputLabelProps,
  formControlProps,
  outlinedInputProps,
}) => {
  const xPosition = 12;

  const [fieldProps, meta, helpers] = useField({
    name,
    validate: fieldValidator(required),
  });
  const { value, onChange, onBlur } = fieldProps;
  const { touched, error } = meta;

  useEffect(() => {
    !value && defaultValue && helpers.setValue(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fixes display bug with required star overlapping border
  const fixedLabel = required ? label + "*" : label;
  const labelAbove = labelPlacement === "above";
  const labelInline = labelPlacement === "inline";

  /*
   * this is a naive string length approach, emoji and unicode characters may be
   * considered a single character but count for multiple length values.
   */
  const charCount =
    max && value && showMaxLength !== undefined && value.length >= showMaxLength
      ? `${value.length}/${max}`
      : null;

  const hasError = Boolean(touched && error);
  const note = hasError ? error : description;

  const changeHandler = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      // make sure first character is +
      let currentValue = event.currentTarget.value;
      currentValue =
        currentValue[0] === "+" ? currentValue : "+" + currentValue;
      let newValue = "+";
      // next 10 digits must be a number
      for (let i = 1; i < currentValue.length; i++) {
        const character = currentValue.charAt(i);
        const notNumber = isNaN(Number.parseInt(character));
        const notX = character !== "x";

        // filter all non number an x values
        if (notNumber && notX) {
          continue;
        }

        if (i !== currentValue.length - 1 && i !== xPosition && !notX) {
          continue;
        }
        if (i === xPosition && notX) {
          newValue = `${newValue}x${character}`;
        } else {
          newValue = `${newValue}${character}`;
        }
      }
      // if the only character is + set te empty string
      if (newValue.length === 1) {
        newValue = "";
      }
      event.currentTarget.value = newValue;
      // then optional extension
      onChange && onChange(event);
    },
    [onChange],
  );

  // don't display the + in the input since this component renders it
  const displayValue = isString(value)
    ? value.replaceAll("+", "")
    : value || "";

  return (
    <FormControl
      variant="outlined"
      className={clsx({ readonly }, className)}
      {...formControlProps}
      disabled={disabled || readonly}
      required={required}
      error={hasError}
    >
      {labelAbove && <div className="label-above">{fixedLabel}</div>}
      {labelInline && <InputLabel {...inputLabelProps}>{label}</InputLabel>}
      <OutlinedInput
        {...outlinedInputProps}
        name={name}
        id={name}
        onChange={changeHandler}
        onBlur={onBlur}
        label={labelInline ? fixedLabel : undefined}
        value={displayValue}
        startAdornment="+"
        inputMode="decimal"
        inputProps={{ maxLength: max, enterKeyHint: "next", type: "text" }}
      />
      {Boolean(charCount || note) && (
        <FormHelperText
          id={`${name}-help-text`}
          className="flex"
          data-error={hasError}
          error={hasError}
        >
          {note}
          {charCount && <span className="flex-spacer" />}
          {charCount}
        </FormHelperText>
      )}
    </FormControl>
  );
};
