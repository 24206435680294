// libraries
import { useHideOffscreen } from "../hooks/useHideOffscreen";

export const RenderWhenInView: React.FC<
  React.PropsWithChildren<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > & {
      dynamic?: boolean;
      disabled?: boolean;
      name?: string;
      height?: number;
    }
  >
> = ({ dynamic, name, disabled, children, height: _height, ...props }) => {
  const { visible, height, ref } = useHideOffscreen({ dynamic });

  if (disabled) {
    return <>{children}</>;
  }

  return (
    <div
      {...props}
      ref={ref}
      style={{ ...(props.style || {}), minHeight: height || _height }}
    >
      {visible && children}
    </div>
  );
};
