// flags
import { FLAGS_MAP } from "../constants/feature-flags.maps";

// services
export { defaultConfig } from "./default";

// types
import { RemoteConfigType } from "./types";

export type { RemoteConfigType } from "./types";

export function deriveGlobalAvailableFeatureFlags(config: RemoteConfigType) {
  return (
    Object.keys(config)
      // eslint-disable-next-line
    .filter((key) => !!(FLAGS_MAP as any)[key])
      .filter((key) => !!config[key as keyof RemoteConfigType])
      // eslint-disable-next-line
    .reduce<number>((cur, key) => (FLAGS_MAP as any)[key].bitFlag | cur, 0)
  );
}

export function parseRemoteFetch(
  defaultConfig: RemoteConfigType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remote: Record<string, any>,
): RemoteConfigType {
  return Object.entries(remote).reduce(
    (conf, [key, val]) => {
      switch (typeof defaultConfig[key as keyof typeof defaultConfig]) {
        case "boolean":
          conf[key] = val.asBoolean();
          break;
        case "number":
          conf[key] = val.asNumber();
          break;
        default:
          conf[key] = val.asString();
      }
      return conf;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { ...defaultConfig } as any,
  ) as RemoteConfigType;
}
