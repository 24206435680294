// libraries
import { useCallback, useEffect } from "react";
import { useField } from "formik";

// context
import { useFormContext } from "../../../../contexts/form.context";

// MUI
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";

// types
import { IFieldRange } from "@iluvatar/global/src/typings";
import { Slider, SliderProps } from "@mui/material";

interface RangeFieldInterface
  extends Omit<IFieldRange, "fieldType" | "defaultValue"> {
  defaultValue?: number | [number, number];
  formControlProps?: Partial<FormControlProps>;
  formControlLabelProps?: Omit<Partial<FormControlLabelProps>, "control">;
  sliderProps?: Partial<SliderProps>;
  className?: string;
}
const minDistance = 1;
export const RangeField: React.FC<RangeFieldInterface> = ({
  className,
  name,
  label,
  disabled,
  required,
  readonly,
  defaultValue,
  description,
  supremum,
  infimum,
  formControlLabelProps = {},
  formControlProps = {},
  sliderProps = {},
}) => {
  const { onFieldChange } = useFormContext();

  const [{ ...field }, { touched, error }, { setValue }] = useField(name);

  useEffect(() => {
    if (field.value === undefined && defaultValue !== undefined) {
      setValue(defaultValue);
      onFieldChange(name, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      _setValue(newValue);
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        _setValue([clamped, clamped + minDistance]);
        return;
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        _setValue([clamped - minDistance, clamped]);
        return;
      }
    } else {
      _setValue(newValue as [number, number]);
      return;
    }
  };

  /**
   * Derived Properties
   */
  const _setValue = useCallback(
    (value: number | [number, number]) => {
      setValue(value);
      onFieldChange(name, value);
    },
    [setValue, onFieldChange, name],
  );

  const hasError = Boolean(touched && error);
  const note = hasError ? error : description;
  const fixedLabel = required ? label + "*" : label;

  return (
    <FormControl
      {...formControlProps}
      error={hasError}
      disabled={disabled || readonly}
      required={required}
      className={className}
    >
      <FormControlLabel
        labelPlacement="top"
        {...formControlLabelProps}
        label={fixedLabel}
        control={
          <Slider
            size="small"
            color="primary"
            {...field}
            {...sliderProps}
            marks={(Array.isArray(field.value || defaultValue)
              ? field.value || defaultValue
              : [field.value || defaultValue]
            ).map((v: number) => ({
              label: v,
              value: v,
            }))}
            disableSwap
            min={infimum}
            max={supremum}
            value={field.value || defaultValue}
            onChange={handleChange}
            getAriaLabel={() => label}
            getAriaValueText={() => label}
          />
        }
      />
      {Boolean(note) && (
        <FormHelperText error={hasError}>{note}</FormHelperText>
      )}
    </FormControl>
  );
};
