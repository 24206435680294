/* eslint-disable @typescript-eslint/no-unused-vars */
import { IPermission } from "../constants/permission.maps";

export type TFeatureFlag = IPermission;

// const _ALL = ~0;
// const _DOCUMENTS = 0x1;
// const _JHA = 0x2;
// const _CORRECTIVE_ACTIONS = 0x4;
// const _FORMS = 0x8;
// const _STANDARDS = 0x10;
// const _CONTRACTOR_MANAGEMENT = 0x20;
// const _SITE_MANAGEMENT = 0x40;
// const _PEOPLE_MANAGEMENT = 0x80;
// const _DIVISION_MANAGEMENT = 0x100;
// const _TO_DO_LIST = 0x200;
const _PUBLIC_REGISTRATION = 0x400;
// const _REPORTS = 0x800;
// const _TRAINING_CERT = 0x1000;
// const _SYSTEM_EXPORT = 0x2000;
// const _DEV_ONLY = 0x4000;
// const _TASKS = 0x8000;
// const _NOTIFICATIONS = 0x10000;
// const _DOCUMENTS_OFFLINE = 0x20000;
// const _FORMS_OFFLINE = 0x40000;
// const _L = 0x80000;
// const _M = 0x100000;
// const _N = 0x200000;
// const _O = 0x400000;
// const _P = 0x800000;
// const _Q = 0x1000000;
// const _R = 0x2000000;
// const _S = 0x4000000;
// const _T = 0x8000000;
// const _U = 0x10000000;
// const _V = 0x20000000;
// const _W = 0x40000000;

// eslint-disable-next-line no-shadow
export enum FEATURE_FLAGS {
  "PUBLIC_REGISTRATION" = "PUBLIC_REGISTRATION",
}

// We currently stay within 31 bits so we don't have to chain floats
export const PUBLIC_REGISTRATION: TFeatureFlag = {
  bitFlag: _PUBLIC_REGISTRATION,
  name: FEATURE_FLAGS.PUBLIC_REGISTRATION,
};

export const FLAGS_MAP: Record<FEATURE_FLAGS, TFeatureFlag> = {
  PUBLIC_REGISTRATION,
};

// features that are available te all customers
// FOR NOW ALL FLAGS TO USER DEFAULT
export const defaultEnabledFeatures: TFeatureFlag[] = [PUBLIC_REGISTRATION];

export const featureIsEnabled = (
  enabledFlags: number,
  testFlag: number,
): boolean => {
  return (enabledFlags & testFlag) === testFlag;
};
