import { Paths } from "../typings/ts-utils";
import { isObject, traverseObjectPath } from "./object";

export const insertArray = (
  values: string[] | string = [],
  arr: string[],
  afterIndex?: number,
): string[] => {
  const toInsert = typeof values === "string" ? [values] : values;
  if (!afterIndex) {
    return [...toInsert, ...arr];
  }
  const start = arr.slice(undefined, afterIndex);
  const end = arr.slice(afterIndex);
  return [...start, ...toInsert, ...end];
};

export function splitArray<T>(arr: T[], binSize: number): T[][] {
  const res: T[][] = [[]];
  const arrC: T[] = [...arr];
  let idx = 0;
  while (res[idx].length < binSize && arrC.length > 0) {
    res[idx].push(arrC.shift() as T);
    if (res[idx].length === binSize && arrC.length) {
      idx++;
      res.push([]);
    }
  }
  return res;
}

// TODO: Add Unit Tests
export function sortObj<T = unknown>(
  path: Paths<T, 4>,
  order: "asc" | "desc" = "asc",
) {
  return function sortHandler(a: T, b: T) {
    if (!path) {
      return 1;
    }
    const aVal = isObject(a) ? traverseObjectPath(path, a, true) : null;
    const bVal = isObject(b) ? traverseObjectPath(path, b, true) : null;
    if (typeof aVal === "number" && typeof bVal === "number") {
      return order ? aVal - bVal : bVal - aVal;
    }

    return order === "asc"
      ? String(aVal).localeCompare(String(bVal))
      : String(bVal).localeCompare(String(aVal));
  };
}

type Ent = [string | number, unknown];
export function entriesToValues(
  func: Exclude<Parameters<typeof Array.prototype.sort>["0"], undefined>,
) {
  return function innerTransfer([, val]: Ent, [, val2]: Ent) {
    return func(val, val2);
  };
}
