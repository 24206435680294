/* eslint-disable @typescript-eslint/no-unused-vars */
// libraries
import { FieldValidator } from "formik";
import { memo, useMemo } from "react";
import * as Yup from "yup";

// hooks
import { hasConditionalVisible } from "../utilities";
import { useFormConditionals } from "../useFormConditionals";

// Components
import {
  TextArea,
  TextField,
  PasswordField,
  PhoneField,
  MemoTextField,
} from "./Input";
import { MultiSelectField, SelectField } from "./Select";
import { GeolocationCheckboxField } from "./Checkbox/GeolocationCheckbox";
import { AutocompleteField } from "./Autocomplete";
import { HorizontalDivider } from "../Elements/Divider";
import { InspectionQuestion } from "./InspectionQuestion";
import { FileSelectField } from "./File";
import { CheckboxField } from "./Checkbox";
import { TimezoneField } from "./Timezone";
import { NumberField } from "./Number";
import { ToggleField } from "./Toggle";
import { RadioField } from "./Radio";
import { ColorField } from "./Color";
import { EmailField } from "./Email";
import { RangeField } from "./Range";
import { Header } from "../Elements/Header";
import { Text } from "../Elements/Text";
import DatePicker from "./Date";
import TimePicker from "./Time";

// types
import {
  IElement,
  IField,
  isField,
  TFieldValue,
} from "@iluvatar/global/src/typings";

export const FormFieldOrElement: React.FC<
  (IElement | IField) & {
    className?: string;
    readonly?: boolean;
    disabled?: boolean;
  }
> = memo(function FormFieldOrElement(props) {
  if (isField(props)) {
    return <FormFieldComponent {...props} />;
  }
  return <FormElementComponent {...props} />;
});

export const FormFieldComponent: React.FC<
  IField & {
    className?: string;
    style?: React.CSSProperties;
  }
> = (props) => {
  const { fieldType, ...fieldProps } = props;
  // memoize component properties that are objects, saves on unnecessary rerenders
  const selectProp = useMemo(
    () => ({
      multiple: props.max && props.max > 1 ? true : false,
    }),
    [props.max],
  );

  const conditionalRender = useFormConditionals(
    fieldProps.name,
    fieldProps.conditions,
  );

  if (conditionalRender.includes("readonly")) {
    fieldProps.readonly = true;
  }

  if (conditionalRender.includes("disabled")) {
    fieldProps.disabled = true;
  }

  const internalHidden =
    conditionalRender.includes("hidden") || fieldProps.hidden;

  const notVisible =
    hasConditionalVisible(fieldProps.conditions || []) &&
    !conditionalRender.includes("visible") &&
    !conditionalRender.includes("readonly");

  if (internalHidden || notVisible) {
    return <></>;
  }

  // If field is not editable it should not be required
  if (fieldProps.readonly || fieldProps.disabled) {
    fieldProps.required = false;
  }

  switch (props.fieldType) {
    case "fileSelect": {
      return <FileSelectField {...fieldProps} />;
    }
    case "imageSelect": {
      return (
        <FileSelectField
          {...fieldProps}
          actionBarText="Click to Attach Image"
          accept="image/*"
        />
      );
    }
    case "textField": {
      return <MemoTextField {...fieldProps} />;
    }
    case "textArea": {
      return <TextArea showMaxLength={1} {...fieldProps} />;
    }
    case "password": {
      return <PasswordField {...fieldProps} />;
    }
    case "emailField": {
      return <EmailField {...fieldProps} />;
    }
    case "dateField": {
      return <DatePicker {...fieldProps} />;
    }
    case "timeField": {
      return <TimePicker {...fieldProps} />;
    }
    case "numberField": {
      return <NumberField {...fieldProps} />;
    }
    case "select": {
      if (props.multiple) {
        return (
          <MultiSelectField
            {...fieldProps}
            defaultValue={(props.defaultValue as TFieldValue[]) || []}
            selectProps={selectProp}
            options={props.options}
          />
        );
      }
      return (
        <SelectField
          {...fieldProps}
          defaultValue={props.defaultValue as TFieldValue}
          selectProps={selectProp}
          options={props.options}
        />
      );
    }
    case "radio": {
      return <RadioField {...fieldProps} options={props.options} />;
    }
    case "rangeField": {
      return (
        <RangeField
          {...fieldProps}
          defaultValue={fieldProps.defaultValue as [number, number]}
          supremum={props.supremum}
          infimum={props.infimum}
        />
      );
    }
    case "toggle": {
      return <ToggleField {...fieldProps} />;
    }
    case "checkbox": {
      return <CheckboxField {...fieldProps} />;
    }
    case "geolocationCheckField": {
      return <GeolocationCheckboxField {...fieldProps} />;
    }
    case "phoneField": {
      return <PhoneField {...fieldProps} />;
    }
    case "autocomplete": {
      if (props.multiple) {
        return (
          <AutocompleteField
            {...fieldProps}
            options={props.options}
            multiple={true}
          />
        );
      }
      return <AutocompleteField {...fieldProps} options={props.options} />;
    }
    case "timezone": {
      return <TimezoneField {...fieldProps} />;
    }
    case "colorField": {
      return <ColorField {...fieldProps} />;
    }
    case "inspectionQuestion": {
      return <InspectionQuestion {...fieldProps} options={props.options} />;
    }
    default: {
      return <TextField {...fieldProps} />;
    }
  }
};

export const FormElementComponent: React.FC<
  IElement & {
    className?: string;
  }
> = (props) => {
  const { fieldType, ...fieldProps } = props;
  // memoize component properties that are objects, saves on unnecessary rerenders

  const conditionalRender = useFormConditionals(
    fieldProps.name,
    fieldProps.conditions,
  );

  const internalHidden =
    conditionalRender.includes("hidden") ||
    conditionalRender.includes("hidden") ||
    fieldProps.hidden;

  const notVisible =
    hasConditionalVisible(fieldProps.conditions || []) &&
    !conditionalRender.includes("visible");

  if (internalHidden || notVisible) {
    return <></>;
  }

  switch (props.fieldType) {
    case "text": {
      return <Text {...fieldProps} />;
    }
    case "header": {
      return <Header {...fieldProps} />;
    }
    case "divider": {
      return <HorizontalDivider {...fieldProps} />;
    }
    default: {
      return <></>;
    }
  }
};

export const fieldValidator =
  (required: boolean | undefined): FieldValidator =>
  (value: unknown) => {
    if (required && !value) {
      return "Field is required";
    }
  };

export const emailValidator =
  (required: boolean | undefined): FieldValidator =>
  async (value: unknown) => {
    if (value) {
      const isEmail = await Yup.string().email().isValid(value);
      if (!isEmail) {
        return "You have entered an invalid email address";
      }
    } else if (required) {
      return "Field is required";
    }
  };

export const numberValidator =
  (
    required: boolean | undefined,
    min: number | undefined,
    max: number | undefined,
  ): FieldValidator =>
  async (value: unknown) => {
    if (value) {
      const isNumber = await Yup.number().isValid(value);
      // TODO: Get the yup error message from Yup utilities and instead of "NAN", display the entered user value
      if (!isNumber) {
        return "Input must be a number";
      } else if (min !== undefined && (value as number) < Number(min)) {
        return `Value must be greater than (or equal to) ${min}`;
      } else if (max !== undefined && (value as number) > Number(max)) {
        return `Value must be less than (or equal to) ${max}`;
      }
    } else if (required) {
      return "Field is required";
    }
  };
