import { FileRecord } from "./types";

// types
export type ProgressTracker = Record<
  string,
  { process: number; [s: string]: unknown }
>;

export function generateProcessObject(files: FileRecord): ProgressTracker {
  return Object.entries(files).reduce<ProgressTracker>(
    (fs, [path, fileArray]) => {
      (fileArray || []).forEach((file, key) => {
        if (!file.ref) {
          fs[`${path}.${key}`] = {
            process: 0,
            path: `${path}.${key}`,
          };
        }
      });
      return fs;
    },
    {},
  );
}
