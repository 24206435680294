// libraries
import { clsx } from "clsx";
import { useEffect, useMemo } from "react";

// MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";

import AddCircleIcon from "@mui/icons-material/AddCircle";

// hooks
import {
  useGroupErrors,
  hasConditionalVisible,
  fieldGroupOrdering,
} from "../utilities";
import { useFormConditionals } from "../useFormConditionals";

// components
import { Section } from "../FormSection";
import { Accordion } from "../../Accordion";
import { FormFieldOrElement } from "../Fields/index";

// context
import { useFormContext } from "../../../contexts/form.context";

// types
import {
  IElement,
  IField,
  IFieldGroupBuilt,
} from "@iluvatar/global/src/typings";

export type FormState = { hasChanges: boolean };

export const FormGroupComponent: React.FC<
  IFieldGroupBuilt & {
    sub?: boolean;
    className?: string;
    children?: React.ReactNode;
    parentPath?: string;
    CatFishComponent?: React.ComponentType<
      (IField | IElement) & {
        className?: string | undefined;
        readonly?: boolean | undefined;
        disabled?: boolean | undefined;
      }
    >;
  }
> = (props) => {
  const {
    CatFishComponent,
    sub,
    headless,
    disabled,
    parentPath,
    readonly,
    name,
    min,
    max,
    children,
    className,
    ...group
  } = props;
  const { createGroupInstance, removeGroupInstance, groupInstances } =
    useFormContext();
  const errors = useGroupErrors(name);
  const conditionalRender = useFormConditionals(name, group.conditions);

  const groupKeys = useMemo(
    () => groupInstances[name] || [],
    [groupInstances, name],
  );

  const canRemoveGroup = min ? groupKeys.length > min : true;
  const canAddGroup = Boolean(max && max > groupKeys?.length);

  // don't show tho add button if the min and max are equal
  const showAdd = max !== min && !readonly;

  const internalDisabled = conditionalRender.includes("disabled") || disabled;

  const internalReadonly = conditionalRender.includes("readonly") || readonly;

  const internalHidden = conditionalRender.includes("hidden") || group.hidden;

  const notVisible =
    hasConditionalVisible(group.conditions || []) &&
    !conditionalRender.includes("visible") &&
    !conditionalRender.includes("readonly");

  const showGroupCount = groupKeys.length > 1;

  // This is needed for when group with subgroups are added
  useEffect(() => {
    if (min && min > groupKeys.length) {
      createGroupInstance(name, min - groupKeys.length)();
    }
  }, [name, min, createGroupInstance, groupKeys]);

  if (internalHidden || notVisible) {
    return <></>;
  }

  return (
    <div
      className={clsx("form-group", className, { headless })}
      {...{ ["data-form-path"]: name }}
    >
      {errors && (
        <div className="form-header top">
          <FormHelperText error={true}>{errors.toString()}</FormHelperText>
        </div>
      )}
      {/* render all instances of the group */}
      {groupKeys?.map((key) => (
        <Accordion
          sub={sub}
          headless={headless}
          title={`${group.label} ${showGroupCount ? "-" + (1 + key) : ""}`}
          subTitle={parentPath}
          key={key.toString()}
          className={clsx("group-instance form-group form-fields", {
            removable: canRemoveGroup,
          })}
          remove={!internalReadonly && canRemoveGroup}
          onRemove={
            !internalReadonly
              ? removeGroupInstance(name, key.toString())
              : undefined
          }
        >
          {group.description && !headless && (
            <Typography variant="body2" sx={{ mb: 2, width: "100%" }}>
              <>{group.description}</>
            </Typography>
          )}
          {children && children}
          {fieldGroupOrdering<IFieldGroupBuilt, IField>(props).map((order) => {
            if (order.type === "field") {
              const field = order.entry;
              return CatFishComponent !== undefined ? (
                <CatFishComponent
                  {...field}
                  key={`${name}.${key}.${field.name}`}
                  name={`${name}.${key}.${field.name}`}
                  disabled={field.disabled || internalDisabled}
                  readonly={field.readonly || internalReadonly}
                  className={"flex-" + (field.span || 1) + "-cols form-field"}
                />
              ) : (
                <FormFieldOrElement
                  {...field}
                  key={`${name}.${key}.${field.name}`}
                  name={`${name}.${key}.${field.name}`}
                  disabled={field.disabled || internalDisabled}
                  readonly={field.readonly || internalReadonly}
                  className={"flex-" + (field.span || 1) + "-cols form-field"}
                />
              );
            } else if (order.entry.isSection) {
              return (
                <Section
                  {...order.entry}
                  key={`${name}.${key}.${order.entry.name}`}
                  sectionKey={`${name}.${key}.${order.entry.name}`}
                  parentPath={`${parentPath ? parentPath + "/" : ""}${
                    order.entry.label
                  }`}
                  readonly={order.entry.readonly || internalReadonly}
                  disabled={order.entry.disabled || internalDisabled}
                />
              );
            } else {
              return (
                <FormGroupComponent
                  sub
                  {...order.entry}
                  parentPath={`${parentPath ? parentPath + "/" : ""}${
                    order.entry.label
                  }`}
                  key={`${name}.${key}.${order.entry.name}`}
                  name={`${name}.${key}.${order.entry.name}`}
                  disabled={order.entry.disabled || internalDisabled}
                  readonly={order.entry.readonly || internalReadonly}
                />
              );
            }
          })}
        </Accordion>
      ))}
      {showAdd && canAddGroup && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            cursor: "pointer",
            alignItems: "center",
            mr: 4,
          }}
          onClick={canAddGroup && createGroupInstance(name)}
        >
          <Typography variant="h3" sx={{ mr: 1 }}>
            Add {group.label}
          </Typography>
          <AddCircleIcon color="secondary" fontSize="large" />
        </Box>
      )}
    </div>
  );
};
