// Libraries
import { clsx } from "clsx";

// MUI
import Typography from "@mui/material/Typography";

// components
import { HtmlHTMLAttributes, forwardRef } from "react";

// styles
import "./card.scss";

type CardProps = {
  title?: string;
  icon?: React.ReactElement;
  children?: React.ReactNode;
} & HtmlHTMLAttributes<HTMLDivElement>;

const Card = forwardRef<HTMLDivElement, CardProps>(function CardComponent(
  { children, title, icon, ...rest },
  forwardedRef,
) {
  return (
    <div {...rest} className={clsx(rest.className, "card")} ref={forwardedRef}>
      {icon}
      {title && <Typography variant="h2">{title}</Typography>}
      {title && <div className="flex flex-1" />}
      {children}
    </div>
  );
});

export default Card;
