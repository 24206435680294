import { useSwipeable } from "react-swipeable";
import { useState, useCallback, useEffect } from "react";

import { FileRoute } from "@tanstack/react-router";

import {
  Alert,
  Badge,
  Button,
  IconButton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
  Grade,
  Remove,
  Tune,
} from "@mui/icons-material";

// hooks
import { useToggle } from "@/hooks/useToggle";
import { useSearchQuery } from "@/hooks/useSearchQuery";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useLocalStorage } from "@/hooks/useLocalStorage";

// components
import DefaultLayout from "@/layout/default-page";
import { FlexSpacer } from "@/components/FlexSpacer";
import { ViewProfile } from "@/components/ViewProfile";
import { Form, FormState } from "@/components/Form";

import { IFieldTypes } from "@iluvatar/global/src/typings";
import {
  genderOptions,
  locationOptions,
  sexualityOptions,
} from "@iluvatar/global/src/constants";

type SearchSettings = {
  age: [number, number];
  gender: string[];
  sexuality: string[];
  locationId?: string;
  geolocation?: { lat: number; long: number };
  geoDist: number;
  geoUnits: string;
};

const _profile = "default";
function HomePage() {
  const { user, api } = useCurrentUser();
  const [viewIdx, setViewIdx] = useState(0);
  const [searchDrawerOpen, toggleSearchDrawer] = useToggle(false);
  const [searchFormMeta, setSearchForm] = useState<FormState>({
    hasChanges: false,
  });
  const [searchSettings, setSearchSettings] = useLocalStorage<SearchSettings>(
    "search-storage",
    { age: [18, 99], gender: [], sexuality: [], geoDist: 42, geoUnits: "km" },
  );

  const [values, errors, loadMore, removeUser] = useSearchQuery({
    communityId: "default",
    age: searchSettings.age,
    gender: searchSettings.gender,
    sexuality: searchSettings.sexuality,
    locationId: searchSettings.locationId,
    geo: searchSettings.geolocation
      ? {
          dist: searchSettings.geoDist,
          units: searchSettings.geoUnits,
        }
      : undefined,
  });

  useEffect(() => {
    if ("geolocation" in navigator && user && user.uid) {
      navigator.geolocation.getCurrentPosition(
        async (s) => {
          if (
            user?.geolocation?.lat !== s.coords.latitude ||
            user?.geolocation?.long !== s.coords.longitude
          ) {
            await api.updateUser(user.uid, {
              geolocation: { lat: s.coords.latitude, long: s.coords.longitude },
            });
          }
        },
        (err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        },
        {
          enableHighAccuracy: false,
          timeout: 300000,
          maximumAge: 100000,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid]);

  // check loaded user array for length change and the need to load more
  // if there are no more accounts to load, reduce index

  const maybeLoadMore = useCallback(
    (_idx: number) => {
      if (((values && values?.length - _idx) || 0) <= 3 && loadMore) {
        loadMore(
          (user?.hiddenUsersMap && user?.hiddenUsersMap[_profile]) || [],
        );
      }
    },
    [loadMore, user?.hiddenUsersMap, values],
  );

  const checkForReducingIdx = useCallback(() => {
    setViewIdx((_idx) =>
      values.length - 2 - _idx <= 0 ? Math.max(_idx - 1, 0) : _idx,
    );
  }, [values.length]);

  const prevClick = useCallback(() => {
    setViewIdx((_idx) => (_idx > 0 ? _idx - 1 : _idx));
  }, []);

  const nextClick = useCallback(() => {
    setViewIdx((_idx) => {
      maybeLoadMore(_idx);
      return values && _idx < values?.length - 1 ? _idx + 1 : _idx;
    });
  }, [maybeLoadMore, values]);

  const swipeProps = useSwipeable({
    onSwipedRight: prevClick,
    onSwipedLeft: nextClick,
  });

  const likeUser = useCallback(
    function likeUserHandler(userId: string) {
      return async function innerClickHandler() {
        await api.likeUser(userId);
        removeUser(userId);
        maybeLoadMore(viewIdx);
        checkForReducingIdx();
      };
    },
    [api, checkForReducingIdx, maybeLoadMore, removeUser, viewIdx],
  );

  const hideUser = useCallback(
    function likeUserHandler(userId: string) {
      return async function innerClickHandler() {
        await api.ignoreUser(userId);
        removeUser(userId);
        maybeLoadMore(viewIdx);
        checkForReducingIdx();
      };
    },
    [api, checkForReducingIdx, maybeLoadMore, removeUser, viewIdx],
  );

  return (
    <DefaultLayout
      className="profile-container mb-[76px] pb-[24px]"
      // disable swipe for now
      // {...swipeProps}
    >
      <>
        {errors && (
          <Alert variant="outlined" severity="error" className="my-4">
            {JSON.stringify(errors)}
          </Alert>
        )}
      </>

      {values.length === 0 && <div>No One else.</div>}

      {Boolean(values.length && values[viewIdx]) && (
        <ViewProfile
          userId={values[viewIdx]?.uid}
          profile={values[viewIdx]?.profiles[_profile]}
          about={values[viewIdx]?.about}
        />
      )}

      <div className="w-[100%] min-h-[64px] fixed bottom-0 left-0 bg-[var(--color-primary--active)] px-4 z-100 backdrop-blur">
        <div className="flex mx-auto w-full max-w-screen-md my-4 gap-4 z-100">
          <IconButton
            className={"text-[var(--color-text)]"}
            onClick={toggleSearchDrawer(true)}
          >
            <Tune />
          </IconButton>
          <IconButton
            disabled={!(viewIdx > 0)}
            onClick={prevClick}
            className={"text-[var(--color-text)]"}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            disabled={!(values.length - 1 - viewIdx > 0)}
            onClick={nextClick}
            className={"text-[var(--color-text)]"}
          >
            <ChevronRight />
          </IconButton>

          <FlexSpacer />
          {Boolean(values && values[viewIdx]) && (
            <Button
              sx={{ borderRadius: "12px" }}
              color="info"
              onClick={hideUser(values[viewIdx]?.uid)}
              className="bg-[var(--color-primary--hover)] p-[9px] min-w-0"
            >
              <Remove />
            </Button>
          )}
          {Boolean(values && values[viewIdx]) && (
            <Badge
              color="info"
              overlap="circular"
              badgeContent={0 /* user?.winkCount*/}
              onClick={likeUser(values![viewIdx]?.uid)}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
              slotProps={{
                badge: {
                  style: { zIndex: 1100 },
                },
              }}
            >
              <Button
                className="border-[var(--color-chart--warning)] p-[9px] min-w-0 hover:bg-[var(--color-chart--warning)] hover:border-[var(--color-border)]"
                sx={{
                  borderRadius: "12px",
                }}
              >
                <Grade color="info" />
              </Button>
            </Badge>
          )}
        </div>
      </div>
      <SwipeableDrawer
        disableSwipeToOpen
        open={searchDrawerOpen}
        PaperProps={{ className: "p-8 mt-[-56px]" }}
        onOpen={toggleSearchDrawer(true)}
        onClose={toggleSearchDrawer(false)}
        anchor="bottom"
      >
        <div className="mx-auto w-full max-w-screen-md">
          <Typography variant="h3" className="mb-8 w-100 text-center">
            Filter and Searching
          </Typography>
          <Form
            id="search-settings"
            renderAfter
            enableReinitialize
            initialValues={searchSettings}
            formDefinition={{
              name: "defaultProfile",
              label: "default profile",
              fields: [
                {
                  name: "sexuality",
                  label: "Sexuality",
                  span: 3,
                  description: "Leave blank to include all",
                  fieldType: IFieldTypes.autocomplete,
                  options: sexualityOptions,
                  multiple: true,
                  freeSolo: true,
                },
                {
                  name: "gender",
                  label: "Gender",
                  span: 3,
                  description: "Leave blank to include all",
                  fieldType: IFieldTypes.autocomplete,
                  options: genderOptions,
                  multiple: true,
                  freeSolo: true,
                },
                {
                  name: "age",
                  label: "Age Range",
                  span: 3,
                  supremum: 99,
                  infimum: 18,
                  defaultValue: [18, 99],
                  fieldType: IFieldTypes.rangeField,
                },
                {
                  name: "text",
                  label: "Characteristics Search",
                  span: 2,
                  disabled: true,
                  fieldType: IFieldTypes.textField,
                },
                {
                  name: "geolocation",
                  label: "User Current Location",
                  span: 1,
                  fieldType: IFieldTypes.geolocationCheckField,
                },
                {
                  name: "locationId",
                  label: "Location",
                  span: 3,
                  options: locationOptions,
                  fieldType: IFieldTypes.autocomplete,
                  conditions: [
                    {
                      action: "hidden",
                      value: undefined,
                      operation: "!=",
                      parameter: ".geolocation.lat",
                    },
                  ],
                },
                {
                  fieldType: IFieldTypes.autocomplete,
                  name: "geoUnits",
                  label: "Distance Units",
                  span: 1,
                  options: [
                    { value: "km", label: "km" },
                    { value: "mi", label: "mile" },
                  ],
                  conditions: [
                    {
                      action: "hidden",
                      value: null,
                      operation: "==",
                      parameter: ".geolocation.lat",
                    },
                  ],
                },
                {
                  fieldType: IFieldTypes.rangeField,
                  name: "geoDist",
                  label: "Search Radius",
                  span: 1,
                  infimum: 0,
                  supremum: 500,
                  defaultValue: 42,
                  conditions: [
                    {
                      action: "hidden",
                      value: null,
                      operation: "==",
                      parameter: ".geolocation.lat",
                    },
                  ],
                },
              ],
            }}
            setFormMetadata={setSearchForm}
            onSubmit={async (ev) => {
              if (ev.geolocation && user) {
                await api.updateUser(user?.uid, {
                  geolocation: {
                    lat: ev.geolocation.lat,
                    long: ev.geolocation.long,
                  },
                });
              }
              setSearchSettings(ev);
              toggleSearchDrawer(false)();
            }}
          >
            <Button
              form="search-settings"
              type="submit"
              className="w-[100%] mb-4 mt-4"
              disabled={!searchFormMeta.hasChanges}
            >
              Update Search Settings
            </Button>
            <Button
              color="info"
              className="w-[100%] mb-4"
              variant="outlined"
              onClick={toggleSearchDrawer(false)}
            >
              Cancel
            </Button>
          </Form>
        </div>
      </SwipeableDrawer>
    </DefaultLayout>
  );
}

export const Route = new FileRoute("/").createRoute({
  component: HomePage,
});
