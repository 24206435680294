import { useContext, useEffect, useState } from "react";

import requestService from "@/services/request.service";
import { useAuthenticationContext } from "@/contexts/authentication/authentication.context";

import { getUserPath } from "@iluvatar/global/src/constants";
import { SubscriptionContext } from "@/contexts/subscription/subscriptions.context";
import { SubscriptionStateListener } from "@/contexts/subscription/subscription.types";

import { UserEntity } from "@iluvatar/global/src/typings";
import { DocumentData, DocumentSnapshot } from "@google-cloud/firestore";
import { DeepPartial } from "@iluvatar/global/src/typings/ts-utils";

type HookListenerState<Error> = {
  updatedAt?: number;
  res?: DocumentSnapshot<DocumentData>;
  data: SubscriptionStateListener<UserEntity, Error>;
};

const userApi = {
  updateUser: function (userId: string, user: DeepPartial<UserEntity>) {
    return requestService.request(`/api/user/${userId}`, {
      method: "put",
      body: JSON.stringify({ user }),
    });
  },
  removeImage: function (
    userId: string,
    communityId: string,
    imageRef: string,
  ) {
    return requestService.request(`/api/user/${userId}/image/${imageRef}`, {
      method: "delete",
      body: JSON.stringify({ profileId: communityId }),
    });
  },
  likeUser: function (userId: string, communityId: string = "default") {
    return requestService.request(`/api/user/${userId}/like`, {
      method: "post",
      body: JSON.stringify({ communityId }),
    });
  },
  ignoreUser: function (userId: string, communityId: string = "default") {
    return requestService.request(`/api/user/${userId}/ignore`, {
      method: "post",
      body: JSON.stringify({ communityId }),
    });
  },
  search: function (communityId: string = "default", exclude: string[]) {
    return requestService.request("/api/search", {
      method: "post",
      body: JSON.stringify({ communityId, exclude: exclude }),
    });
  },
};

export function useCurrentUser() {
  const { uid } = useAuthenticationContext();
  const { addFirestoreSnapshot, state } = useContext(SubscriptionContext);

  const [listener, setListener] = useState<HookListenerState<unknown>>({
    updatedAt: undefined,
    data: [undefined, undefined, undefined, undefined],
  });

  //   const unSubFcn = useCallback(
  //     () => removeFirestoreSub(getUserPath(uid || "err")),
  //     [uid, removeFirestoreSub],
  //   );

  const targetState = state[getUserPath(uid || "err")];

  /**
   * If target state is or becomes undefined and/or the path changes
   * - set initial undefined listened state
   * - add firestore document subscription to that path
   */
  useEffect(() => {
    if (uid) {
      // reset listener if path changes and there is no targetState
      setListener({
        updatedAt: undefined,
        data: [undefined, undefined, undefined, undefined],
      });
      addFirestoreSnapshot(getUserPath(uid));
    }
  }, [addFirestoreSnapshot, uid]);

  /**
   * Propagate target state to listener
   */
  useEffect(() => {
    if (targetState && targetState?.updatedAt !== listener.updatedAt) {
      if (targetState.error) {
        // eslint-disable-next-line
          console.warn(targetState);
      }

      setListener({
        updatedAt: targetState?.updatedAt,
        data: [
          targetState?.value as UserEntity,
          targetState?.state,
          targetState?.error,
          undefined,
        ],
      });
    }
  }, [targetState, listener]);

  return {
    user: listener.data[0],
    state: listener.data[1],
    error: listener.data[2],
    api: userApi,
  };
}
