// libraries
import tinycolor from "tinycolor2";

/**
 * lifted from the react-color library
 */
export const isValidHex = (hex: string) => {
  if (hex === "transparent") {
    return true;
  }
  // disable hex4 and hex8
  const lh = String(hex).charAt(0) === "#" ? 1 : 0;
  return (
    hex.length !== 4 + lh && hex.length < 7 + lh && tinycolor(hex).isValid()
  );
};
