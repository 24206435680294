// libraries

import * as z from "zod";
import * as Yup from "yup";
import { FormikHelpers } from "formik";
import { FileRoute, Link, useMatch, useNavigate } from "@tanstack/react-router";
import { AuthError, AuthErrorCodes, confirmPasswordReset } from "firebase/auth";
import { Alert, CircularProgress } from "@mui/material";

import ArrowBack from "@mui/icons-material/ArrowBack";

// MUI
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// services && hooks
import { auth } from "../services/firebase.init";

import { useToggle } from "../hooks/useToggle";
import { useToastContext } from "../contexts/toast.context";

// components
import { Form } from "../components/Form";
import UnAuthLayout from "../layout/unauthed-page";

// constants
import { FORM_GLOBAL_ERROR_KEY } from "@iluvatar/global/src/constants";
import { confirmPassword } from "../components/Form/Validation";

// styles
import "../scss/auth.scss";

// types
import {
  FieldGroupOrderingTypes,
  IFieldTypes,
} from "@iluvatar/global/src/typings";

type PasswordResetFormValue = {
  password: string;
  passwordConfirmation: string;
};

const PASSWORD_RESET_VALIDATOR = Yup.object().shape({
  password: Yup.string().required(),
  passwordConfirmation: Yup.string()
    .required()
    .test(confirmPassword("password")),
});

const PasswordResetPage: React.FC = () => {
  const navigate = useNavigate();
  const match = useMatch({ strict: false });
  const { addToast } = useToastContext();
  const [blocking, toggleBlocking] = useToggle(false);

  const disabled = !match.search?.oobCode;

  const resetPasswordFormSubmission = async (
    { password }: PasswordResetFormValue,
    helpers: FormikHelpers<PasswordResetFormValue>,
  ) => {
    toggleBlocking(true);
    try {
      if (!match.search.oobCode || typeof match.search.oobCode !== "string") {
        helpers.setFieldError(
          FORM_GLOBAL_ERROR_KEY,
          "Password Reset code is missing or invalid",
        );
        toggleBlocking(false);
        return;
      }

      await confirmPasswordReset(auth, match.search.oobCode, password);

      addToast("Password has been reset");
      navigate({ to: "/login" });
    } catch (error) {
      // Error codes can be found here.
      // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#sendpasswordresetemail
      const err = error as AuthError;
      if (
        AuthErrorCodes.EXPIRED_OOB_CODE === err.code ||
        AuthErrorCodes.INVALID_OOB_CODE === err.code ||
        AuthErrorCodes.INVALID_CODE === err.code ||
        AuthErrorCodes.CODE_EXPIRED === err.code
      ) {
        helpers.setFieldError(
          FORM_GLOBAL_ERROR_KEY,
          "Password reset code expired or is invalid, try the email link again, or sent another reset email.",
        );
      } else {
        helpers.setFieldError(FORM_GLOBAL_ERROR_KEY, err.message);
      }
    }
    toggleBlocking(false);
  };

  return (
    <UnAuthLayout>
      <div
        className="auth-page-container"
        style={{ padding: "16px", maxWidth: "450px", margin: "auto" }}
      >
        <Typography variant="h5" color="text.secondary" sx={{ my: 3 }}>
          Set New Password
        </Typography>
        {!match.search?.oobCode && (
          <Alert
            variant="outlined"
            severity="error"
            sx={{ width: "100%", mb: 2, mt: 2 }}
          >
            No reset Code provided
          </Alert>
        )}
        <Form
          blockRouting={blocking}
          disabled={disabled}
          id="reset-password-form"
          className="form-two-column"
          initialValues={{ password: "", passwordConfirmation: "" }}
          validationSchema={PASSWORD_RESET_VALIDATOR}
          onSubmit={resetPasswordFormSubmission}
          formDefinition={{
            name: "password-reset-form",
            label: "Password Reset",
            ordering: [
              {
                type: FieldGroupOrderingTypes.field,
                name: "password",
                orderIdx: 0,
                entryIdx: 0,
              },
              {
                type: FieldGroupOrderingTypes.field,
                name: "passwordConfirmation",
                orderIdx: 1,
                entryIdx: 1,
              },
            ],
            fields: [
              {
                fieldType: IFieldTypes.password,
                span: 4,
                name: "password",
                label: "Password",
              },
              {
                fieldType: IFieldTypes.password,
                span: 4,
                name: "passwordConfirmation",
                label: "Confirm Password",
              },
            ],
          }}
        />
        <Button
          sx={{ mt: 2, width: "100%", minHeight: "48px", mb: 5 }}
          form="reset-password-form"
          variant="outlined"
          type="submit"
          color="primary"
          disabled={blocking || disabled}
          startIcon={
            blocking && (
              <CircularProgress size={14} sx={{ mr: "5px", ml: "1px" }} />
            )
          }
        >
          Reset Password
        </Button>
        <Link
          to="/login"
          className="flex f-c-c"
          style={{ color: "var(--color-text--secondary)" }}
        >
          <ArrowBack sx={{ mr: 1 }} />
          back to Login
        </Link>
      </div>
    </UnAuthLayout>
  );
};

export const Route = new FileRoute("/password-reset-form")
  .createRoute({
    validateSearch: z.object({
      oobCode: z.string(),
    }),
  })
  .update({
    component: PasswordResetPage,
  });
