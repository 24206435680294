// libraries
import { IValidationError } from "@iluvatar/global/src/typings";
import { TestConfig, BaseSchema, ValidationError, TestContext } from "yup";

// types

export function validate<T = unknown>(
  schema: BaseSchema,
  val: unknown,
  options?: { cast?: false },
): Promise<IValidationError[]>;
export function validate<T = unknown>(
  schema: BaseSchema,
  val: unknown,
  options: { cast: true },
): Promise<[T, IValidationError[]]>;
export function validate<T = unknown>(
  schema: BaseSchema,
  val: unknown,
  options?: { cast?: boolean },
): Promise<IValidationError[] | [T, IValidationError[]]> {
  let _val = val;

  if (options?.cast) {
    try {
      _val = schema.cast(val);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err);
    }
  }

  return schema
    .validate(_val, { abortEarly: false })
    .then(() => {
      if (options?.cast) {
        return [_val, []] as [T, IValidationError[]];
      } else {
        return [];
      }
    })
    .catch((errors: ValidationError) => {
      const _errs = errors.inner?.map<IValidationError>(
        ({ message, path }) => ({
          message,
          path,
        }),
      );
      if (options?.cast) {
        return [_val as T, _errs];
      } else {
        return _errs;
      }
    });
}

/**
 * TODO: refactor validation to meet ITU E123/164 standard
 */
const rPhone = /^\+([0-9,x]+)$/;
export const phoneValidator: TestConfig = {
  name: "phoneNumber",
  message: "Phone numbers must be of the form +12225554444 or +12225554444x555",
  test: (phoneNumber) => {
    if (!phoneNumber || phoneNumber === "") {
      return true;
    }
    return (
      typeof phoneNumber === "string" &&
      phoneNumber.length >= 12 &&
      rPhone.test(phoneNumber)
    );
  },
};

export const codeValidator = (min = 2, max = 25): TestConfig => ({
  name: "ShortCode",
  test: (key, context) => {
    if (typeof key !== "string") {
      return context.createError({
        message: "Short codes must be strings",
      });
    }
    if (key.length < min) {
      return context.createError({
        message: `Short Codes must be at least ${min} characters`,
      });
    }
    if (!key.match(/^[A-Z][0-9A-Z\\-]+$/)) {
      return context.createError({
        message:
          "Short codes must only contain Capital Letters and, numbers and dashes (after the first)",
      });
    }
    if (key.length > max) {
      return context.createError({
        message: `Short Codes be ${max} characters or less`,
      });
    }
    return true;
  },
});

/**
 * Used for free metadata entries within a form. Some sanitization of form labels (also object keys)
 */
export const labelKeyValidator: TestConfig = {
  name: "objectKey",
  message: "Key must only contain letters, numbers, spaces, and - _ ",
  test: (val) =>
    Boolean(
      !val ||
        (typeof val === "string" && /[^a-zA-Z0-9 _-]/g.test(val) === false),
    ),
};

/**
 * Ensures that each value within a field is unique across entries of the array
 */
export const uniqueArrayFieldValue = (fieldName: string): TestConfig => ({
  name: "unique label",
  message: "The labels of the metadata must be unique",
  test: (arrayValues) => {
    const propObject: Record<string, number> = {};
    return Boolean(
      !arrayValues ||
        (Array.isArray(arrayValues) &&
          arrayValues?.every((entry) => {
            if (entry[fieldName] && !propObject[entry[fieldName]]) {
              propObject[entry[fieldName]] = 1;
              return true;
            }
            return false;
          })),
    );
  },
});

export function _relativePath(s?: string) {
  const _s = s?.endsWith(".") ? s?.substring(0, s.length - 1) : s;

  if (_s?.startsWith(".")) {
    return _s;
  }
  return `.${_s}`;
}

export function _trimPath(s?: string) {
  const _s = s?.endsWith(".") ? s?.substring(0, s.length - 1) : s;

  if (_s?.startsWith(".")) {
    return _s.substring(1);
  }
  return _s;
}

export const recordType = (
  typeValidator: BaseSchema,
  optional?: boolean,
): TestConfig => ({
  name: "recordType",
  message: "The record does not match the type",
  test: (object: unknown, context) => {
    if (optional && !object) {
      return true;
    }
    if (object && typeof object === "object") {
      const errors: ValidationError[] = [];

      Object.entries(object).forEach(([_key, value]) => {
        try {
          typeValidator.validateSync(value, {
            recursive: true,
            abortEarly: false,
          });
        } catch (_err) {
          errors.push(
            ...mergeErrors(
              _err as ValidationError,
              `${context.path}.${_key}`,
              context,
            ),
          );
        }
      });

      if (errors.length) {
        return new ValidationError(errors);
      }
    }
    return true;
  },
});

export function bubbleUpValidationErrors(
  err: unknown,
  path: string | undefined,
  context: TestContext,
): ValidationError | true {
  const errors = mergeErrors(err as ValidationError, path, context);
  if (errors.length) {
    return new ValidationError(errors);
  }
  return true;
}

export function mergeErrors(
  err: ValidationError,
  path: string | undefined,
  context: TestContext,
): ValidationError[] {
  const errors: ValidationError[] = [];

  if (err.path) {
    errors.push(
      new ValidationError(
        err.message,
        err.value,
        _trimPath(`${context.path}${_relativePath(err.path)}`),
        err.type,
      ),
    );
  }

  if (err.inner) {
    errors.push(
      ...err.inner.map((_innerErr) => {
        return new ValidationError(
          _innerErr.message,
          _innerErr.value,
          _trimPath(`${path}${_relativePath(_innerErr.path)}`),
          _innerErr.type,
        );
      }),
    );
  }

  return errors;
}

/**
 * validates that the fieldName (at the same level) is equal in vale
 * @param fieldName
 * @returns
 */
export const confirmPassword = (fieldName: string): TestConfig => ({
  name: "Confirm Password",
  message: "The passwords do not match",
  test: (password, context) => {
    const otherPassField = context.parent[fieldName];
    if (!password) {
      return true;
    }
    if (password === otherPassField) {
      return true;
    }
    return false;
  },
});

/**
 * test function to validate one of the schemas is true
 */
export function some<T>(...schemas: BaseSchema<T>[]): TestConfig {
  return {
    name: "Some Validation",
    message: "Data does not match the shapes provided",
    test: function test(value, context) {
      if (!schemas.length) {
        return true;
      }

      const errors: ValidationError[] = [];
      const hasValid = schemas.some((schema) => {
        try {
          schema.validateSync(value, {
            recursive: true,
            abortEarly: false,
          });
          return true;
        } catch (_err) {
          if (_err) {
            const err = _err as ValidationError;
            if (err.path) {
              errors.push(
                new ValidationError(
                  err.message,
                  err.value,
                  _trimPath(`${context.path}${_relativePath(err.path)}`),
                  "someError",
                ),
              );
            }
            if (err.inner) {
              errors.push(
                ...err.inner.map(
                  (_innerErr) =>
                    new ValidationError(
                      _innerErr.message,
                      _innerErr.value,
                      _trimPath(
                        `${context.path}${_relativePath(_innerErr.path)}`,
                      ),
                      _innerErr.type,
                    ),
                ),
              );
            }
          }
          return false;
        }
      });

      if (hasValid) {
        return true;
      }

      return new ValidationError(errors);
    },
  };
}
