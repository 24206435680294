import dayjs from "dayjs";

import { Button, Divider, Typography } from "@mui/material";

// components
import { TagList } from "../TagList";
import { ProfileDescription } from "../ProfileDescription";

// types
import { ProfileDetails, UserEntity } from "@iluvatar/global/src/typings";
import { ImageCarousel } from "../ProfileImageBanner/ImageCarousel";

export function ViewProfile({
  userId,
  about,
  profile,
}: {
  userId: string;
  about: UserEntity["about"];
  profile: ProfileDetails;
}) {
  return (
    <>
      <ImageCarousel
        userId={userId}
        images={
          profile.imageIds
            ?.map((id) => ({
              ...(profile.imagesById![id] || {}),
              id: id,
            }))
            .filter((ref) => !ref.metadata.isProcessing) || []
        }
      />
      <Typography variant="h3" className="flex flex-row">
        {profile.displayName}
      </Typography>
      <Typography variant="subtitle2">
        {about.birthDate
          ? `${dayjs(new Date()).diff(about.birthDate, "years")}, `
          : ""}
        {[about.gender, about.sexuality].join(" ")}
      </Typography>
      {profile.subTitle && (
        <Typography variant="subtitle2">{profile.subTitle}</Typography>
      )}
      <ProfileDescription>{profile.description}</ProfileDescription>
      <TagList items={profile.characteristics || []} label="Characteristics" />
      <TagList items={profile.interests || []} label="Interests" />
      <TagList items={profile.desires || []} label="Desires" />
      <Divider />
      <Button variant="outlined" className="mt-8" color="info" disabled>
        Report Account
      </Button>
    </>
  );
}
