// utilities
import { IFileReference } from "@iluvatar/global/src/typings";
import { isImage } from "@iluvatar/global/src/utilities";

// types
import { FormFile } from "./types";

export function imgRef(file: FormFile): string | undefined {
  if (file.ref && isImage(file.metadata.contentType)) {
    return file.ref;
  }
  return undefined;
}

function computeFileSize(path: string): number {
  return Number(path.split("/")?.pop()?.split("_")?.shift()) || 0;
}

function absDiff(path: string, size: number): number {
  return Math.abs(computeFileSize(path) - size);
}

// TODO: get closest s
export function imgRefSize(
  file: FormFile | IFileReference | undefined,
  size: 300 | 600 | 40,
): string | undefined {
  if (file && file.ref && isImage(file.metadata.contentType)) {
    if (file?.metadata?.transforms && Array.isArray(file.metadata.transforms)) {
      const _imgRefs = (file.metadata.transforms as string[]).sort(
        (a: string, b: string) => {
          if (computeFileSize(a) < size) {
            return 1;
          }

          return absDiff(a, size) < absDiff(b, size) ? -1 : 1;
        },
      );
      return _imgRefs[0];
    }
    return file.ref;
    // return file?.metadata?.transforms
    //   ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //     (file.metadata.transforms as any)[1]
    //   : undefined;
  }
  return undefined;
}
